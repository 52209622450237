import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Icon } from 'components/ui';

export const CopyText = ({ children, title, successMessage, className }) => {
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(children);
    successMessage && toast.success(successMessage);
  };

  return (
    <strong {...(className && { className })} {...(title && { title })}>
      <Icon iconName="lar la-copy" onClick={handleCopyToClipBoard} /> {children}
    </strong>
  );
};

CopyText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  successMessage: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};
