import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Col, Row } from 'antd';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { DealPrice, PriceLabel } from 'components/shared';
import { dealRiskLevel } from 'enums';

const PricingDealsPreview = ({ deal }) => {
  const { getText } = useTranslations();
  useCurrencies((currenciesState) => currenciesState.selectedCurrency);

  return (
    <Row>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('fullPrice')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">
          <PriceLabel
            fiat={deal?.price}
            fiatOriginCurrency={deal?.currencyCode}
            fiatDesiredCurrency={deal?.currencyCode}
            fiatOnly={false}
          />
        </span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('discount')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">{`${deal?.discount}%`}</span>
      </Col>
      {deal.discountedPrice > 0 && (
        <>
          <Col lg={4} span={24}>
            <span className="subtitle">{getText('discountedPrice')}</span>
          </Col>
          <Col lg={20} span={24}>
            <DealPrice deal={deal} className="content" />
          </Col>
        </>
      )}
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('dealShakerCouponPrice')}</span>
      </Col>
      <Col lg={20} span={24}>
        <DealPrice showFinalPrice deal={deal} className="content" />
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('quantityOfCoupons')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">{deal.couponQuantity}</span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('dealRiskLevel')} </span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">
          {deal?.paymentClearingRisk ? getText(dealRiskLevel[deal?.paymentClearingRisk]) : '-'}
        </span>
      </Col>
    </Row>
  );
};

PricingDealsPreview.propTypes = {
  deal: PropTypes.object,
};

export default PricingDealsPreview;
