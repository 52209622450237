import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Collapse } from 'antd';
import { useUrlParams, Dropdown } from 'components/UIExternal';
import { CountryDropdown } from 'components/ui';
import { pageFilterCollapse } from 'styles';

const statusOptions = () => [
  {
    label: getText('cryptoBan'),
    value: true,
  },
  {
    label: getText('nonCryptoBan'),
    value: false,
  },
];

export const CountryConfigurationFilterTab = ({ paginationRef, filterKeys }) => {
  const { queryParams } = useUrlParams();

  return (
    <Collapse css={pageFilterCollapse}>
      <Collapse.Panel header={getText('filters')}>
        <section className="filters-container">
          <div>
            <label>{getText('country')}</label>
            <CountryDropdown
              placeholder={getText('searchByCountry')}
              onChange={(value) => paginationRef.current?.applyFilters({ [filterKeys.name]: value })}
            />
          </div>
          <div>
            <label>{getText('status')}</label>
            <Dropdown
              placeholder={getText('status')}
              value={queryParams[filterKeys.isCryptoRestricted]}
              options={statusOptions()}
              onChange={(value) => paginationRef.current?.applyFilters({ [filterKeys.isCryptoRestricted]: value })}
              mapValue={(el) => el?.value}
            />
          </div>
        </section>
      </Collapse.Panel>
    </Collapse>
  );
};

CountryConfigurationFilterTab.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
};
