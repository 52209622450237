import { theme } from 'styles';

export const formDateStyle = {
  marginBottom: '1em',
  '& > div': {
    padding: '0 !important',
  },
};

export const datePickerStyles = (showFiltersError) => ({
  '&.ant-picker': {
    border: `1px solid ${showFiltersError ? theme.red : theme.gray500}`,
    borderRadius: 2,
  },
});
