import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { HtmlEditor } from 'components/shared';
import modalIcon from 'assets/images/DeclineModal.svg';
import { modelContentWrapper, cancelButtonStyle, commentModalActions, addButtonStyle } from './styles';

const CommentModal = ({ isVisible, onAccept, onCancel, title }) => {
  const { getText } = useTranslations();
  const [comment, setComment] = useState('');

  const handleCancel = () => {
    setComment('');
    isFunction(onCancel) && onCancel();
  };

  const handleSubmit = () => {
    setComment('');
    isFunction(onAccept) && onAccept(comment);
  };

  return (
    <Modal
      open={isVisible}
      width={880}
      zIndex={1100}
      onCancel={onCancel}
      css={modelContentWrapper}
      footer={null}
      destroyOnClose>
      <img src={modalIcon} className="iconModal" alt="prop" />
      <p className="section-subtitle">{title ?? getText('addComment')}</p>
      <HtmlEditor value={comment} onChange={setComment} placeholder={getText('yourComment')} />
      <div css={commentModalActions}>
        <Button type="secondary" small css={cancelButtonStyle} onClick={handleCancel}>
          {getText('cancel')}
        </Button>
        <Button type="link" small disabled={!comment} onClick={handleSubmit} css={addButtonStyle}>
          {getText('submit')}
        </Button>
      </div>
    </Modal>
  );
};

CommentModal.propTypes = {
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
};

export default CommentModal;
