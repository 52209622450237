export const selectCategoryDropDown = {
  margin: '0',
};

export const selectSubCategoryDropDown = {
  margin: '0',
};

export const categoryContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5em',
};
