import { useTranslations } from '@veraio/strank';
import { Tabs } from 'components/ui';
import { CountryConfigurations, GeneralConfigurations, CategoryConfigurations } from './Tabs';
import { container } from './styles';
import { useHistory } from 'react-router-dom';

const tabs = { general: 'general', countryConfigurations: 'country-configurations', categoryConfiguratons: 'category-configuratons' };

const Settings = () => {
  const { getText } = useTranslations();
  const history = useHistory();
  const locationPath = window.location.pathname.split('/').pop();

  const tabItems = [
    {
      key: tabs.general,
      label: getText(tabs.general),
      children: <GeneralConfigurations />,
    },
    {
      key: tabs.countryConfigurations,
      label: getText(tabs.countryConfigurations),
      children: <CountryConfigurations />,
    },
    {
      key: tabs.categoryConfiguratons,
      label: getText(tabs.categoryConfiguratons),
      children: <CategoryConfigurations />,
    }
  ];

  const handleTabChange = tab => {
    history.push(`/settings/${tab}`);
  };

  return (
    <div css={container}>
      <Tabs
        defaultActiveKey={tabs.general}
        activeKey={locationPath}
        onTabClick={handleTabChange}
        items={tabItems}
      />
    </div>
  );
};

export default Settings;
