import { getText } from '@veraio/strank';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { Tag } from 'components/UIExternal';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { PriceLabel } from 'components/shared';
import { Link } from 'react-router-dom';
import { textLinkStyle, textMutedStyle } from '../../styles';

export const getSettlementColumns = () => [
  {
    name: getText('transactionId'),
    render: (row) => (
      <div>
        <strong><Link css={textLinkStyle} to={`transactions/${row.id}/details`}>#{row.id}</Link></strong>
        {!!row.externalId && row.externalId !== '0' && (<>
          <br />Ext #{row.externalId}
        </>)}
      </div>
    ),
    width: 180
  },
  {
    name: getText('receiver'),
    render: row => (
      <div>
        <strong>{row.receiverName}</strong>
        <br />
        <span css={textMutedStyle}>{row.receiverPhone}</span>
        <br />
        <span css={textMutedStyle}>{row.receiverEmail}</span>
        <br />
        <span css={textMutedStyle}>{row.receiverNickname}</span>
      </div>
    ),
  },

  {
    name: getText('status'),
    render: row => (
      <Tag type={TRANSACTION_STATUS_COLOR[row.statusId]} fontSize="0.725rem">
        {getText(TRANSACTION_STATUS_INVERSE[row.statusId])}
      </Tag>
    ),
    width: 180,
  },
  {
    name: getText('settlementDate'),
    value: 'settlementDate',
    dateTimeFormat: 'DD-MM-YYYY HH:mm',
    width: 180,
  },
  {
    name: getText('amount'),
    render: (row) => (
      <PriceLabel
        fiat={row.originalAmount}
        fiatOriginCurrency={row.originalCurrency}
        fiatDesiredCurrency={row.originalCurrency}
        crypto={row.oneCoinAmount || row.oespAmount}
        cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        fiatOnly={false}
      />
    ),
    width: 200,
  },
  {
    name: getText('bundledTransactions'),
    render: row => <Link css={textLinkStyle} to={`settlement/${row.id}/bundled-transactions`}>{getText('view')}</Link>,
    width: 180,
  }
];

export const filterKeys = {
  transactionId: 'transactionId',
  date: 'date',
  statusId: 'statusId',
  currency: 'currency',
};

export const sortingKeys = {
  sorting: 'transactionsSorting',
};
