import { theme } from 'styles';

const itemHeight = 51;

export const dropDownContainer = (disabled) => ({
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.3 : 1,
  outline: 'none',
  fontSize: '0.8rem',
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${theme.gray500}`,
  borderRadius: '2px',
});

export const dropDownSelect = (isOpened, isSelected, small) => [
  {
    border: `1px solid ${theme.white}`,
    borderRadius: isOpened ? '4px 4px 0 0' : '4px',
    backgroundColor: theme.white,
    padding: '0.4em 0.7em',
    color: isSelected ? theme.gray200 : theme.gray300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: isSelected ? 'hidden' : 'none',
    maxHeight: isSelected ? '3.125rem' : 'auto',
    overflowY: isSelected ? 'scroll' : 'auto',
  },
  small && {
    padding: '0.3em 0.5em',
  },
];

export const dropDownText = (isSelected) => ({
  userSelect: isSelected ? 'text' : 'none',
  maxHeight: isSelected ? '3.125rem' : 'auto',
});

export const dropDownOptionsContainer = (isOpened, numberOfItems, maxNumber, onTop, onLeft) => [
  {
    position: 'absolute',
    zIndex: 40,
    color: theme.gray300,
    minWidth: '100%',
    height: 'auto',
    maxHeight: numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : 'none',
    overflowY: 'auto',
    backgroundColor: theme.gray600,
    transform: `scaleY(${isOpened ? 1 : 0})`,
    transformOrigin: onTop ? 'bottom' : 'top',
    transition: 'transform 400ms ease-in-out',
    cursor: 'default',
  },
  onTop
    ? {
        bottom: '100%',
        boxShadow: '0 -2px 4px rgba(0,0,0,0.3)',
        borderBottom: isOpened ? '1px solid #DDDDDD' : 'none',
        borderRadius: '4px 4px 0 0',
      }
    : {
        top: '100%',
        boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
        borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
        borderRadius: '0 0 4px 4px',
      },
  onLeft && { right: 0 },
];

export const dropDownOptionItem = (isSelected, hasRender, small) => [
  {
    backgroundColor: isSelected ? theme.skyblueLight : theme.white,
    color: isSelected ? theme.gray200 : theme.gray100,
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: isSelected ? theme.skyblueLight : theme.skyblueLight,
    },
    '&:focus': {
      outline: 'none',
    },
    '& i': {
      marginRight: '0.5em',
    },
  },
  !hasRender && {
    padding: '0.75em 1.5em',
  },
  small && {
    padding: '0.5em 1em',
  },
];

export const dropDownGroupLabel = {
  margin: 0,
  padding: '0.75em',
  cursor: 'default',
  color: theme.gray100,
  fontSize: '0.875rem',
  fontWeight: 400,
};

export const rightIconsContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const dropDownRightIcon = (isOpened) => ({
  fontSize: '1.1875rem',
  fontWeight: 600,
  transform: `rotate(${isOpened ? -180 : 0}deg)`,
  transition: 'transform 0.35s ease-out',
  cursor: 'pointer',
  display: 'inline-flex',
});

export const dropDownDeleteIcon = {
  display: 'inline-flex',
  fontSize: '1.0625rem',
  height: '1.0625rem',
  cursor: 'pointer',
  color: theme.gray400,

  '&:hover': {
    color: theme.gray200,
  },
};

export const dropdownSearch = {
  position: 'sticky',
  top: 0,
  width: '100%',
};

export const noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1em 0.5em',
};

export const noResultsIcon = {
  backgroundColor: theme.skyblue,
  borderRadius: '50%',
  padding: '0.5em',
};

export const infiniteScroll = {
  padding: '1em',
  display: 'flex',
  justifyContent: 'center',
};
