import { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Form, Input, Select, TimePicker, InputNumber } from 'antd';
import { FormEditButtons } from 'components/shared';
import { Dropdown } from 'components/UIExternal';
import { formatTime, getCountryPhoneCodeOptions } from 'utils';
import { addressFormEdit, formDateAndTimeEdit, phoneNumber, mandatoryStar, phoneCodeStyles } from './styles';
import { CountryDropdown } from '../../ui';

const AddressCardEdit = ({ address, onCancel, onEdit }) => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();
  const countries = useLocations((locationsState) => locationsState.countries);
  const phoneCodes = getCountryPhoneCodeOptions(countries);
  const phoneCodeRef = useRef(address?.phoneNumber?.split(' ')[0]);

  const newExternalValues = {
    ...omit(address, 'businessNames', 'phone', 'workingDays', 'workingHours', 'country'),
    countryId: countries?.find((el) => el?.name === address?.country)?.id,
    businessAddressDetails: address?.businessNames?.find((el) => el.name)?.name,
    phoneCode: phoneCodes?.find((code) => Number(code.value) === Number(address?.phone.split(' ')[0])),
    phoneNumber: address?.phone.split(' ')[1],
    fromDay: address?.workingDays?.split(' – ')[0],
    toDay: address?.workingDays?.split(' – ')[1],
    fromTime: address?.workingHours && moment(address?.workingHours.split(' – ')[0], 'HH:mm'),
    toTime: address?.workingHours && moment(address?.workingHours.split(' – ')[1], 'HH:mm'),
  };

  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  const handleSaveForm = async (values) => {
    const formEditValues = {
      ...omit(values, 'phoneCode', 'phoneNumber', 'fromDay', 'toDay', 'fromTime', 'toTime', 'businessAddressDetails'),
      phone: `${values.phoneCode.value} ${values.phoneNumber}`,
      workingDays: values.fromDay && values.toDay ? `${values.fromDay} – ${values.toDay}` : null,
      workingHours:
        values.fromTime && values.toTime ? `${formatTime(values.fromTime)} – ${formatTime(values.toTime)}` : null,
    };

    await onEdit(formEditValues);
  };

  const handleCountryChange = (countryId) => {
    const foundCountryData = countries?.find((country) => country.id === countryId);
    const foundPhoneCode = phoneCodes?.find((code) => code.value === foundCountryData.phoneCode);

    phoneCodeRef.current.changeValue(foundPhoneCode);
    form.setFieldsValue({ phoneCode: foundPhoneCode.value });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      css={addressFormEdit}
      onFinish={handleSaveForm}
      initialValues={newExternalValues}>
      <Form.Item name="countryId" label={getText('country')} rules={[mandatoryRule]} data-id="Address-country">
        <CountryDropdown placeholder={getText('selectCountry')} onChange={handleCountryChange} />
      </Form.Item>
      <Form.Item name="city" data-id="Address-city" rules={[mandatoryRule]} labelAlign="left" label={getText('city')}>
        <Input placeholder={getText('enterCity')} />
      </Form.Item>
      <Form.Item name="state" data-id="Address-state" labelAlign="left" label={getText('state')}>
        <Input type="text" placeholder={getText('state')} />
      </Form.Item>
      <Form.Item name="postcode" data-id="Address-postcode" labelAlign="left" label={getText('postcode')}>
        <Input type="text" placeholder={getText('postcode')} />
      </Form.Item>
      <Form.Item
        name="street"
        data-id="Address-street"
        labelAlign="left"
        label={getText('streetAddress')}
        rules={[
          mandatoryRule,
          {
            max: 100,
            message: getText('FieldShouldNotExceedSymbolsCount', { count: 100 }),
          },
        ]}>
        <Input placeholder={getText('merchantAddress')} />
      </Form.Item>
      <Form.Item
        name="contactPerson"
        data-id="Address-contact-person"
        labelAlign="left"
        label={getText('contactPerson')}>
        <Input placeholder={getText('enterFirstAndLastName')} />
      </Form.Item>
      <div className="phone-code-and-number">
        <p style={{ marginBottom: '0.5em' }}>
          <span css={mandatoryStar}>*</span>
          {getText('phoneNumber')}
        </p>
        <div css={phoneNumber}>
          <Form.Item name="phoneCode" data-id="Address-phoneCode" rules={[mandatoryRule]}>
            <Dropdown
              noClear
              ref={phoneCodeRef}
              placeholder={getText('phoneCode')}
              options={phoneCodes}
              uniqueKey="value"
              displayKey="label"
              withSearch
              css={phoneCodeStyles}
              renderSelected={(selected) => selected && `+ ${selected.value}`}
            />
          </Form.Item>
          <Form.Item name="phoneNumber" data-id="Address-phone" rules={[mandatoryRule]}>
            <InputNumber className="phone-number" placeholder={getText('enterPhoneNumber')} />
          </Form.Item>
        </div>
      </div>
      <div css={formDateAndTimeEdit}>
        <Form.Item name="fromDay" data-id="Address-workingDaysFrom" labelAlign="left" label={getText('workingDays')}>
          <Select
            showSearch
            placeholder={getText('workingFrom')}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {weekDays.map((day) => (
              <Select.Option key={day} value={day}>
                {day}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="toDay" data-id="Address-workingDaysTo" labelAlign="left">
          <Select
            showSearch
            placeholder={getText('to')}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {weekDays.map((day) => (
              <Select.Option key={day} value={day}>
                {day}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div css={formDateAndTimeEdit}>
        <Form.Item label={getText('workingHours')} name="fromTime" data-id="fromTime">
          <TimePicker
            placeholder={getText('openFrom')}
            format="HH:mm"
            onSelect={(value) => {
              form.setFieldsValue({
                fromTime: value,
              });
            }}
          />
        </Form.Item>
        <Form.Item name="toTime" data-id="toTime">
          <TimePicker
            placeholder={getText('until')}
            format="HH:mm"
            onSelect={(value) => {
              form.setFieldsValue({
                toTime: value,
              });
            }}
          />
        </Form.Item>
      </div>
      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

AddressCardEdit.propTypes = {
  address: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default AddressCardEdit;
