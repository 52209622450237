import { getReq, patchReq, postReq } from 'services/axios/makeRequest';
import { refundsListParams } from 'services/models/refunds';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Refunds`;

export const getAllRefunds = (options) => getReq(`${baseUrl}/List?${refundsListParams(options)}`);

export const getRefundById = (refundId) => getReq(`${baseUrl}/Get/${refundId}`);

export const updateRefundStatus = (data) => patchReq(`${baseUrl}/UpdateStatus`, data);

export const addCommentToRefund = ({ refundId, comment }) => postReq(`${baseUrl}/Comment/${refundId}`, { comment });
