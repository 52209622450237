import { isArray } from '@veraio/core';
import { Icon } from 'components/ui/Icon';
import { Button } from 'components/UIExternal';
import { useEffect, useState } from 'react';
import { expandCollapseButton, noChildItem, treeItem, withChildItem } from './styles';

const hasExpandedId = (id, expandedItemsIds) => {
	const isInExpandedIds = expandedItemsIds && expandedItemsIds.length > 0
		? expandedItemsIds.indexOf(id || -1) > -1
		: false;

	return isInExpandedIds;
};

const TreeItem = ({
	item,
	idKey = 'id',
	titleKey,
	childrenKey,
	render,
	level,
	expandedItemsIds,
	minSearchLength
}) => {
	const [expanded, setExpanded] = useState(false);
	const { searchText, ids } = expandedItemsIds;

	useEffect(() => {
		if (searchText && searchText.length > 0) {
			if (searchText.length >= minSearchLength) {
				const updatedExpand = hasExpandedId(item?.id, ids);
				setExpanded(updatedExpand);
			}
		} else setExpanded(false);
	}, [searchText]);

	const hasChildren = item && item[childrenKey] && isArray(item[childrenKey]) && item[childrenKey].length > 0;
	const titleMatchSearch = searchText && searchText.length >= minSearchLength && (item[titleKey] || '').indexOf(searchText) > -1;
	const isVisible = searchText && searchText.length > 0
		? hasExpandedId(item?.id, ids) || titleMatchSearch
		: true;

	const handleExpandCollapseClick = () => setExpanded(!expanded);

	return (<>
		{isVisible && <div css={treeItem(level)}>
			{hasChildren && <Button css={expandCollapseButton} type="link" small onClick={handleExpandCollapseClick}>
				{expanded
					? <Icon size={12} iconName="las la-angle-up" />
					: <Icon size={12} iconName="las la-angle-down" />
				}
			</Button>}
			<div css={!hasChildren ? noChildItem : withChildItem}>
				{render(item)}
			</div>
		</div>}

		{expanded && <div>
			{item[childrenKey].map(child => <TreeItem
				key={child[idKey]}
				titleKey={titleKey}
				level={level + 1}
				item={child}
				childrenKey={childrenKey}
				render={render}
				expandedItemsIds={expandedItemsIds}
				minSearchLength={minSearchLength}
			/>)
			}
		</div>}
	</>);
};

export default TreeItem;