import { getReq } from 'services/axios/makeRequest';
import { getStatisticsGraphParams, getStatisticsOverviewParams } from 'services/models/statistics';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Statistics`;

export const getStatisticsDashboard = () => getReq(`${baseUrl}/Get`);

export const getStatisticsOverview = (options) => getReq(`${baseUrl}/Overview?${getStatisticsOverviewParams(options)}`);

export const getStatisticsGraphs = (options) => getReq(`${baseUrl}/Graphs?${getStatisticsGraphParams(options)}`);
