import { getText } from '@veraio/strank';

export const getTextChangeProps =
  (oldData, newData) => (propertyKey, translationPropertyKey, language, mapPropertyCallBack) => {
    const getTranslationProperty = (entityData) =>
      getText(entityData[propertyKey].find((el) => el.languageCode === language)?.[translationPropertyKey]);

    // for the cases when the value is boolean or enum
    const oldValue = mapPropertyCallBack ? mapPropertyCallBack(oldData[propertyKey]) : oldData[propertyKey];
    const newValue = newData
      ? mapPropertyCallBack
        ? mapPropertyCallBack(newData[propertyKey])
        : newData[propertyKey]
      : null;

    const oldText = translationPropertyKey ? getTranslationProperty(oldData) : oldValue;
    // This handles the case when there are no changes at all.
    // In this case, the API returns newData as null. We then return null as newText.
    const newText = newData ? (translationPropertyKey ? getTranslationProperty(newData) : newValue) : null;

    return newData ? { oldText, newText } : { oldText };
  };
