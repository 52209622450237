import { invert } from 'lodash-es';

export const UserStatus = {
  New: 'New',
  Pending: 'Pending',
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
};

export const USER_TYPE = {
  USER: 1,
  COUNTRY_MANAGER: 2,
  ADMIN: 3,
  REGIONAL_DIRECTOR: 4,
  AREA_MANAGER: 5,
};

const userType = {
  user: 1,
  countryManager: 2,
  admin: 3,
  regionalDirector: 4,
  areaManager: 5,
};

export const userTypeInverse = invert(userType);
