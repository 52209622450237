export const headerContainer = {
  display: 'flex',
  marginBottom: '3em',
  alignItems: 'center',
  '& > button': {
    paddingLeft: 0,
  },
};

export const transactionsTableContainer = {};

export const transactionsRowStatus = {
  '& .clearing-date': {
    marginTop: '5px;',
  },
};
