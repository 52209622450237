import { useEffect, useState } from 'react';
import { getAllCategories, patchCategoryClearingDate, setError } from 'services';
import { categoriesSettingsContainer } from './styles';
import { CategoriesTree } from 'components/screens';
import toast from 'react-hot-toast';
import { useTranslations } from '@veraio/strank';

const CategoryConfigurations = () => {
	const [categories, setCategories] = useState([]);
	const { getText } = useTranslations();

	useEffect(() => {
		fetchCategories();
	}, []);

	const fetchCategories = async () => {
		const [res, err] = await getAllCategories();
		if (err) return setError(err);

		setCategories(res);
	};


	const handleSubmitChangePeriod = async (categoryId, categoryClearingDaysValue) => {
		const [, err] =
			await patchCategoryClearingDate(categoryId, categoryClearingDaysValue);

		if (err) setError(err);
		toast.success(getText('successfullyEditedConfig'));
	};

	return <div css={categoriesSettingsContainer}>
		<CategoriesTree categories={categories} onCategoryChange={handleSubmitChangePeriod} />
	</div>;
};

export default CategoryConfigurations;