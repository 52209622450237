import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useTranslations } from '@veraio/strank';
import { isNil, dropRight } from 'lodash-es';
import { Upload } from 'antd';
import { toast } from 'react-hot-toast';
import { Button, Icon } from 'components';
import { merchantFileType } from 'enums';
import { setError, removeMedia, addImageToDeal } from 'services';
import { ml } from 'styles';
import {
  filesContainer,
  inputIcon,
  recommendationContainer,
  btn,
  uploadContainer,
} from './styles';

const UploadFiles = ({
  updateList,
  mediaFiles,
  allowedFormat,
  dealId,
  recommendation,
  singleFile,

}) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const [fileInfo, setFileInfo] = useState({ file: null, description: null, isUploaded: null });
  const [fileList, setFileList] = useState(mediaFiles ?? []);
  const { Dragger } = Upload;

  const fileUploadConfig = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: [allowedFormat].join(','),
    beforeUpload: file => fileValidation(file),
  };

  const fileValidation = file => {
    if (file.size > merchantFileType.sizeLimit) {
      toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
      return Upload.LIST_IGNORE;
    }

    if (!allowedFormat.includes(file.type)) {
      toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
      return Upload.LIST_IGNORE;
    }

    setFileInfo(prev => ({ ...prev, file, isUploaded: true }));
  };

  const updateData = (prop, value) => setFileInfo(prev => ({ ...prev, [prop]: value }));

  const handleUpload = async () => {
    const fileData = new FormData();
    fileData.append('file', fileInfo.file);
    const [res, err] = await addImageToDeal(fileData, dealId);
    err ? setError(err) : toast.success(getText('fileUploadedSuccessfully'));
    setFileList(prev => ([...prev, res]));
    setFileInfo({ file: null, isUploaded: false, description: null });
    !isNil(res) && updateList(res);
  };

  const handleCancel = () => {
    if (!isNil(fileInfo.file)) setFileInfo({ file: null, isUploaded: false });
    if (fileList?.length && fileList.length > mediaFiles.length) {
      handleRemoveMedia(fileList[fileList.length - 1].id);
      const tempList = dropRight(fileList, 1);
      setFileList(tempList);
      updateData('isUploaded', false);
    }
  };

  const handleRemoveMedia = async id => {
    const [, err] = await removeMedia(id);
    err && setError(err);
  };

  return (
    <>
      <div className={filesContainer}>
        <Dragger {...fileUploadConfig} disabled={singleFile && fileList?.length} css={uploadContainer}>
          <div className="upload-drag-icon">
            {fileInfo.isUploaded ? (
              <>
                <Icon iconName="las la-7x la-check-circle" className={`${inputIcon(theme)} success`} />
                <p className="upload-text">{getText('uploadSuccessful')}</p>
              </>
            ) : (
              <>
                <Icon iconName="las la-7x la-cloud-upload-alt" className={''} />
                <p className="upload-text">{getText('dragAndDropBrowseFiles')}</p>
                {recommendation && <p className={recommendationContainer(theme)}>{getText(recommendation)}</p>}
              </>
            )}
          </div>
        </Dragger>
      </div>
      <div className="flex upload-field-type">
        <Button type="default" small onClick={handleCancel} disabled={!fileInfo.isUploaded} css={btn}>
          {getText('cancel')}
        </Button>
        <Button
          className={ml({ lg: 10 })}
          type="primary"
          small
          onClick={handleUpload}
          disabled={!fileInfo.isUploaded}
          css={btn}
        >
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

UploadFiles.propTypes = {
  updateList: PropTypes.func,
  mediaFiles: PropTypes.array,
  hasDescription: PropTypes.bool,
  filePurpose: PropTypes.number,
  dealId: PropTypes.number,
  allowedFormat: PropTypes.array,
  recommendation: PropTypes.string,
  label: PropTypes.string,
  labelInformation: PropTypes.string,
  singleFile: PropTypes.bool,
};

export default UploadFiles;

