/* eslint-disable camelcase */

export const theme = {
  gray100: '#222222',
  gray200: '#616366',
  gray300: '#888F97',
  gray400: '#C5C8CC',
  gray500: '#DCDDE0',
  gray550: '#EDEFF2',
  gray600: '#F4F5F7',
  gray700: '#fafafa',
  gray800: '#f0f0f0',
  gray900: '#E6EDF273',
  background100: '#19285E',
  background200: '#344676',
  blue: '#0F6EDE',
  blue2: '#0048B5',
  fb_blue: '#3479EA',
  green: '#07903E',
  green2: '#00B256',
  green3: '#6FCF97',
  primary_blue: '#40AAFB',
  primary_blue_hover: '#3A91F8',
  skyblue: '#DAEBFF',
  skyblue_hover: '#AFD4FF',
  light_blue_hover: '#F8FBFE',
  skyblueLight: '#f2f8ff',
  primary_orange: '#FF9E00',
  white: '#ffffff',
  transparent: 'transparent',
  black: '#000000',
  black100: '#000000d9',
  primary_yellow: '#FFCF00',
  pale_yellow: '#FFE76A',
  primary_hover_btn: '#FFB200',
  purple: '#8E4BE3',
  purple2: '#C881EA',
  violet: '#445BD0',
  red: '#F63C25',
  red_dark: '#DA1A0E',
  slider_step: '#172746',
  ui_hover: '#E6EDF2',
  ui_hover_rgba: 'rgba(230, 237, 242, 0.45)',
  gray_rgba: 'rgba(34, 34, 34, 0.08)',
  gray400_rgba: 'rgba(197, 200, 204, 0.50)',
  skyblue_rgba: 'rgba(218, 235, 255, 0.10)',
  lightgray: '#F7F8FA;',
  dark_gray: '#808080',
  default_hover: '#F2F7FC',
  new_coupon: '#7B61FF',
  warning: '#ffab02',
  warningDark: '#9e4a03',
  warningLight: '#fff3c5',
  free: '#C5F0D7',
  qrCodev1: '#0f6ede',
  qrCodev2: '#f4f5f7',
  Desktop: '@media (min-width: 992px)',
  Mobile: '@media (max-width: 991px)',
};
