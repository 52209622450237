import { theme } from 'styles';

export const titleStyle = {
  color: '#273B4A',
  fontSize: '2rem',
};

export const container = {
  padding: '0 2em',
  '& .header': { marginTop: '2.5em' },
};

export const notificationsButtonWrapper = {
  display: 'flex',
  gap: '0.5em',
  flexWrap: 'wrap',
};

export const tooltipContainer = {
  marginLeft: 'auto',
};

export const dateStyles = {
  color: theme.gray200,
};

export const statusDot = (isSend) => ({
  height: '0.375rem',
  width: '0.375rem',
  backgroundColor: isSend ? theme.green2 : theme.blue,
  borderRadius: '50%',
  marginRight: '0.375em',
});

export const statusContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > p': {
    marginBottom: 0,
  },
};

export const notificationIcn = {
  width: '2.5rem',
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '0.75em',

  '& > span': {
    fontSize: '1.5rem',
    color: theme.primary_orange,
    fontWeight: 500,
  },
};

export const titleIcnContainer = {
  display: 'flex !important',
  flexDirection: 'row',
  alignItems: 'center',
  '& > div': {
    '& > h3': {
      marginBottom: 0,
    },
    '& > p': {
      marginBottom: 0,
    },
  },
};

export const actionContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  fontSize: '0.8125rem',
};

export const linkBthStyles = {
  color: theme.gray100,
  '&:hover': {
    color: theme.gray100,
  },
};

export const typeTime = {
  [theme.Desktop]: {
    '& .time': {
      color: theme.gray300,
      fontSize: '0.8125rem',
    },
  },
  [theme.Mobile]: {
    '& .time': {
      color: theme.gray300,
      fontSize: '0.75rem',
    },
  },
};

export const notificationIcon = {
  width: '2rem',
  boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
  borderRadius: '50%',
};

export const markAsReadBtn = {
  marginRight: '0.625em',
};

export const messageStyles = (isRead) => ({
  fontWeight: !isRead ? 700 : 400,
  color: isRead && theme.gray100,
});

export const notificationIsRead = (isRead) => ({
  fontWeight: !isRead && 700,
});
