import { keyframes } from '@emotion/react';
import { theme } from 'styles';

export const progressStepImages = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
};

export const progressStep = {
  width: '4rem',
  textAlign: 'center',
};

export const progressStepStepsWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '1em',
};

export const progressLineWrapper = {
  position: 'relative',
  background: theme.gray600,
  borderRadius: 10,
  margin: '0 .8em',
};

const fillProgressLine = (progress) =>
  keyframes({
    '0%': { width: 0 },
    '100%': { width: `${progress}%` },
  });

export const progressLine = (progress, numberOfSteps) => ({
  height: '0.5rem',
  borderRadius: 8,
  marginTop: '1em',
  animation: `${fillProgressLine(progress)} ${numberOfSteps * 1.4}s ease-out 0s 1 both`,
  backgroundColor: theme.primary_blue,
});

export const progressLineCheckPoints = {
  position: 'absolute',
  zIndex: 1,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

export const progressLinePoint = (isActive) => ({
  width: '0.7rem',
  height: '0.7rem',
  borderRadius: '50%',
  background: isActive ? theme.primary_blue : theme.gray500,
  boxShadow: `0 0 0 0.2rem ${isActive ? theme.skyblue : theme.gray600}`,
});
