import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { ReactComponent as EmptyIcon } from '../../../assets/images/empty-icon.svg';
import { noResultsContainer, noResultsTitle, noResultsIcon, noResultsSubtitle } from './styles';

export const NoResults = ({ emptyMessage }) => {
  return (
    <section css={noResultsContainer}>
      <EmptyIcon css={noResultsIcon} />
      <p>{emptyMessage ?? getText('thereAreNoRecords')}</p>
    </section>
  );
};

NoResults.propTypes = {
  emptyMessage: PropTypes.string,
};

export const Error = () => {
  return (
    <section css={noResultsContainer}>
      <div css={noResultsIcon}>
        <Icon material iconName="report" size={48} color="white" />
      </div>
      <h2 css={noResultsTitle}>Request failure.</h2>
      <p css={noResultsSubtitle}>
        There was problem getting the results.
        <br />
        Please try again later.
      </p>
    </section>
  );
};
