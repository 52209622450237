import { theme } from 'styles';

export const wrapper = {
  paddingLeft: '0.625em',
  '& .title': { fontSize: '1rem', fontWeight: 700, color: theme.background100 },
  '& .subtitle': { fontSize: '0.875rem', fontWeight: 400, color: theme.background200 },
  '& .content': { fontSize: '0.875rem', fontWeight: 400, color: theme.gray100 },
  '& .content.date': { color: theme.gray200 },
};

export const editFormSectionBtn = {
  padding: '0.3em',
  minHeight: 'auto',
  marginLeft: '0.5em',
};

export const formEditBtns = {
  display: 'flex',
  gap: '0.5em',
};

export const formDateStyle = {
  marginBottom: '1em',
  '& > div': {
    padding: '0 !important',
  },
};

export const formStyles = {
  width: '100%',
  '& > div': {
    marginBottom: '1em',
  },
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
};

export const packageWeightWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  width: '100%',
  margin: '0.5em 0',
};
export const packageWeight = {
  display: 'flex',
  alignItems: 'center',
};

export const wrapperStyles = {
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
};

export const box = {
  alignItems: 'center',
  borderRadius: 3,
  '& .ant-form-item-control-input-content': {
    display: 'flex',
  },
  '& .ant-form-item-label .ant-form-item-no-colon': {
    fontWeight: 500,
    fontSize: '0.8125rem !important',
  },
};

export const merchantWrapper = {
  display: 'flex',
  width: '100%',
  alignItems: 'baseline',
};

export const pricePicker = {
  width: '40%',
  '& .ant-slider-dot': { display: 'none' },
  '& .ant-slider': { margin: 0 },
  '& .ant-slider-mark-text': {
    transform: 'none !important',
    color: theme.gray200,
    top: '-1.875em',
    '&:last-of-type': { left: '92% !important' },
  },
  '& .ant-slider-track': {
    backgroundColor: `${theme.primary_yellow} !important`,
    height: '0.5em',
  },
  '& .ant-slider-rail': { height: '0.5em' },
  '& p': {
    marginTop: '2.5em',
    marginBottom: '2.375em',
    fontWeight: 400,
    fontSize: '0.75rem',
    color: theme.gray200,
  },
  '& h5': { textAlign: 'center' },
};

export const categoryButtonsWrapper = {
  marginBottom: '1.5em',
  marginTop: '0.25em',
  display: 'flex',
  gap: '0.5em',
  width: '100%',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: '0.3125em',
  },
  '& .categorySelected': {
    backgroundColor: theme.default_hover,
    color: theme.red,
  },
  '& .ant-btn': {
    marginRight: '1em',
    marginTop: '0.25em',
    borderRadius: '4px',
    color: theme.black,
    textShadow: 'none',
  },
  '& .ant-btn-primary': {
    backgroundColor: theme.skyblue,
  },
  '& button:focus': {
    background: theme.skyblue,
    color: theme.black,
  },
};

export const noCategoryContainer = (showError) => ({
  border: showError ? `1px solid ${theme.red}` : `2px solid ${theme.gray500}`,
  borderRadius: 3,
});

export const requiredStyles = {
  textWrap: 'no-wrap',
  color: theme.red,
  marginRight: '0.25em',
};

export const sliderText = {
  margin: '0 !important',
  textAlign: 'center',
};

export const labelSpan = {
  display: 'flex',
};

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -1em',
  alignItems: 'center',
  gap: '0.2em',
};

export const colInput = {
  flex: '1 0 0%',
  width: '100%',
  maxWidth: '100%',
  padding: '0 1em',
};

export const colText = {
  flex: '1 0 0%',
  width: '100%',
  maxWidth: '15%',
  padding: '0 1em',
};
