import { theme } from 'styles';

export const galleryWrapper = {
  '& .image-gallery-thumbnails .image-gallery-thumbnails-container': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .image-gallery-thumbnails-wrapper.bottom': {
    marginTop: '1em',
    maxWidth: '70%',
    [theme.Mobile]: {
      display: 'none',
    },
  },
  '& .image-gallery-thumbnails': {
    display: 'flex',
  },
  '& .image-gallery-thumbnail': {
    border: '1px solid transparent',
    marginRight: '0.5em',
    width: '5.3125rem',
    height: '3.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 4,
  },
  '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
    width: '115%',
    height: 'auto',
  },
  '& .image-gallery-thumbnail.active, & .image-gallery-thumbnail:focus, & .image-gallery-thumbnail:hover': {
    border: '1px solid transparent',
  },
  '& .image-gallery-thumbnail .image-gallery-thumbnail-inner': {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .image-gallery-slide': {
    width: '100%',
    height: 0,
    paddingBottom: '57%',
  },
  '& .image-gallery-slide div': {
    width: '100%',
    height: '100%',
    [theme.Mobile]: {
      height: 'auto',
    },
  },
  '& .image-gallery-slides': { borderRadius: 4 },
  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: '-9999px',
    bottom: '-9999px',
    left: '-9999px',
    right: '-9999px',
    margin: 'auto',
  },
};
