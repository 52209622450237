import { useEffect, useState, useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { isFunction } from '@veraio/core';
import { Dropdown } from 'components/UIExternal';
import { getAllCategories } from 'services';
import useError from 'services/errorHandling/useError';
import { selectSubCategoryDropDown, selectCategoryDropDown, categoryContainer } from './styles';

const DealCategories = ({ value, onChange }) => {
  const { setError } = useError();
  const { getText } = useTranslations();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);
  const [selectedLastCategory, setSelectedLastCategory] = useState(null);
  const subCategoriesRef = useRef();
  const subSubCategoriesRef = useRef();
  const lastCategoriesRef = useRef();

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();

    if (err) return setError(err);
    const results = res.map((category) => ({
      ...category,
      label: getText(category.name),
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        label: getText(subCategory.name),
        subCategories: subCategory.subCategories.map((subSubCategory) => ({
          ...subSubCategory,
          label: getText(subSubCategory.name),
          subCategories: subSubCategory.subCategories.map((lastCategory) => ({
            ...lastCategory,
            label: getText(lastCategory.name),
          })),
        })),
      })),
    }));

    const topCategory = results.find(
      (cat) =>
        cat.id === value ||
        cat.subCategories.find(
          (subCat) =>
            subCat.id === value ||
            subCat.subCategories.find((el) => el.id === value || el.subCategories.find((i) => i.id === value)),
        ),
    );
    const subCategory = results
      .flatMap((cat) => cat.subCategories)
      .find(
        (subCat) =>
          subCat.id === value ||
          subCat.subCategories.find((el) => el.id === value || el.subCategories.find((i) => i.id === value)),
      );
    const subSubCategory = results
      .flatMap((cat) => cat.subCategories.flatMap((subCat) => subCat.subCategories))
      .find((el) => el.id === value || el.subCategories.find((i) => i.id === value));
    const lastCategory = results
      .flatMap((cat) =>
        cat.subCategories.flatMap((subCat) => subCat.subCategories.flatMap((subSubCat) => subSubCat.subCategories)),
      )
      .find((el) => el.id === value || el.subCategories.find((i) => i.id === value));

    setCategories(results);
    setSelectedCategory(topCategory);
    setSelectedSubCategory(subCategory);
    setSelectedSubSubCategory(subSubCategory);
    setSelectedLastCategory(lastCategory);
  };

  const handleCategoryChange = (val) => {
    setSelectedCategory(val);

    if (val?.subCategories?.length) {
      subCategoriesRef.current?.changeValue(val.subCategories[0]);
      !selectedSubCategory && handleSubCategoryChange(val.subCategories[0]);
    } else {
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
      setSelectedLastCategory(null);
      isFunction(onChange) && onChange(val?.id);
    }
  };

  const handleSubCategoryChange = (val) => {
    setSelectedSubCategory(val);

    if (val?.subCategories?.length) {
      subSubCategoriesRef.current?.changeValue(val.subCategories[0]);
      !selectedSubSubCategory && handleSubSubCategoryChange(val.subCategories[0]);
    } else {
      setSelectedSubSubCategory(null);
      setSelectedLastCategory(null);
      isFunction(onChange) && onChange(val?.id);
    }
  };

  const handleSubSubCategoryChange = (val) => {
    setSelectedSubSubCategory(val);

    if (val?.subCategories?.length) {
      lastCategoriesRef.current?.changeValue(val.subCategories[0]);
      !selectedLastCategory && handleLastCategoryChange(val.subCategories[0]);
    } else {
      setSelectedLastCategory(null);
      isFunction(onChange) && onChange(val?.id);
    }
  };

  const handleLastCategoryChange = (val) => {
    setSelectedLastCategory(val);
    isFunction(onChange) && onChange(val?.id);
  };

  return (
    <div css={categoryContainer}>
      <Dropdown
        noClear
        small
        placeholder={getText('category')}
        value={selectedCategory}
        options={categories}
        onChange={handleCategoryChange}
        css={selectCategoryDropDown}
        uniqueKey="id"
        displayKey="label"
      />
      {!!selectedCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={subCategoriesRef}
          value={selectedSubCategory}
          placeholder={getText('subCategory')}
          options={selectedCategory?.subCategories}
          onChange={handleSubCategoryChange}
          css={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
      {!!selectedSubCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={subSubCategoriesRef}
          value={selectedSubSubCategory}
          placeholder={getText('subSubCategory')}
          options={selectedSubCategory?.subCategories}
          onChange={handleSubSubCategoryChange}
          css={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
      {!!selectedSubSubCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={lastCategoriesRef}
          value={selectedLastCategory}
          placeholder={getText('lastSubCategory')}
          options={selectedSubSubCategory?.subCategories}
          onChange={handleLastCategoryChange}
          className={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
    </div>
  );
};

export default DealCategories;
