import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { omit } from 'lodash-es';
import { Input, Select, TimePicker, Form } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Button, Breadcrumbs } from 'components/UIExternal';
import { CountryDropdown, DatePicker, Icon, BaseCheckbox } from 'components/ui';
import { mergeDateTime } from 'utils/moment';
import useError from 'services/errorHandling/useError';
import { createNotifications } from 'services/api/notificationsService';
import { sendToOptions, createNotificationPages } from './config';
import {
  formStyle,
  createNotificationFormContainer,
  notificationCountry,
  notificationSendTo,
  bodyText,
  notificationActions,
  titleStyle,
  backButtonStyle,
  breadcrumbsStyles,
  cancelButtonStyle,
  mainBodyNotification,
  notificationCheckboxStyle,
} from './styles';

export const CreateNotification = () => {
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const inputRef = useRef();
  const textAreaRef = useRef();
  const { setError } = useError();
  const [form] = Form.useForm();
  const history = useHistory();
  const [showDateTime, setShowDateTime] = useState(false);

  const handleSendNotification = async (data) => {
    const reworkedNotificationsData = {
      ...omit(data, ['date', 'time', 'immediatelyNotification']),
      publishDate: data?.date && data?.time && mergeDateTime(moment.utc(data?.date), data?.time).format(),
      countryIds: data.countryIds === 0 ? countries.map((el) => el.id) : [data.countryIds],
    };

    const [, err] = await createNotifications(reworkedNotificationsData);
    err ? setError(err) : history.push('/notifications');
  };

  return (
    <section css={createNotificationFormContainer}>
      {/* createNotificationPages should be function because it has getText inside which throw error if it is used before configuration */}
      <Breadcrumbs css={breadcrumbsStyles} items={createNotificationPages()} />

      <header className="header flex ">
        <Button type="link" onClick={() => history.goBack()} small css={backButtonStyle}>
          <Icon iconName="las la-arrow-left" />
        </Button>
        <h1 css={titleStyle}>{getText('createNotifications')}</h1>
      </header>
      <article css={formStyle}>
        <Form form={form} onFinish={handleSendNotification}>
          <div css={mainBodyNotification}>
            <Form.Item
              labelCol={{ lg: 7 }}
              name="countryIds"
              labelAlign="left"
              label={getText('country')}
              rules={[
                {
                  required: true,
                  message: getText('pleaseSelectCountry'),
                },
              ]}>
              <CountryDropdown
                css={notificationCountry}
                placeholder={getText('selectCountry')}
                onChange={(value) => form.setFieldsValue({ countryIds: value })}
              />
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 7 }}
              name="merchantsOnly"
              labelAlign="left"
              label={getText('sendTo')}
              rules={[
                {
                  required: true,
                  message: getText('pleaseSelectSendTo'),
                },
              ]}>
              <div css={notificationSendTo}>
                <Select
                  placeholder={getText('pleaseSelect')}
                  allowClear
                  onChange={(e) => form.setFieldsValue({ merchantsOnly: e })}
                  // sendToOptions should be function because it has getText inside which throw error if it is used before configuration
                  options={sendToOptions()}
                />
              </div>
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 7 }}
              name="title"
              labelAlign="left"
              label={getText('title')}
              rules={[
                {
                  required: true,
                  message: getText('pleaseAddTitle'),
                },
                ({ getFieldValue }) => ({
                  validator() {
                    if (getFieldValue('title').length > 250)
                      return Promise.reject(new Error(getText('fieldLengthMax', { count: 250 })));
                    else return Promise.resolve();
                  },
                }),
              ]}>
              <Input ref={inputRef} placeholder={getText('enterTitle')} />
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 7 }}
              name="message"
              labelAlign="left"
              label={getText('bodyText')}
              rules={[
                {
                  required: true,
                  message: getText('pleaseAddBodyText'),
                },
                ({ getFieldValue }) => ({
                  validator() {
                    if (getFieldValue('message').length > 500)
                      return Promise.reject(new Error(getText('fieldLengthMax', { count: 500 })));
                    else return Promise.resolve();
                  },
                }),
              ]}>
              <Input.TextArea
                ref={textAreaRef}
                placeholder={getText('pleaseDescribeYourNotification')}
                css={bodyText}
                autoSize
              />
            </Form.Item>
            <Form.Item
              css={notificationCheckboxStyle}
              initialValue={!showDateTime}
              valuePropName="checked"
              name="immediatelyNotification">
              <BaseCheckbox onChange={() => setShowDateTime((prev) => !prev)}>
                {getText('sendNotificationImmediately')}
              </BaseCheckbox>
            </Form.Item>
            {showDateTime && (
              <div>
                <Form.Item
                  labelCol={{ lg: 7 }}
                  name="date"
                  labelAlign="left"
                  label={getText('date')}
                  rules={[
                    {
                      required: true,
                      message: getText('pleaseSelectDate'),
                    },
                  ]}>
                  <DatePicker disabledDate={(current) => current.isBefore(moment())} format="DD/MM/YYYY" hideToday />
                </Form.Item>
                <Form.Item
                  labelCol={{ lg: 7 }}
                  name="time"
                  labelAlign="left"
                  label={getText('time')}
                  rules={[
                    {
                      required: true,
                      message: getText('pleaseSelectTime'),
                    },
                  ]}>
                  <TimePicker use12Hours format="h:mm a" allowClear minuteStep={60} placeholder="00" showNow={false} />
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
        <Form.Item>
          <div css={notificationActions}>
            <Button
              type="link"
              small
              onClick={() => {
                form.resetFields();
                history.goBack();
              }}
              css={cancelButtonStyle}>
              {getText('cancel')}
            </Button>
            <Button type="primary" onClick={() => form.submit()}>
              {getText('sendNotification')}
            </Button>
          </div>
        </Form.Item>
      </article>
    </section>
  );
};
