import { theme } from 'styles';

export const sectionTitle = {
  fontWeight: 700,
  fontSize: '1.5rem',
  margin: '1.5em 0px 1em 0px',
};

export const paymentChart = {
  display: 'flex',
  flexDirection: 'column',
  '& table': {
    flex: 1,
    marginTop: '2em',
  },
};

export const couponsChart = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& table': {
    flex: 1,
  },
};

export const overviewRow = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const overviewBox = {
  border: `1px solid ${theme.gray500}`,
  padding: '1em',
  borderRadius: 3,
  minWidth: '13.625rem',
  '& p': {
    margin: 0,
  },
  '& p:first-of-type': {
    fontSize: '3rem',
    color: theme.gray100,
    fontWeight: 500,
    [theme.Mobile]: {
      fontSize: '1.875rem',
    },
  },
  '& p:last-of-type': {
    fontSize: '0.8125rem',
    color: theme.gray300,
  },
  marginBottom: '4em',
};

export const borderBox = {
  border: `2px solid ${theme.gray500}`,
  borderRadius: 2,
  marginBottom: '1.5em',
  [theme.Desktop]: {
    marginLeft: '0.75em',
  },
};

export const dailySalesTitleWrapper = {
  padding: '0 1em',
  marginTop: '1.5em',
  marginBottom: '1em',

  '& label:first-of-type': {
    fontSize: '1.5rem',
    fontWeight: 500,
    flexGrow: 1,
  },
  '& label:not(:first-of-type)': {
    fontSize: '0.75rem',
    [theme.Mobile]: { marginRight: '0.25em', marginLeft: '0.25em' },
    marginRight: '0.5em',
    marginLeft: '0.5em',
    color: theme.gray300,
  },
  '& button': {
    border: 'none',
    background: 'transparent',
    fontSize: '1rem',
    color: theme.blue2,
    textWrap: 'wrap',
  },
  '& button:hover': {
    color: theme.blue,
  },
  [theme.Mobile]: {
    textAlign: 'center',
    '& label:first-of-type': {
      marginBottom: '1em',
    },
    '& button': {
      marginTop: '1em',
      width: '100%',
    },
  },
};

export const container = {
  padding: '0 2em',
};

export const filterRow = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  marginBottom: '2em',
};

export const dropDownStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0 1em',
};

export const dateContainer = {
  width: '18.75rem',
  marginBottom: '0.625em',
};

export const datePickerStyles = {
  display: 'flex',
  gap: '1em',
  flexWrap: 'wrap',
  '& span': {
    marginRight: '0.5em',
  },
};
