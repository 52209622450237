import { theme } from 'styles';

export const priceContainer = (className) => [
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  className,
];

export const savingStyles = {
  fontSize: '0.875rem',
  color: theme.gray200,
  marginTop: '0.375em',
};

export const priceStyles = {
  fontSize: '1rem',
  fontWeight: 500,
  margin: '0 0.25em 0 0',
  lineHeight: 1.5,

  '&.DealDetails': {
    fontSize: '1.375rem',
  },

  '& .quantity': {
    color: theme.gray300,
    marginLeft: '0.6em',
    letterSpacing: 2,
  },
};

export const beforeDiscountStyles = {
  fontSize: '0.875rem',
  fontWeight: 400,
  color: theme.gray300,
  textDecoration: 'line-through',
  margin: '0 0.25em 0 0',
  lineHeight: 1.5,
};
