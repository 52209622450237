import PropTypes from 'prop-types';
import { PriceLabel } from 'components/shared';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { amountsBox } from './styles';

const TransactionsAmountsTotals = ({ one, oesp, fiat, fiatCurrency, title }) => {

  return (
    <div css={amountsBox} data-id="transactions-total-amounts-box">
      <h6>{title}</h6>
      <div data-id="transactions-total-amounts-values">
        <span className='amount-label'>
          <PriceLabel crypto={one} ignoreEmpty fiatOnly={false} />
        </span>
        <span className='amount-label'>
          <PriceLabel fiat={fiat} fiatDesiredCurrency={fiatCurrency} fiatOnly={false} />
        </span>
        <span className='amount-label'>
          <PriceLabel crypto={oesp} cryptoDesiredCurrency={CRYPTO_CURRENCIES.OESP.code} fiatOnly={false} ignoreEmpty />
        </span>
      </div>
    </div>
  );
};

TransactionsAmountsTotals.propTypes = {
  one: PropTypes.number,
  oesp: PropTypes.number,
  fiat: PropTypes.number,
  fiatCurrency: PropTypes.string,
  title: PropTypes.string,
};

export default TransactionsAmountsTotals;
