import PropTypes from 'prop-types';
import { printPrice } from '@oneecosystem/dealshaker-core';
import { priceLabel } from './styles';

const PriceLabel = props => {
  // Always turn off default stringify, because the component always return react object
  const { fiatLabel, cryptoLabel } = printPrice({ ...props, stringify: false });

  if (!cryptoLabel) {
    return (
      <span dir="auto" className={priceLabel}>
        {fiatLabel}
      </span>
    );
  }
  if (!fiatLabel) return <span className={priceLabel}>{cryptoLabel}</span>;

  return (
    <>
      <span dir="auto" className={priceLabel}>
        {fiatLabel}
      </span>
      &nbsp;+&nbsp;
      <span className={priceLabel}>{cryptoLabel}</span>
    </>
  );
};

PriceLabel.propTypes = {
  stringify: PropTypes.bool,
};

export default PriceLabel;
