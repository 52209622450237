import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, useDeepEffect } from 'components/UIExternal';
import { CLEARING_AND_SETTLEMENT } from 'enums/settings';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const ClearingPeriods = ({ data, onUpdate }) => {
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (e) => setValues((prev) => ({ ...prev, [e?.target.getAttribute('name')]: e?.target.value }));

  if (isNil(data)) return <FormBoxLoader number={6} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('basicClearing')}</span>
        <Input
          name={CLEARING_AND_SETTLEMENT.BASIC_CLEARING_DAYS}
          value={values?.[CLEARING_AND_SETTLEMENT.BASIC_CLEARING_DAYS]}
          onChange={handleChange}
        />
      </div>
      <div css={submitActions}>
        <Button small type="secondary" onClick={() => onUpdate(values)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

ClearingPeriods.propTypes = {
  data: PropTypes.object,
  hasSelectedCountry: PropTypes.bool,
  onUpdate: PropTypes.func,
  onReset: PropTypes.func,
};
