import { theme } from 'styles';

export const popoverWrapper = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'fixed !important',
  borderRadius: 4,
  paddingTop: '0px !important',
  backgroundColor: theme.white,
  [theme.Mobile]: {
    width: '100%',
    left: '0 !important',
    '& .ant-popover-content': { width: '100%' },
  },
  [theme.Desktop]: { maxWidth: '21.875rem' },
};

export const badgeContainer = {
  position: 'relative',
  [theme.Mobile]: {
    padding: '0.25em',
  },
};

export const badgeNumber = (count) => ({
  position: 'absolute',
  top: 0,
  right: '0.625rem',
  display: count < 1 && 'none',
  backgroundColor: theme.primary_yellow,
  fontSize: '0.5rem',
  fontWeight: 700,
  borderRadius: 2,
  padding: '0.0625em',
});

export const notificationMessageButton = {
  marginTop: '0.375em',
  border: `1px solid ${theme.gray500}`,
  width: '100%',
  borderRadius: 6,
  height: '1.875rem',
  color: theme.gray100,
  fontWeight: 400,
  fontSize: '0.875rem',

  '&:hover': {
    borderColor: theme.primary_blue_hover,
    color: theme.primary_blue_hover,
  },
};
