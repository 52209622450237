import { theme } from './theme';

export const pageContainer = (otherStyles) => ({
  padding: '1em 3em',
  ...otherStyles,
});

export const pageFilterCollapse = {
  width: '100%',
  border: 'none',
  marginBottom: '2em',
  backgroundColor: 'transparent',

  '& .ant-collapse-item': {
    borderBottom: ' none',
  },
  '& .ant-collapse-header': {
    backgroundColor: theme.gray700,
    borderRadius: '2rem !important',
    border: 'none',
  },
  '& .ant-collapse-content': {
    border: 'none',
  },
  '& .ant-collapse-content-box': {
    paddingBottom: 0,
  },

  '& .filters-container': {
    display: 'flex',
    gap: '1rem',
    flexFlow: 'row wrap',
    alignItems: 'flex-end',

    '& label': {
      display: 'block',
      fontWeight: 500,
    },
    '& .filters-date-pickers': {
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: '0.5em 1em',
    },
    '& > *': {
      flex: '1 0 15rem',
      maxWidth: '20rem',
      '&.flex-auto': {
        flex: '0 0 auto',
      },
    },
  },
};
