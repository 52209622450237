import { theme } from 'styles';

export const search = {
  border: 'none !important',
  marginTop: '1.875em',
};

export const loadMoreBtn = {
  [theme.Mobile]: {
    margin: 'auto',
    marginBottom: '2.1875em',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
  marginBottom: '2.5em',
};

export const searchResultWrapper = {
  borderRadius: '10px !important',
};

export const uploadBtn = {
  justifyContent: 'start',
  padding: '0.625em',
  color: theme.gray200,
  backgroundColor: theme.gray600,
  '& .la-paperclip': { fontSize: '1.25rem' },
  '& div': { alignItems: 'start' },
  '& .click-here-lbl': { fontSize: '0.625rem', color: theme.gray100 },
  '& .attach-text-lbl': { fontSize: '0.625rem', color: theme.gray200 },
};

export const noMessagesBox = {
  textAlign: 'center',
  '& .date': { fontSize: '0.75rem' },
  '& img': { marginTop: '4.375em' },
  '& .start': { fontSize: '1.125rem', fontWeight: 700, marginBottom: 0 },
  '& .ask-anything': { fontSize: '0.875rem', marginBottom: '3.75em' },
};

export const participantSelector = {
  marginTop: '1.25em',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.25em 1.25em 0 1.25em',
  '& p': { margin: '0 0 0 0.625em', fontSize: '1rem' },
  '& .wrapper': { alignItems: 'center', marginBottom: '1.0625em' },
  '& .popover': { justifyContent: 'flex-end' },
  '& .more-btn': { border: 'none', background: theme.white, fontSize: '1.375rem', padding: 0 },
};

export const messageWrapper = (isCurrentUser) => ({
  verticalAlign: 'middle',
  marginBottom: '1.25em',
  '& .reverse-avatar ': { display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' },
  '& .message ': {
    backgroundColor: isCurrentUser ? theme.skyblue : theme.gray550,
    borderRadius: 2,
    padding: '0.5em 0.75em',
    marginTop: '0.25em',
  },
  '& .image ': { marginTop: '0.5em' },
  '& .user-name-label ': { marginRight: '0.3125em', marginLeft: '0.3125em', fontSize: '0.8125rem', fontWeight: 500 },
  '& .hour-label ': { fontSize: '0.6875rem', color: theme.gray200, display: 'flex', alignItems: 'center' },
});

export const galleryModal = {
  '& .ant-modal-content': {
    [theme.Desktop]: { padding: '0 4.625em' },
    [theme.Mobile]: { padding: '0 0.25em' },
  },
  '& .ant-modal-body': { padding: '0.625em 0' },
  '& .image-gallery-slide': {
    width: '100%',
    [theme.Desktop]: { height: '41.25rem' },
    [theme.Mobile]: { height: '31.25rem' },
    backgroundColor: theme.gray500,
    paddingBottom: '1',
    borderRadius: 4,
  },
  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  '& .image-gallery-right-nav': {
    padding: 0,
    filter: 'none',
    right: -55,
    [theme.Mobile]: { right: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
  '& .image-gallery-left-nav': {
    filter: 'none',
    padding: 0,
    left: -55,
    [theme.Mobile]: { left: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
};

export const allMessagesWrapper = {
  maxHeight: '28.125rem',
  paddingRight: '0.625em',
  overflowY: 'auto',
  display: 'flex !important',
  flexDirection: 'column-reverse',
  '& .date-label ': { fontSize: '0.75rem', display: 'flex', justifyContent: 'center' },
  '& .ant-row ': { flexDirection: 'column-reverse' },
  '& .reverse ': { flexDirection: 'column-reverse', display: 'flex' },
};

export const imagesPreview = {
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-image': { objectFit: 'cover !important' },
  '& .ant-upload-list-item-info': { borderRadius: 3 },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [theme.Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [theme.Mobile]: { width: '6.25rem', height: '5rem' },
  },
};

export const linkBtnStyle = {
  fontSize: '0.875rem',

  '& > span': {
    marginRight: '0.75em',
    fontSize: '1.375rem',
  },
};

export const bigLinkBtnStyle = {
  fontSize: '1.0625rem',
  fontWeight: 700,

  '& > span': {
    marginRight: '0.75em',
    fontSize: '1.375rem',
  },
};
