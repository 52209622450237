import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { useUrlParams } from 'components/UIExternal';
import { PageHeader } from 'components/shared';
import { refundStatusColor, refundStatusInverse, refundStatusSelect } from 'enums';

export const RefundsFilterBar = ({ paginationRef, filterKeys, defaultQueryParams }) => {
  const { queryParams } = useUrlParams();
  const queryStatus = queryParams[filterKeys.status] && queryParams[filterKeys.status].map(Number);

  return (
    <PageHeader
      title={getText('refunds')}
      statusProps={{
        defaultValue: queryStatus ?? defaultQueryParams[filterKeys.status],
        onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.status]: value }),
        options: refundStatusSelect(),
        tagProps: { type: refundStatusColor, value: refundStatusInverse },
      }}
    />
  );
};

RefundsFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
  defaultQueryParams: PropTypes.object,
};
