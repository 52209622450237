import { notificationType } from 'enums';

export const getAllNotificationsParams = options => ({
  ...options,
  targetNotifications: Object.keys(notificationType).slice(0, -1),
});

export const getAllNotificationsBody = res => ({
  ...res,
  items: res.items?.map(el => ({ ...el, additionalData: JSON.parse(el.additionalData) })),
});

export const getNotificationByIdBody = res => ({
  ...res,
  additionalData: JSON.parse(res.additionalData),
});
