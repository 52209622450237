import { theme } from 'styles';

export const pricePicker = {
  width: '40%',
  '& .ant-slider-dot': { display: 'none' },
  '& .ant-slider': { margin: 0 },
  '& .ant-slider-mark-text': {
    transform: 'none !important',
    color: theme.gray200,
    top: '-1.875em',
    '&:last-of-type': { left: '92% !important' },
  },
  '& .ant-slider-track': {
    backgroundColor: `${theme.primary_yellow} !important`,
    height: '0.5em',
  },
  '& .ant-slider-rail': { height: '0.5em' },
  '& p': {
    marginTop: '2.5em',
    marginBottom: '2.375em',
    fontWeight: 400,
    fontSize: '0.75rem',
    color: theme.gray200,
  },
  '& h5': { textAlign: 'center' },
};

export const sliderText = {
  margin: '0 !important',
  textAlign: 'center',
};

export const formDateStyle = {
  marginBottom: '1em',
  '& > div': {
    padding: '0 !important',
  },
};

export const formStyles = {
  width: '100%',
  '& > div': {
    marginBottom: '1em',
  },
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
};
