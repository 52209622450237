const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'USER_DATA_FETCHED':
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loaded: true,
      };
    case 'USER_INIT':
      return {
        ...state,
        isAuthenticated: false,
        loaded: true,
      };
    case 'ERROR_USER_DATA_FETCHED':
      return {
        ...state,
        isAuthenticated: false,
        userInfo: null,
        error: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
