import { theme } from 'styles';

export const orderDetailsContainer = {
  overflowY: 'auto',
  padding: '0 1.5em',
  border: `1px solid ${theme.gray500}`,
  borderRadius: 5,
  flex: '4 0 0',
  marginBottom: '1em',

  '& .gray': {
    color: theme.dark_gray,
  },

  '& .order-details-section': {
    borderRadius: 10,
    border: `1px solid ${theme.gray400}`,
    padding: '2em',
    '&:last-of-type': {
      marginBottom: '2em',
    },
  },

  '& .order-details-section-title': {
    margin: '2em 0 0.8em',
  },
};
