import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  initLanguagesStore,
  initTranslationsStore,
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
} from '@veraio/strank';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { initLocationsStore, initCurrenciesStore, useLocations } from '@oneecosystem/dealshaker-core';
import { useDeepEffect } from 'components/UIExternal';
import { useUser } from 'stores/contexts/User';
import Routes from 'enums/Routes';
import Logout from 'screens/Public/Logout';
import RedirectCallback from 'screens/Public/RedirectCallback';

import Routing from 'screens/Routing';

const App = () => {
  const geoLocation = useLocations((locations) => locations.geoLocation);
  const { changeLanguage, allEnvLanguages } = useTranslations();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    initLanguagesStore();
    initTranslationsStore();
  }, []);

  useEffect(() => {
    isAuthenticated && initializePrivateStores();
  }, [isAuthenticated]);

  useDeepEffect(() => {
    geoLocation?.languageCode &&
      allEnvLanguages?.length &&
      changeLanguage(getSavedLanguage() ?? geoLocation.languageCode ?? getBrowserLanguage());
  }, [geoLocation, allEnvLanguages]);

  const initializePrivateStores = async () => {
    await initLocationsStore({
      isManager: !hasRole(USER_ROLES.DS_ADMIN),
    });
    initCurrenciesStore();
  };

  return (
    <Switch>
      <Route exact path={Routes.RedirectCallback} component={RedirectCallback} />
      <Route exact path={Routes.Logout} component={Logout} />
      <Route component={Routing} />
    </Switch>
  );
};

export default App;
