import { theme } from 'styles';

export const inputContainer = {
  flexDirection: 'row',
  fontWeight: 400,
  fontSize: '0.8rem',
  color: theme.gray100,
  padding: '0.4em 0.7em',
  '&.ant-input': {
    border: `1px solid ${theme.gray500}`,
    borderRadius: 2,
  },
  '&.ant-input:focus, .ant-input-focused': {
    borderColor: theme.primary_blue,
    boxShadow: 'none',
  },
};
