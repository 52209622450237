import PropTypes from 'prop-types';
import { isFunction, uniqBy } from 'lodash-es';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Dropdown } from 'components/UIExternal';

const CountryDropdown = ({ onChange, placeholder, value, className, noClear }) => {
  const countries = useLocations((locationsState) => locationsState.countries);

  const handleChange = (prop, newValue) => {
    isFunction(onChange) &&
      onChange(
        prop,
        newValue,
        countries.find((el) => el.id === prop),
      );
  };

  return (
    <Dropdown
      small
      withSearch
      noClear={noClear}
      placeholder={placeholder}
      options={uniqBy(countries, 'id')}
      uniqueKey="id"
      displayKey="name"
      mapValue={(el) => el?.id}
      value={value}
      onChange={handleChange}
      className={className}
    />
  );
};

CountryDropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  className: PropTypes.string,
  noClear: PropTypes.bool,
};

export default CountryDropdown;
