import { isArray } from '@veraio/core';

export const flattenTree = (treeArray, childrenKey, itemKey) => {
	let flatTree = [];
	treeArray.forEach(item => {
		flatTree = flattenItem(item, flatTree, childrenKey, itemKey, 0, {});
	});

	return flatTree;
};

const flattenItem = (item, currentFlatTree, childrenKey, itemKey, level, parentItem) => {
	const itemToInclude = { ...item };
	delete itemToInclude[childrenKey];
	currentFlatTree = [...currentFlatTree, itemToInclude];

	if (item[childrenKey] && isArray(item[childrenKey]) && item[childrenKey].length > 0) {
		const currParentItem = Object.assign({}, parentItem, { [level]: item[itemKey] });
		item[childrenKey].forEach(subItem =>
			currentFlatTree = flattenItem(
				{ ...subItem,
					parentItem: currParentItem,
					level: level + 1
				},
				currentFlatTree,
				childrenKey,
				itemKey,
				level + 1,
				currParentItem
			));
		}

	return currentFlatTree;
};