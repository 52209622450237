export const ordersContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '0 2em',
};

export const ordersContentContainer = {
  display: 'flex',
  gap: '1rem',
  flex: 1,
  overflowY: 'hidden',
};
