import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { Form, Col } from 'antd';
import { useTranslations } from '@veraio/strank';
import { FormEditButtons } from 'components/shared';
import { BaseCheckbox } from 'components/ui/Checkboxes';

import { checkboxesWrapper } from './styles';

const OtherEdit = ({ deal, onCancel, onEdit }) => {
  const { getText } = useTranslations();

  const newInitialValues = {
    ...pick(deal, 'singlePurchasePerUser', 'listed'),
  };

  const handleSaveForm = async (values) => {
    await onEdit(values, ['singlePurchasePerUser', 'listed']);
    onCancel();
  };

  return (
    <>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('predefinedTerms')}</span>
      </Col>
      <Form layout="vertical" initialValues={newInitialValues} onFinish={handleSaveForm}>
        <div className={checkboxesWrapper} data-id="buyOneDealCheckbox">
          <Form.Item valuePropName="checked" name="singlePurchasePerUser" style={{ marginBottom: '0' }}>
            <BaseCheckbox>{getText('oneCustomerCanBuyOneDeal')}</BaseCheckbox>
          </Form.Item>
        </div>
        <div className={checkboxesWrapper} data-id="listedDealCheckbox">
          <Form.Item valuePropName="checked" name="listed" style={{ marginBottom: '0' }}>
            <BaseCheckbox>{getText('allowDealOnLists')}</BaseCheckbox>
          </Form.Item>
        </div>
        <FormEditButtons onCancel={onCancel} />
      </Form>
    </>
  );
};

OtherEdit.propTypes = {
  deal: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default OtherEdit;
