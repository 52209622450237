import { Table } from 'components/UIExternal';
import { BusinessesFilterBar } from 'components/screens';
import { businessStatus } from 'enums';
import { getAllBusinesses } from 'services';
import { businessesColumns } from './config';
import { pageContainer } from './styles';

const filterKeys = {
  status: 'statusIds',
  search: 'searchText',
  countries: 'countryIds',
  owner: 'ownerName',
  user: 'userName',
  email: 'email',
  creationDateFrom: 'creationDateFrom',
  creationDateTo: 'creationDateTo',
  modifiedDateFrom: 'modifiedDateFrom',
  modifiedDateTo: 'modifiedDateTo',
};

const Businesses = () => {
  return (
    <section css={pageContainer}>
      <Table
        // businessesColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={businessesColumns()}
        getDataMethod={getAllBusinesses}
        filterBar={BusinessesFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.status]: [businessStatus.pending] }}
      />
    </section>
  );
};

export default Businesses;
