import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { Avatar, Col, List, Popover, Row, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { TabPane, Tabs, Icon } from 'components/ui';
import { useUser } from 'stores/contexts/User';
import apiRoutes from 'config/apiRoutes';
import { chatStatus } from 'enums/ChatEnum';
import { formatDefaultDate, formatTime } from 'utils/valueFormatter';
import { delay } from 'utils/queryUtils';
import { markChatAsArchived } from 'services/api/chatService';
import useError from 'services/errorHandling/useError';
import { usePageNumberPaging } from 'services/axios/paging';
import noChatsImg from 'assets/images/chat/no-messages.png';
import { mt, mb } from 'styles';
import {
  notificationsTable,
  typeTime,
  message,
  mobileWrapperTypeMessage,
  bold,
  noNotificationImage,
  noChatsContainer,
  dealTitle,
  pageWrapper,
  filterWrapper,
  archiveBtnStyle,
  messageBtnStyle,
  tabs,
  tabContentStyle,
  moreBtnStyle,
  primaryBtnStyle,
} from './styles';

export const Messages = () => {
  const history = useHistory();
  const currentAccountId = useUser()?.userInfo?.id;
  const { getText } = useTranslations();
  const { setError } = useError();
  const pageSize = 5;
  const getChatsURLFunctions = {
    1: apiRoutes.CHAT_LIST_CHATS,
    2: apiRoutes.CHAT_LIST_GROUP_CHATS,
    3: apiRoutes.CHAT_LIST_MODERATOR_CHATS,
  };
  const [activeMainFilter, setActiveMainFilter] = useState(1);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [chats, loadNewFilter, loadNewPage, reloadAllLoadedPages, isLoading] = usePageNumberPaging(
    getChatsURLFunctions[activeMainFilter],
    pageSize,
  );

  useEffect(() => {
    loadNewFilter({ sortBy: 0 });
  }, [activeMainFilter]);

  useEffect(() => {
    if (pageSize * pageNumber - chats?.length > 0) setNoMoreMessages(true);
    else setNoMoreMessages(false);
  }, [chats]);

  const chatContent = (
    <>
      <List
        css={notificationsTable}
        itemLayout="horizontal"
        bordered={!isMobile && 'true'}
        dataSource={chats}
        renderItem={(chat) => (
          <List.Item css={chat.statusId === chatStatus.Unread.id && 'unread'}>
            {isMobile ? (
              <div css={mobileWrapperTypeMessage}>
                <div css={typeTime}>
                  <Avatar.Group
                    maxCount={2}
                    onClick={() => history.push(`/messages/chat?chatId=${chat.id}`)}
                    size="large">
                    {chat.participants
                      .filter((user) => user.id !== currentAccountId)
                      .map((participant, i) => (
                        <Avatar
                          key={i}
                          onClick={() => history.push(`/messages/chat?chatId=${chat.id}`)}
                          src={participant.avatarThumbnailUrl}>
                          {participant.firstName?.charAt(0)}
                        </Avatar>
                      ))}
                  </Avatar.Group>
                  <Link to={`/messages/chat?chatId=${chat.id}`} className="flex flex-column">
                    <label>{chat.name}</label>
                    <label className="time">{moment(chat?.lastMessageDate).format('DD/MM/YYYY · hh:mm')}</label>
                  </Link>
                  <Popover
                    trigger="click"
                    placement="bottom"
                    css={chat.statusId === chatStatus.Unread.id && 'unread'}
                    content={
                      <>
                        <Button
                          type="link"
                          small
                          onClick={async () => {
                            await markChatAsArchived(chat.id, setError);
                            reloadAllLoadedPages();
                          }}
                          css={archiveBtnStyle}>
                          {getText('archive')}
                        </Button>
                        <Button type="link" small css={archiveBtnStyle}>
                          {getText('viewMerchant')}
                        </Button>

                        {chat.deal && (
                          <Button type="link" small linkTo={`/deal/${chat.deal.id}`} css={archiveBtnStyle}>
                            {getText('viewDeal')}
                          </Button>
                        )}
                      </>
                    }>
                    {/* We need this div because Popover puts ref to its only child to append onClick method which opens the popup
         But our button do not accept ref and it is not acceptable to have ref on button, so we just add parent span */}
                    <span>
                      <Button type="link" small css={moreBtnStyle}>
                        <Icon iconName="las la-ellipsis-v" />
                      </Button>
                    </span>
                  </Popover>
                </div>

                {chat.deal?.title && (
                  <label css={[chat.statusId === chatStatus.Unread.id && bold, message, dealTitle]}>
                    {chat.deal.title}
                  </label>
                )}
                {chat.deal?.description && <label>{chat.deal.description}</label>}
              </div>
            ) : (
              <Row style={{ width: '100%' }}>
                <Col lg={6} span={24}>
                  <div className="flex">
                    <Avatar.Group
                      maxCount={2}
                      onClick={() => history.push(`/messages/chat?chatId=${chat.id}`)}
                      size="large">
                      {chat.participants
                        .filter((user) => user.id !== currentAccountId)
                        .map((participant, i) => (
                          <Avatar
                            key={i}
                            onClick={() => history.push(`/messages/chat?chatId=${chat.id}`)}
                            src={participant.avatarThumbnailUrl}>
                            {participant.firstName?.charAt(0)}
                          </Avatar>
                        ))}
                    </Avatar.Group>
                    <Link to={`/messages/chat?chatId=${chat.id}`} css={typeTime}>
                      <label css={chat.statusId === chatStatus.Unread.id && bold} className="type">
                        {chat.name}
                      </label>
                      <br />
                      <label className="time">
                        {formatDefaultDate(chat.lastMessageDate)}
                        {formatTime(chat.lastMessageDate)}
                      </label>
                    </Link>
                  </div>
                </Col>
                <Col lg={16} span={24}>
                  {(chat?.deal?.title || chat?.deal?.description) && (
                    <div className="flex-column">
                      {chat.deal?.title && (
                        <label
                          css={[chat.statusId === chatStatus.Unread.id && bold, message, dealTitle]}
                          className="type flex-column">
                          {chat.deal.title}
                        </label>
                      )}
                      {chat.deal?.description && <label css={message}>{chat.deal?.description}</label>}
                    </div>
                  )}
                </Col>
                <Col lg={{ span: 1, offset: 1 }} span={24}>
                  <Popover
                    trigger="click"
                    placement="bottom"
                    css={chat.statusId === chatStatus.Unread.id && 'unread'}
                    content={
                      <>
                        <Button
                          type="link"
                          small
                          onClick={async () => {
                            await markChatAsArchived(chat.id, setError);
                            reloadAllLoadedPages();
                          }}
                          css={archiveBtnStyle}>
                          {getText('archive')}
                        </Button>

                        {chat.deal && (
                          <Button type="link" small linkTo={`/deal/${chat.deal.id}`} css={archiveBtnStyle}>
                            {getText('viewDeal')}
                          </Button>
                        )}
                      </>
                    }>
                    {/* We need this div because Popover puts ref to its only child to append onClick method which opens the popup
        But our button do not accept ref and it is not acceptable to have ref on button, so we just add parent span */}
                    <span>
                      <Button type="link" small css={moreBtnStyle}>
                        <Icon iconName="las la-ellipsis-v" />
                      </Button>
                    </span>
                  </Popover>
                </Col>
              </Row>
            )}
          </List.Item>
        )}
      />
      {!noMoreMessages && (
        <Button
          type="secondary"
          small
          onClick={() =>
            delay(() => {
              loadNewPage(pageNumber + 1);
              setPageNumber((prev) => prev + 1);
            })
          }>
          {getText('loadMore')}
        </Button>
      )}
    </>
  );

  const noChats = (
    <div css={noChatsContainer}>
      <img css={noNotificationImage} src={noChatsImg} alt="No chats" />
      <div className="flex flex-column">
        <h4>{getText('youDontHaveAnyMessagesYet')}</h4>
        <label>{getText('placeWhereMessagesWillAppear')}</label>
        <Button type="primary" linkTo="/deals" small css={primaryBtnStyle}>
          {getText('browseDeals')}
        </Button>
      </div>
    </div>
  );

  return (
    <div css={pageWrapper}>
      <div css={filterWrapper} className="flex space-between">
        <Tabs css={tabs} onTabClick={(e) => setActiveMainFilter(Number(e))}>
          <TabPane
            key={1}
            tab={
              <div css={tabContentStyle}>
                <Icon iconName="las la-inbox" />
                {getText('inbox', {
                  inboxCount: chats.filter((chat) => chat.statusId === chatStatus.Unread.id).length,
                })}
              </div>
            }
          />
          <TabPane
            key={2}
            tab={
              <div css={tabContentStyle}>
                <Icon iconName="las la-user-friends" />
                {getText('groupMessages')}
              </div>
            }
          />
          <TabPane
            key={3}
            tab={
              <div css={tabContentStyle}>
                <Icon iconName="las la-shield-alt" />
                {getText('moderators')}
              </div>
            }
          />
          <TabPane
            key={4}
            tab={
              <Button type="link" small linkTo="/messages/chat" css={messageBtnStyle}>
                <label>{getText('startChat')}</label>
              </Button>
            }
          />
        </Tabs>
      </div>
      {isLoading ? (
        <div css={[mt({ lg: 50, span: 20 }), mb({ lg: 50, span: 20 })]} className="flex flex-center">
          <Spin size="large" />
        </div>
      ) : chats.length > 0 ? (
        chatContent
      ) : (
        noChats
      )}
    </div>
  );
};
