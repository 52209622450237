export const formStyles = {
  width: '100%',
  '& > div': {
    marginBottom: '1em',
  },
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
};

export const formDateStyle = {
  marginBottom: '1em',
  '& > div': {
    padding: '0 !important',
  },
};
