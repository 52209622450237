export const orderDetailsHeaderContainer = {
  padding: '2em 0',

  '& .order-details-status-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',

    '& .order-details-status': {
      fontSize: '0.8rem',
      textTransform: 'uppercase',
      padding: '0 0.5em',
      borderRadius: '5rem',
    },
  },
};
