import apiRoutes from 'config/apiRoutes';
import { deleteReq, patchReq } from '../axios/makeRequest';

import { postReq } from '../axios/makeRequest';

const baseUrl = `${apiRoutes.ADDRESS}/api/Media`;

export const removeMedia = (id, reason) => deleteReq(`${apiRoutes.MEDIA_REMOVE(id)}${reason && `?reason=${reason}`}`);

export const updateDealImageVisibility = (id, isHidden) => patchReq(`${baseUrl}/HideDealMedia/${id}`, { isHidden });

export const addImageToDeal = (data, dealId) => postReq(`${baseUrl}/AddImage/${dealId}`, data);
