// import { Desktop, Phone } from 'assets/css/breakpoints';
import { css } from '@emotion/react';

const getMaxBreakpoint = bp => `@media (max-width: ${bp}px)`;
const getMinBreakpoint = bp => `@media (min-width: ${bp}px)`;
const getResponsiveBreakpoint = bp => `@media (max-width: ${bp}px)`;

export const Mobile = getMaxBreakpoint(992);
export const Desktop = getMinBreakpoint(993);
export const Phone = getResponsiveBreakpoint(768);

export const formSectionWrapper = theme =>
  css({
    '& .upload-field-type': {
      flexWrap: 'wrap',
      gap: '1em',
    },
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.25em 2em',
      marginBottom: '1.5em',
    },
  });

export const filesContainer = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(15.625em, 2fr))',
  columnGap: '0.625em',
  margin: '1.25em 0',
  [Phone]: {
    gridTemplateColumns: '1fr',
  },
  '& .upload-text': {
    margin: '1em 0',
  },
});

export const filesListStyles = css({
  listStyle: 'none',
  display: 'grid',
  gap: '0.3125em',
  maxHeight: '13.125rem',
  [Phone]: {
    marginTop: '1em',
  },
  WebkitScrollbar: {
    width: '0.1875rem',
    height: '0.3125rem',
  },
  WebkitScrollbarThumb: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  overflowY: 'auto',
});

export const fileListItem = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.3rem',
    background: theme.gray600,
    padding: '0.75em 0.375em',
    fontSize: '0.75rem',
    height: '5rem',
    '& .file-info': {
      flex: '2 1 0',
      overflow: 'hidden',
    },
    '& .file-name, & .file-description': {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
    },
    '& .file-name': {
      color: theme.gray100,
      whiteSpace: 'pre-wrap',
      WebkitLineClamp: 1,
    },
    '& .file-description': {
      color: theme.gray300,
      WebkitLineClamp: 2,
    },
    '& .thumbnail-img': {
      width: '3rem',
      height: '3rem',
      borderRadius: '0.3rem',
      flex: '1 1 0',
    },
  });

export const toggleHiddenText = css({
  width: '15.625rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  transition: 'all .5s ease-in-out',
  '&:hover': {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
});

export const inputIcon = theme =>
  css({
    color: theme.primary_blue,
    '&.success': {
      color: theme.green,
    },
  });

export const recommendationContainer = theme =>
  css({
    color: theme.gray300,
    fontSize: '.8rem',
  });

export const btn = css({
    margin: '10px'
});

export const uploadContainer = css({
    maxWidth: '352px'
});

