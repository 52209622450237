import queryString from 'query-string';
import { pick, isNil } from 'lodash-es';

export const getAllDisputesParams = (options) => {
  const { statusIds } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(!isNil(statusIds) && { statusIds }),
    },
    { arrayFormat: 'index' },
  );
};
