import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { theme } from 'styles';

export const areaColumns = (onEdit, onDelete) => [
  {
    name: getText('areaName'),
    value: 'areaName',
  },
  {
    name: getText('actions'),
    width: 80,
    flex: '0 1 auto',
    render: (area) => (
      <>
        <Icon iconName="las la-pen" size={20} onClick={() => onEdit(area?.areaId)} />
        <Icon
          iconName="las la-trash"
          size={20}
          onClick={() => onDelete(area?.areaId)}
          style={{ color: theme.red, marginLeft: 10 }}
        />
      </>
    ),
  },
];
