import queryString from 'query-string';
import { isNil } from 'lodash-es';

export const getStatisticsOverviewParams = (options) => {
  const { countryId, businessId, startDate, endDate, currencyId } = options || {};
  return queryString.stringify(
    {
      ...(!isNil(countryId) && { countryId }),
      ...(!isNil(businessId) && { businessId }),
      ...(!isNil(startDate) && { startDate }),
      ...(!isNil(endDate) && { endDate }),
      ...(!isNil(currencyId) && { currencyId }),
    },
    { arrayFormat: 'index' },
  );
};

export const getStatisticsGraphParams = (options) => {
  const { countryId, businessId, statisticsPeriodId, categoryId, currencyId } = options;

  return queryString.stringify(
    {
      ...(!isNil(countryId) && { countryId }),
      ...(!isNil(businessId) && { businessId }),
      ...(!isNil(statisticsPeriodId) && { statisticsPeriodId }),
      ...(!isNil(categoryId) && { categoryId }),
      ...(!isNil(currencyId) && { currencyId }),
    },
    { arrayFormat: 'index' },
  );
};
