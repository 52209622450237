import { theme } from 'styles';

export const shadowedBox = {
  boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
};

export const borderedBox = {
  border: `2px solid ${theme.gray500}`,
  borderRadius: 3,
};
