import { getText } from '@veraio/strank';
import ClearingSettlementSharedFilterBar from '../ClearingSettlementSharedFilterBar';
import { CLEARING_SORT_SELECT } from 'enums';


const ClearingFilterBar = (props) => <ClearingSettlementSharedFilterBar
	{...props}
	sortOptions={CLEARING_SORT_SELECT(getText)}
/>;

export default ClearingFilterBar;