import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDeepEffect } from 'components/UIExternal';
import { objectToQuery } from 'utils/queryUtils';
import { getReq } from './makeRequest';

export const usePageNumberPaging = (baseUrl, pageSize, filter) => {
  const [pagingInfo, setPagingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);
  const [queryFilter, setQueryFilter] = useState({
    ...filter,
    pageNumber: 1,
    pageSize,
  });

  const queryString = objectToQuery(queryFilter);

  useEffect(() => {
    setQueryFilter((prev) => ({ ...prev, ...filter }));
  }, [filter]);

  useDeepEffect(() => {
    fetchData();
  }, [queryFilter]);

  const fetchData = async () => {
    setIsLoading(true);

    const res = await axios.get(`${baseUrl}${queryString}`);
    const { items, totalCount } = res.data;

    setPagingInfo({
      count: totalCount,
      totalPages: Math.ceil(totalCount / pageSize),
    });

    setLoadedData(items);
    setIsLoading(false);
  };

  const loadNewPage = (pageNumber) => {
    if (pageNumber === queryFilter?.pageNumber) return;

    setQueryFilter({
      ...queryFilter,
      pageNumber,
      pageSize,
    });
  };

  const loadCurrentPage = (currentPage) => {
    if (currentPage === queryFilter.pageNumber) return;

    const temp = [...loadedData];
    const difference = queryFilter.pageNumber - currentPage;
    for (let i = 0; i < difference * queryFilter.pageSize; i++) temp.pop();

    setQueryFilter({
      ...queryFilter,
      currentPage,
      pageSize,
    });
    setLoadedData(temp);
  };

  const reloadAllLoadedPages = () => {
    const newPageSize = queryFilter.pageNumber * queryFilter?.pageSize;
    const pageNumber = 1;
    const modifiedQueryFilter = {
      pageNumber,
      pageSize: newPageSize,
    };
    setIsLoading(true);
    getReq(baseUrl, modifiedQueryFilter).then((res) => {
      setLoadedData(res[0]?.items || []);
      setIsLoading(false);
    });
  };

  const loadNewFilter = (newFilter) => {
    setLoadedData([]);
    setQueryFilter({
      ...newFilter,
      pageNumber: 1,
      pageSize,
    });
  };

  return [loadedData, loadNewPage, loadNewFilter, pagingInfo, isLoading, reloadAllLoadedPages, loadCurrentPage];
};
