import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { getLanguageByCode } from '@veraio/strank';
import { Form, Input } from 'antd';
import { TabPane, Tabs } from 'components/ui';
import { FormEditButtons } from 'components/shared';
import { formStyles, formDateStyle } from './styles';

const MainInformationEdit = ({ deal, onCancel, onEdit }) => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();
  const [activeLanguage, setActiveLanguage] = useState(deal?.dealDetails.at(0)?.languageCode);
  const formValuesRef = useRef(deal?.dealDetails);

  useEffect(() => {
    form.resetFields();
  }, [activeLanguage]);

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  const handleSaveForm = async () => {
    await onEdit({ dealDetails: formValuesRef.current });
    onCancel();
  };

  const handleValueChange = (data) => {
    formValuesRef.current = formValuesRef.current.map((values) =>
      values?.languageCode === activeLanguage ? { ...values, ...data } : values,
    );
  };

  const newExternalValues = formValuesRef.current?.find((el) => el.languageCode === activeLanguage);

  return (
    <Form
      layout="vertical"
      form={form}
      css={formStyles}
      initialValues={newExternalValues}
      onValuesChange={(_, formValues) => {
        handleValueChange(formValues);
      }}
      onFinish={handleSaveForm}>
      <Tabs destroyInactiveTabPane onChange={setActiveLanguage}>
        {deal.dealDetails.map((detail) => (
          <TabPane
            key={detail.languageCode}
            tab={getLanguageByCode(detail?.languageCode)?.name}
            activeKey={activeLanguage}>
            <Form.Item label="Title" name="title" style={{ width: '40%' }} rules={[mandatoryRule]} css={formDateStyle}>
              <Input type="text" placeholder="Edit title" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              style={{ width: '40%' }}
              rules={[mandatoryRule]}
              css={formDateStyle}>
              <Input.TextArea type="text" placeholder="Edit description" autoSize={{ minRows: 5 }} />
            </Form.Item>
            <Form.Item
              label="Coupon terms of use"
              name="couponTermsOfUse"
              style={{ width: '40%' }}
              rules={[mandatoryRule]}
              css={formDateStyle}>
              <Input.TextArea type="text" placeholder="Edit terms and conditions" autoSize={{ minRows: 5 }} />
            </Form.Item>
            <FormEditButtons onCancel={onCancel} />
          </TabPane>
        ))}
      </Tabs>
    </Form>
  );
};

MainInformationEdit.propTypes = {
  deal: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default MainInformationEdit;
