import { theme } from 'styles';

export const orderDetailsUserContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
  paddingTop: '2em',

  '& .order-details-user-trade-icon': {
    alignSelf: 'center',
    margin: '0 1.5em',
  },

  '& .order-details-user-card': {
    flex: '1 0 17rem',
    maxWidth: '25rem',
    padding: '2em',
    background: theme.gray700,
    borderRadius: 10,
    marginBottom: '1em',

    '& .order-details-user-title': {
      textTransform: 'uppercase',
      marginBottom: '1em',
    },

    '& .order-details-user-name': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .order-details-user-info-separator': {
      margin: '1em 0',
    },

    '& .order-details-user-info-row': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '&:not(:last-of-type)': {
        marginBottom: '1em',
      },
      '& > span': {
        marginLeft: '.7em',
      },
      '& .order-details-user-info-tooltip': {
        position: 'absolute',
        background: theme.purple,
        color: theme.white,
        textAlign: 'center',
        padding: '10px 20px 10px 10px',
        borderTopRightRadius: 7,
        borderBottomRightRadius: 7,
        left: '2.7rem',
        bottom: '-0.5rem',
        transition: 'opacity 1s ease-in, visibility 0ms ease-in',
        zIndex: 1,
        visibility: 'hidden',
        '&:after': {
          content: "''",
          position: 'absolute',
          left: '-30px',
          bottom: 0,
          width: 0,
          height: 0,
          borderTop: '22px solid transparent',
          borderRight: `15px solid ${theme.purple}`,
          borderBottom: '20px solid transparent',
          borderLeft: '15px solid transparent',
        },
      },
      '& .order-details-user-info-icon-container': {
        '&:hover .order-details-user-info-tooltip': {
          visibility: 'visible',
          transitionDelay: '1s',
          opacity: '1',
        },
      },
    },

    '& .order-details-user-avatar': {
      textTransform: 'uppercase',
      background: theme.purple,
      marginRight: '0.75em',
      color: theme.white,
      borderRadius: '50%',
      width: '3rem',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1rem',
    },
  },
};
