import { theme } from 'styles';

const materialIcon = ({ iconName, outlined, twoTone, round, sharp }) => [
  {
    fontFamily: "'Material Icons'",
    fontStyle: 'normal',
    '&:before': {
      content: `"${iconName}"`,
    },
  },
  outlined && {
    fontFamily: "'Material Icons Outlined'",
  },
  twoTone && {
    fontFamily: "'Material Icons Two Tone'",
  },
  round && {
    fontFamily: "'Material Icons Round'",
  },
  sharp && {
    fontFamily: "'Material Icons Sharp'",
  },
];

export const baseIcon = (props) => {
  const { color, onClick, size, filter, material } = props;

  return [
    {
      lineHeight: '1',
      textRendering: 'optimizeLegibility',
      textTransform: 'none',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSmoothing: 'antialiased',
      fontSize: size || '1.5rem',
      color: theme[color] || color,
      userSelect: 'none',
      transition: 'color 0.5s ease-in-out',
      fontStyle: 'normal',
    },
    onClick && {
      cursor: 'pointer',
    },
    filter && {
      filter,
    },
    material && materialIcon(props),
  ];
};
