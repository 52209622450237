import { theme } from 'styles';

export const labelTitle = {
  color: theme.background200,
};

export const formStyles = {
  width: '100%',
  '& > div': {
    marginBottom: '1em',
  },
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
};

export const historyChanges = {
  display: 'flex',
  flexWrap: 'wrap',
};
