export const checkboxesWrapper = {
  marginTop: '1.5em',
  '& .ant-form-item': {
    marginBottom: 0,
  },
};

export const othersWrapper = {
  display: 'flex',
};

export const othersFeaturesWrapper = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '0',
};
