import { useState, useEffect } from 'react';
import { pick } from '@veraio/core';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import {
  MobileVersionConfiguration,
  WalletsFeeConfiguration,
  ExternalPaymentProviders,
  FormBox,
  Settlements,
} from 'components/screens';
import useError from 'services/errorHandling/useError';
import { MOBILE_VERSION, CONFIG_GROUP, WALLETS_FEE, EXTERNAL_PAYMENT_PROVIDERS, SETTLEMENTS_ROLE, GLOBAL_CLEARING_RISK_LEVELS } from 'enums';
import { getAllConfigs, updateConfig } from 'services';
import { configurationsContainer } from './styles';
import { GlobalClearingRiskLEvels } from 'components/screens/Settings/Forms/GlobalClearingRiskLEvels';

export const GeneralConfigurations = () => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    const request = getAllConfigs;
    const params = [];
    const [res, err] = await request(...params);
    if (err) return setError(err);

    setConfigurations({
      [CONFIG_GROUP.MOBILE_VERSION]: pick(res, Object.values(MOBILE_VERSION)),
      [CONFIG_GROUP.WALLETS_FEE]: pick(res, Object.values(WALLETS_FEE)),
      [CONFIG_GROUP.EXTERNAL_PAYMENT_PROVIDERS]: pick(res, Object.values(EXTERNAL_PAYMENT_PROVIDERS)),
      [CONFIG_GROUP.CLEARING_AND_SETTLEMENT]: pick(res, Object.values(SETTLEMENTS_ROLE)),
      [CONFIG_GROUP.GLOBAL_CLEARING_RISK_LEVELS]: pick(res, Object.values(GLOBAL_CLEARING_RISK_LEVELS)),
    });
  };

  const updateConfigs = async (groupName, data) => {
    const request = updateConfig;
    const params = [groupName, data].concat([]);
    const [, err] = await request(...params);
    if (err) return setError(err);

    toast.success(getText('successfullyEditedConfig'));
    fetchConfigurations();
  };

  const configProps = (groupName) => ({
    groupName,
    data: configurations?.[groupName],
    onUpdate: (data) => updateConfigs(groupName, data),
  });

  return (
    <div css={configurationsContainer}>
      <FormBox title={getText('mobileVersion')}>
        <MobileVersionConfiguration {...configProps(CONFIG_GROUP.MOBILE_VERSION)} />
      </FormBox>
      <FormBox title={getText('Wallets')}>
        <WalletsFeeConfiguration {...configProps(CONFIG_GROUP.WALLETS_FEE)} />
      </FormBox>
      <FormBox title={getText('externalPaymentsProviders')}>
        <ExternalPaymentProviders {...configProps(CONFIG_GROUP.EXTERNAL_PAYMENT_PROVIDERS)} />
      </FormBox>
      <FormBox title={getText('Settlements')}>
        <Settlements {...configProps(CONFIG_GROUP.CLEARING_AND_SETTLEMENT)} />
      </FormBox>
      <FormBox title={getText('globalClearing')}>
        <GlobalClearingRiskLEvels
          groupName={CONFIG_GROUP.GLOBAL_CLEARING_RISK_LEVELS}
          data={configurations?.[CONFIG_GROUP.GLOBAL_CLEARING_RISK_LEVELS]}
          onUpdate={(data) => updateConfigs(CONFIG_GROUP.CLEARING_AND_SETTLEMENT, data)}
        />
      </FormBox>
    </div>
  );
};

GeneralConfigurations.propTypes = {
  fee: PropTypes.number,
  feeName: PropTypes.string,
  currency: PropTypes.string,
  onChangeFee: PropTypes.func,
  onSaveFee: PropTypes.func,
};
