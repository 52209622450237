import { getText } from '@veraio/strank';
import moment from 'moment';
import parseHtml from 'html-react-parser';
import { Tag } from 'components/UIExternal';
import { isValidDate } from 'utils/valueFormatter';
import { businessStatusColor, businessStatusInverse } from 'enums';

export const BUSINESS_STATUS_HISTORY_COLUMNS = () => [
  {
    name: getText('changedAt'),
    render: (business, NullItem) =>
      isValidDate(business?.createdDate) ? (
        <span>{moment(business.createdDate).format('DD/MM/YYYY hh:mm:ss')}</span>
      ) : (
        <NullItem />
      ),
    width: 110,
  },
  {
    name: getText('changedBy'),
    value: 'createdBy',
    width: 180,
  },
  {
    name: getText('reason'),
    dataIndex: 'reason',
    render: (business, NullItem) => (business?.reason ? parseHtml(business.reason) : <NullItem />),
  },
  {
    name: getText('status'),
    render: (business) => (
      <Tag type={businessStatusColor[business?.statusId]}>{getText(businessStatusInverse[business?.statusId])}</Tag>
    ),
    width: 100,
  },
];
