import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isObjectLike, isFunction } from 'lodash-es';
import { Icon } from 'components/ui';
import { useAutocomplete } from './useAutocomplete';
import {
  searchBarContainer,
  searchBarInputContainer,
  searchBarInput,
  searchBarInputIcon,
  searchBarSuggestionsContainer,
  searchBarSuggestionItem,
} from './styles';

const SearchBar = forwardRef((props, searchBarRef) => {
  const { displayKey = 'label', className, renderInput, renderSuggestionsContainer, renderSuggestion } = props;

  const { inputProps, suggestionItemProps, isOpen, suggestions, rootNodeRef } = useAutocomplete(props, searchBarRef);

  const listOfSuggestions = suggestions?.map((el, i) => {
    const itemProps = {
      css: searchBarSuggestionItem,
      key: `suggestion-${i}`,
      role: 'button',
      tabIndex: 0,
      onBlur: inputProps.onBlur,
      onClick: () => suggestionItemProps.onClick(el),
      onKeyDown: suggestionItemProps.onKeyDown,
      [suggestionItemProps.itemIdAttr]: i,
    };

    return isFunction(renderSuggestion) ? (
      renderSuggestion({ itemProps, suggestionItemProps, item: el })
    ) : (
      <div {...itemProps}>{isObjectLike(el) ? el[displayKey] : el}</div>
    );
  });

  return (
    <div ref={rootNodeRef} css={searchBarContainer} {...(className && { className })}>
      {isFunction(renderInput) ? (
        renderInput({
          containerProps: { css: searchBarInputContainer },
          inputProps: { css: searchBarInput, ...inputProps },
          iconProps: {
            iconName: 'las la-search',
            css: searchBarInputIcon,
          },
        })
      ) : (
        <div css={searchBarInputContainer}>
          <input css={searchBarInput} {...inputProps} />
          <label htmlFor={inputProps.id}>
            <Icon iconName="las la-search" css={searchBarInputIcon} />
          </label>
        </div>
      )}

      {isFunction(renderSuggestionsContainer)
        ? renderSuggestionsContainer({
            isOpen,
            suggestions,
            css: searchBarSuggestionsContainer,
            children: listOfSuggestions,
          })
        : isOpen && !!suggestions?.length && <div css={searchBarSuggestionsContainer}>{listOfSuggestions}</div>}
    </div>
  );
});

SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  displayKey: PropTypes.string,
  renderInput: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
};

export default SearchBar;
