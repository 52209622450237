import PropTypes from 'prop-types';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import { formatDefaultDateForDatePicker } from 'utils/valueFormatter';
import { datePicker } from './styles';

const DatePicker = ({ placeholder, dateFormat, onChange, defaultValue, disabledDate, hideToday }) => {
  const format = dateFormat || 'DD/MM/YYYY';

  const onChangeHandler = (date) => {
    if (onChange !== undefined) onChange(date?.format('YYYY-MM-DD'));
  };

  return (
    <AntDatePicker
      defaultValue={defaultValue && moment(formatDefaultDateForDatePicker(defaultValue), 'DD-MM-YYYY')}
      disabledDate={disabledDate}
      format={format}
      showToday={!hideToday}
      onChange={(e) => onChangeHandler(e)}
      css={datePicker}
      placeholder={placeholder || format}
    />
  );
};

DatePicker.propTypes = {
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  disabledDate: PropTypes.func,
  hideToday: PropTypes.bool,
};

export default DatePicker;
