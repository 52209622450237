import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { notificationBreadcrumbs } from './styles';

// sendToOptions should be function because it has getText inside which throw error if it is used before configuration
export const sendToOptions = () => [
  { label: getText('allUsers'), value: false },
  { label: getText('merchants'), value: true },
];

// createNotificationPages should be function because it has getText inside which throw error if it is used before configuration
export const createNotificationPages = () => [
  {
    url: '/notifications',
    label: (
      <div css={notificationBreadcrumbs}>
        <Icon iconName="lar la-bell" />
      </div>
    ),
  },
  { url: '/notifications', label: getText('notifications') },
  {
    label: getText('createNotification'),
  },
];
