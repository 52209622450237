import { useState, useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { pick } from 'lodash-es';
import { Col, Form, Radio, InputNumber, Select, Popover, Input } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { FormEditButtons } from 'components/shared';
import { DealCategories } from '../DealDetails';
import { deliveryMethod, dealWeightTypeSelect } from 'enums';
import {
  formStyles,
  formDateStyle,
  requiredStyles,
  wrapperStyles,
  box,
  radioGroup,
  row,
  rowWeight,
  colWeight,
  popoverContainer,
  tagsHintText,
  tagStyles,
  tagListStyles,
  categoriesEdit,
  tagsStyles,
} from './styles';

const DealDetailsEdit = ({ deal, onCancel, onEdit }) => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();
  const tagsInputRef = useRef();
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(deal?.deliveryMethodId);

  const handleDeliveryMethodChange = (e) => {
    setSelectedDeliveryMethod(e.target.value);
  };

  const newInitialValues = {
    ...pick(
      deal,
      'deliveryMethodId',
      'merchantName',
      'categoryId',
      'length',
      'width',
      'height',
      'weight',
      'weightTypeId',
      'bvPoints',
      'tags',
    ),
  };

  const handleSaveForm = async (values) => {
    await onEdit(values, [
      'deliveryMethodId',
      'merchantName',
      'category',
      'length',
      'width',
      'height',
      'weight',
      'weightTypeId',
      'bvPoints',
      'tags',
    ]);
    onCancel();
  };

  const handleTagChange = async (e) => {
    const {
      target: { value },
    } = e;
    const trimmedTag = value?.trim();
    if (trimmedTag.length < 2) return;
    const prevTags = form.getFieldValue('tags') ?? [];
    form.setFieldsValue({ tags: prevTags.concat(trimmedTag) });
    form.resetFields(['tagsInput']);
  };

  const handleRemoveTag = (idx) => {
    const prevTags = [...form.getFieldValue('tags')];
    prevTags.splice(idx, 1);
    form.setFieldsValue({ tags: prevTags });
  };

  return (
    <Form layout="inline" form={form} css={formStyles} onFinish={handleSaveForm} initialValues={newInitialValues}>
      <div />
      <Form.Item
        labelAlign="left"
        label={getText('category')}
        name="categoryId"
        rules={[mandatoryRule]}
        css={categoriesEdit}>
        <DealCategories />
      </Form.Item>
      <div css={wrapperStyles}>
        <div>
          <span css={requiredStyles}>*</span>
          <span className="subtitle" style={{ marginRight: '0.5em' }}>
            {getText('deliveryMethod')}
          </span>
        </div>
        <div css={box}>
          <Form.Item
            labelCol={{ lg: 24 }}
            name="deliveryMethodId"
            rules={[
              {
                required: true,
                message: getText('pleaseSelectDeliveryMethod'),
              },
            ]}>
            <Radio.Group css={radioGroup} value={selectedDeliveryMethod} onChange={handleDeliveryMethodChange}>
              <Radio.Button value={deliveryMethod.selfOrganizedShipping}>
                {getText('selfOrganizedShipping')}
              </Radio.Button>
              <Radio.Button value={deliveryMethod.redeemAtLocation}>{getText('redeemAtLocation')}</Radio.Button>
              <Radio.Button value={deliveryMethod.onlineService}>{getText('onlineService')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item name="bvPoints" label={getText('bvPoints')} css={formDateStyle}>
        <InputNumber type="number" placeholder={getText('bvPoints')} min={0} disabled={!isAdmin} />
      </Form.Item>
      <Form.Item
        name="tagsInput"
        labelAlign="left"
        labelCol={{ lg: 24 }}
        data-id="tagsInput"
        label={
          <Popover
            content={getText('tagHelpText')}
            overlayClassName={popoverContainer}
            zIndex={900}
            placement="bottomRight"
            trigger="click">
            <div>
              <span>{getText('tags')}</span>
            </div>
          </Popover>
        }
        css={tagsStyles}>
        <div>
          <Input ref={tagsInputRef} onPressEnter={handleTagChange} placeholder={getText('addTag')} maxLength={30} />
          <span css={tagsHintText}>{getText('tagsHintText')}</span>
        </div>
      </Form.Item>
      <Form.Item name="tags" noStyle />
      <Form.Item shouldUpdate>
        {() => (
          <div css={tagListStyles}>
            {form.getFieldValue('tags')?.map((tag, idx) => (
              <div key={idx} css={tagStyles}>
                {tag}
                <CloseOutlined size={12} onClick={() => handleRemoveTag(idx)} />
              </div>
            ))}
          </div>
        )}
      </Form.Item>
      <div style={{ width: '100%' }}>
        <div>
          <Col lg={4} span={24} style={{ maxWidth: '100%', marginBottom: '0.5em' }}>
            <span className="subtitle">{getText('packageSize')}</span>
          </Col>
          <Form.Item name="height" label={getText('height')} css={formDateStyle}>
            <InputNumber type="number" placeholder={getText('editHeight')} min={0} addonAfter="cm" />
          </Form.Item>
          <Form.Item name="width" label={getText('width')} css={formDateStyle}>
            <InputNumber type="number" placeholder={getText('editWidth')} min={0} addonAfter="cm" />
          </Form.Item>
          <Form.Item name="length" label={getText('depth')} css={formDateStyle}>
            <InputNumber type="number" placeholder={getText('editDepth')} min={0} suffix="cm" addonAfter="cm" />
          </Form.Item>
        </div>
        <Col lg={4} span={24} style={{ maxWidth: '100%', marginBottom: '0.5em' }}>
          <span className="subtitle">{getText('packageWeight')}</span>
        </Col>
        <div css={rowWeight}>
          <div css={colWeight}>
            <p>{`${getText('weight')}:`}</p>
          </div>
          <div css={row}>
            <div css={colWeight}>
              <Form.Item name="weight" css={formDateStyle}>
                <InputNumber type="number" placeholder={getText('editWeight')} min={0} />
              </Form.Item>
            </div>
            <div css={colWeight}>
              <Form.Item name="weightTypeId" css={formDateStyle}>
                <Select placeholder={getText('weightType')} options={dealWeightTypeSelect} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div />
        <FormEditButtons onCancel={onCancel} />
      </div>
    </Form>
  );
};

DealDetailsEdit.propTypes = {
  deal: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DealDetailsEdit;
