import { useRef } from 'react';
import { getText } from '@veraio/strank';
import { PageHeader } from 'components/shared';
import { OrderFilters, OrdersList, OrderDetails } from 'components/screens';
import { useUrlParams } from 'components/UIExternal';
import { ordersContainer, ordersContentContainer } from './styles';
import { orderStatusColor, orderStatusInverse, orderStatusSelect } from 'enums';

const filterKeys = {
  merchantCountryId: 'merchantCountryId',
  merchantEmail: 'merchantEmail',
  merchantName: 'merchantName',
  buyerCountryId: 'buyerCountryId',
  buyerEmail: 'buyerEmail',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  orderId: 'orderId',
  statusIds: 'statusIds'
};

export const Orders = () => {
  const paginationRef = useRef();
  const { queryParams } = useUrlParams();
  const queryStatus = queryParams[filterKeys.statusIds] && queryParams[filterKeys.statusIds]?.map((el) => Number(el));

  return (
    <section css={ordersContainer}>
      <PageHeader title={getText('orders')}
        statusProps={{
          defaultValue: queryStatus,
          onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.statusIds]: value }),
          options: orderStatusSelect(),
          tagProps: { type: orderStatusColor, value: orderStatusInverse },
        }}/>
      <OrderFilters onChange={(data) => paginationRef.current?.applyFilters(data)} filterKeys={filterKeys} />
      <section css={ordersContentContainer}>
        <OrdersList filterKeys={filterKeys} paginationRef={paginationRef} />
        <OrderDetails />
      </section>
    </section>
  );
};
