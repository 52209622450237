import { isNumber, isArray, isFunction } from 'lodash-es';

export const mockTableRes =
  (data = [], filterCallback, key = 'items', timeout = 0) =>
  (options) => {
    const getPromise = (resolveData) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve([resolveData]);
        }, timeout);
      });

    if (!isArray(data)) return getPromise({ [key]: [], totalCount: 0 });

    const { pageNumber, pageSize } = options;
    let totalCount = data.length;

    let filteredData = [...data];

    if (isFunction(filterCallback)) {
      filteredData = filterCallback(data, options);
      totalCount = filteredData.length;
    }

    if (isNumber(pageNumber) && isNumber(pageSize))
      filteredData = filteredData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

    return getPromise({
      [key]: filteredData,
      totalCount,
    });
  };
