import { getReq, putReq, postReq, deleteReq } from 'services/axios/makeRequest';
import { updateConfigBody, getAllConfigsResponse } from 'services/models';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Configurations`;

export const getAllConfigs = async () => {
  const [res, err] = await getReq(`${baseUrl}/GetAll`);
  return [res && getAllConfigsResponse(res), err];
};

export const getConfigsByCountry = async (countryId) => {
  const [res, err] = await getReq(`${baseUrl}/GetAll/${countryId}`);
  return [res && getAllConfigsResponse(res), err];
};

export const updateConfig = (group, data) => putReq(`${baseUrl}/Update/${group}`, updateConfigBody(data));

export const updateConfigByCountry = (group, data, countryId) =>
  putReq(`${baseUrl}/Update/${group}/${countryId}`, updateConfigBody(data));

export const getFees = () => getReq(`${baseUrl}/GetBuyerFees`);

export const saveEuroFee = (fee) => postReq(`${baseUrl}/SetBuyerEuroFee`, fee);

export const saveOneCoinFee = (fee) => postReq(`${baseUrl}/SetBuyerOneCoinFee`, fee);

export const deleteConfigByCountry = (group, countryId) => deleteReq(`${baseUrl}/Delete/${group}/${countryId}`);
