import { useTranslations } from '@veraio/strank';
import { isFunction } from 'lodash-es';
import { Button } from 'components/UIExternal';
import { formEditBtns } from './styles';

export const FormEditButtons = ({ onCancel }) => {
  const { getText } = useTranslations();

  const handleCancel = (e) => {
    e.preventDefault();
    isFunction(onCancel) && onCancel();
  };

  return (
    <div css={formEditBtns}>
      <Button small type="success">
        {getText('save')}
      </Button>
      <Button onClick={handleCancel} small type="danger">
        {getText('cancel')}
      </Button>
    </div>
  );
};
