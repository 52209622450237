import { theme } from 'styles';

export const sortDropdown = {
  margin: '2.1875em 0',
  '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    borderRadius: 28,
    border: `1px solid ${theme.gray500}`,
    width: '15.625rem',
    backgroundColor: 'transparent',
  },
  '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
    border: `1px solid ${theme.gray500}`,
  },
  '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    borderColor: theme.gray500,
    boxShadow: 'none',
  },
};

export const pageWrapper = {
  padding: '3.125em',
};

export const notificationsTable = {
  marginBottom: '1.5em !important',
  '& .unread': { background: theme.ui_hover_rgba },
  '& .la-bell': {
    boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
    borderRadius: '50%',
    fontSize: '1.5rem',
    display: 'flex',
    marginRight: '0.75em',
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: theme.white,
    color: theme.primary_orange,
    borderColor: theme.gray600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .la-ticket-alt': {
    boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
    borderRadius: '50%',
    fontSize: '1.4375rem',
    display: 'flex',
    width: '2.5rem',
    height: '2.5rem',
    marginRight: '0.75em',
    backgroundColor: theme.white,
    color: theme.primary_blue,
    borderColor: theme.gray600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .la-shopping-bag': {
    boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    fontSize: '1.3125rem',
    display: 'flex',
    marginRight: '0.75em',
    backgroundColor: theme.white,
    color: theme.purple,
    borderColor: theme.gray600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .la-star': {
    boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
    borderRadius: '50%',
    fontSize: '1.5rem',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    marginRight: '0.75em',
    backgroundColor: theme.white,
    color: theme.primary_yellow,
    borderColor: theme.gray600,
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.Desktop]: { '& .ant-avatar-group': { alignItems: 'center' } },
  [theme.Mobile]: {
    '& .ant-list-item': {
      border: 'none !important',
      borderRadius: 4,
    },
  },
};

export const icon = {
  [theme.Desktop]: {
    width: '7%',
  },
  [theme.Mobile]: {
    width: '15%',
  },
};

export const filterWrapper = {
  [theme.Mobile]: { overflow: 'auto' },
};

export const typeTime = {
  border: 'none',
  background: 'none',
  marginLeft: '1em',
  [theme.Desktop]: {
    '& .type': {
      color: theme.gray200,
      fontSize: '1.125rem',
    },
    '& .time': {
      color: theme.gray300,
      fontSize: '0.8125rem',
      '& > *': {
        marginRight: 8,
      },
    },
  },
  [theme.Mobile]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .time': {
      color: theme.gray300,
      fontSize: '0.75rem',
    },
  },
};

export const message = {
  [theme.Desktop]: {
    width: '63%',
  },
};

export const dealTitle = {
  color: theme.blue2,
};

export const mobileWrapperTypeMessage = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.3125em',
};

export const bold = {
  fontWeight: 700,
  color: `${theme.blue2} `,
};

export const noChatsContainer = {
  display: 'flex',
  [theme.Mobile]: { flexDirection: 'column' },
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.125rem',
  color: theme.gray300,
  marginBottom: '3.125em',
  '& div': { marginLeft: '2.1875em', alignItems: 'flex-start' },
  '& label': { fontSize: '0.875rem', marginBottom: '0.75em' },
  '& h4': { fontWeight: 700 },
};

export const noNotificationImage = {
  marginBottom: '1.875em',
  marginTop: '2.5em',
  [theme.Mobile]: {
    width: '40%',
  },
  [theme.Desktop]: {
    width: '15%',
  },
};

export const tabs = {
  '& .ant-tabs-content-holder': { marginTop: '0.9375em' },
};

export const archiveBtnStyle = {
  fontSize: '0.8125rem',
  color: theme.gray100,

  '&:hover': {
    borderColor: 'transparent',
    opacity: 0.6,
  },
};

export const moreBtnStyle = {
  fontSize: '1.0625rem',
};

export const messageBtnStyle = {
  fontSize: '0.9375rem',
  color: theme.gray300,
};

export const tabContentStyle = {
  '& > span': {
    marginRight: '0.75em',
  },
};

export const primaryBtnStyle = {
  fontSize: '0.875rem',
};
