import { getText } from '@veraio/strank';
import { Collapse } from 'antd';
import { TextInput } from 'components/ui';
import { Dropdown, useUrlParams } from 'components/UIExternal';
import { useEffect, useRef, useState } from 'react';
import { pageFilterCollapse } from 'styles';
import { filtersContainer } from './styles';
import { CURRENCY_SELECT, TRANSACTION_STATUS_SELECT } from 'enums';

const ClearingSettlementSharedFilterBar = ({ paginationRef, filterKeys, sortingKeys, sortOptions }) => {
  const { queryParams, clearQueryParams } = useUrlParams();
  const prevSearchTypeValue = useRef(null);

  const SEARCH_TYPE_OPTIONS = [
    { label: getText('merchantDetails'), value: 'merchantDetails' },
    { label: getText('orderId'), value: 'orderId' },
    { label: getText('transactionId'), value: 'transactionId' },
    { label: getText('buyerDetails'), value: 'buyerDetails' },
  ];

  const getSearchTypeOptions = () => SEARCH_TYPE_OPTIONS.filter((o) => Object.keys(filterKeys).indexOf(o.value) > -1);

  const getInitialSearchType = (qParams = {}) => {
    const availableOptions = getSearchTypeOptions(filterKeys);
    const foundParam = Object.keys(qParams).filter((p) => availableOptions.map((o) => o.value).indexOf(p) > -1);

    return foundParam && foundParam.length > 0 ? foundParam[0] : (availableOptions[0] || {}).value;
  };

  const [searchType, setSearchType] = useState(getInitialSearchType(queryParams, filterKeys));

  const handleSearchTypeChange = (selection) => {
    prevSearchTypeValue.current = { searchType, value: queryParams[searchType] };
    setSearchType(selection.value);
  };

  useEffect(() => {
    if (searchType && prevSearchTypeValue.current?.searchType) {
      clearQueryParams([prevSearchTypeValue.current?.searchType]);
      handleChangeSearch({ [searchType]: prevSearchTypeValue.current?.value });
    }
  }, [searchType]);

  const handleChange = (keyValuePair) => paginationRef.current?.applyFilters(keyValuePair);

  const handleChangeSearch = (keyValuePair) => {
    handleChange(keyValuePair);
  };

  const selectedSearchType = SEARCH_TYPE_OPTIONS.find((t) => t.value === searchType);
  const searchPlaceholder = `Search by ${selectedSearchType?.label}`;

  return (
    <div css={filtersContainer}>
      <Collapse css={pageFilterCollapse}>
        <Collapse.Panel header={getText('filters')}>
          <section className="filters-container">
            <div className="flex variable-search">
              <div className="small-filter" data-id="clearing-settlement-search-options">
                <Dropdown
                  className="nowrap"
                  small
                  noClear
                  options={getSearchTypeOptions(filterKeys)}
                  value={searchType}
                  onChange={handleSearchTypeChange}
                />
              </div>
              <div className="filters-search-field" data-id="clearing-settlement-filter-search">
                <TextInput
                  showDelete
                  onSearch={(value) => handleChangeSearch({ [filterKeys[searchType]]: value })}
                  placeholder={searchPlaceholder}
                  defaultValue={queryParams[filterKeys[searchType]]}
                />
              </div>
            </div>
            <div className="small-filter" data-id="clearing-settlement-filter-status">
              <Dropdown
                small
                options={TRANSACTION_STATUS_SELECT(getText)}
                value={queryParams[filterKeys.statusId]}
                placeholder={getText('status')}
                onChange={(val) => handleChange({ [filterKeys.statusId]: val?.value })}
              />
            </div>
            <div className="small-filter" data-id="clearing-settlement-filter-currency">
              <Dropdown
                small
                options={CURRENCY_SELECT}
                value={queryParams[filterKeys.currency]}
                placeholder={getText('currency')}
                onChange={(val) => handleChange({ [filterKeys.currency]: val?.value })}
              />
            </div>
            {sortingKeys && (
              <div className="align-right" data-id="merchant-office-transactions-sorting">
                <div className="sort-field">
                  <label className="nowrap">{getText('sortBy')}</label>
                  <Dropdown
                    noClear
                    options={sortOptions}
                    value={queryParams[sortingKeys.sorting]}
                    onChange={(val) => handleChange({ [sortingKeys.sorting]: val?.value })}
                  />
                </div>
              </div>
            )}
          </section>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ClearingSettlementSharedFilterBar;
