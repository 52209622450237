import { Table } from 'components/UIExternal';
import { getAllSettlement } from 'services/api/transactions';
import { filterKeys, getSettlementColumns, sortingKeys } from './config';
import { pageContainer, tableContainer } from '../../styles';
import { SettlementFilterBar, TransactionsTotalsPanel } from 'components/screens';
import { getText } from '@veraio/strank';
import { SETTLEMENT_SORT_OPTIONS } from 'enums';

const SettlementTab = ({ pendingClearingSettlementAmounts = {} }) => {
  const {
    pendingSettlementCoinWalletBalance,
    pendingSettlementCashWalletBalance,
    pendingSettlementOespWalletBalance,
  } = pendingClearingSettlementAmounts;

  const totals = [
    {
      one: pendingSettlementCoinWalletBalance,
      fiat: pendingSettlementCashWalletBalance,
      oesp: pendingSettlementOespWalletBalance,
      title: getText('awaitingSettlement'),
    },
  ];

  const initQueryParams = {
    [sortingKeys.sorting]: SETTLEMENT_SORT_OPTIONS.creationDateDesc,
  };

  return <section css={pageContainer}>
    <div data-id="merchant-office-clearing-tab-totals">
      <TransactionsTotalsPanel totalsData={totals} />
    </div>

    <div css={tableContainer} data-id="merchant-office-settlement-table">
      <Table
        getDataMethod={getAllSettlement}
        columns={getSettlementColumns()}
        filterBar={SettlementFilterBar}
        filterKeys={filterKeys}
        sortingKeys={sortingKeys}
        defaultQueryParams={initQueryParams}
      />
    </div>
  </section>;
};

export default SettlementTab;
