import { theme } from 'styles';

export const spinner = {
  margin: '1.875em 1.875em !important',
};

export const navigationWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.5em',

  '.specs-container': {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      paddingLeft: 0,
    },
    '& .address-name': {
      fontSize: '1.375rem',
      fontWeight: 500,
      color: theme.background100,
      marginRight: '0.625em',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
  '.actions-container': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
  },

  '& .la-arrow-left': {
    fontSize: '1.375rem',
    color: theme.background100,
    marginTop: '-0.25em',
  },
};

export const subTitle = {
  display: 'inline-block',
  color: theme.background100,
  fontSize: '1rem',
  fontWeight: 700,
  marginBottom: '0.5em',
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};
