import { theme } from 'styles';

export const pageHeader = {
  margin: '1.5em 0',
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1em',
};

export const mainContainer = {
  fontWeight: 400,
  fontSize: '0.875rem',
  color: theme.gray100,
  '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    borderRadius: 10,
    border: `1px solid ${theme.gray500}`,
    width: '15rem',
    backgroundColor: 'transparent',
  },
  '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
    border: `1px solid ${theme.gray500}`,
  },
  '& .ant-select-selector': {
    paddingTop: '0.1875em !important',
  },
  '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    borderColor: theme.gray500,
    boxShadow: 'none',
  },
};

export const tag = {
  marginRight: '0.25em !important',
  display: 'flex !important',
  flexDirection: 'row !important',
  padding: '0 !important',
  border: 'none !important',
  '& .ant-tag-close-icon': { display: 'flex', alignItems: 'center', marginLeft: '0.25em !important' },
};
