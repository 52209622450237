export const loginPageContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  margin: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  '& .login-form': {
    width: '25rem',
  },
  '& .logo': {
    maxWidth: '20rem',
    width: '100%',
  },
  '& .title': {
    margin: '2em 0 0.5em',
    textAlign: 'center',
  },
  '& .email-input .ant-form-item-label': {
    marginRight: '1.6875em',
  },
};
