import { isNumber, isNil } from 'lodash-es';

export const createCountryAreaParams = (options) => {
  const { countryId, areaName } = options;

  return {
    ...(!isNil(countryId) && { countryId }),
    ...(!isNil(areaName) && { areaName }),
  };
};

export const updateCountryAreaParams = (options) => {
  const { areaId, countryId, areaName } = options;

  return {
    ...(isNumber(areaId) && { areaId }),
    ...(!isNil(countryId) && { countryId }),
    ...(!isNil(areaName) && { areaName }),
  };
};
