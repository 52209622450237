import { theme } from 'styles';

export const categoriesFilterContent = {
  width: '100%',
  '& .ant-menu-submenu-title': { paddingLeft: '0.3125em !important', paddingRight: '5px !important' },
  [theme.Mobile]: {
    maxHeight: '25rem',
    overflow: 'auto',
    overflowX: 'hidden',
    '& .ant-menu-item': { paddingLeft: '0.3125em !important', paddingRight: '0.3125em !important' },
  },
};

export const menuItem = {
  padding: '0.3125em 0 !important',
};

export const checkbox = {
  width: '100%',
  '& .ant-checkbox': { width: '1.0625rem' },
  '& span:last-of-type': { width: '100%', paddingLeft: 0, marginLeft: '0.5em' },
};

export const actionButtonStyle = {
  fontSize: '0.875rem',
  padding: 0,
  paddingLeft: '0.375em',
  color: theme.black100,
  cursor: 'pointer',

  '&:hover': {
    borderColor: 'transparent',
  },
};

export const arrowStyle = {
  fontSize: '1.5625rem',
  padding: 0,
  cursor: 'pointer',
  color: theme.black100,

  '&:hover': {
    borderColor: 'transparent',
    color: theme.blue,
  },
  '& .isExpanded': { transform: 'rotate(90deg)' },
  '& .la-angle-right': { fontSize: '1.5625rem' },
};

export const checkboxContainer = {
  display: 'flex',
  justifyContent: 'space-between',

  '&:hover': {
    opacity: 0.6,
  },
  [theme.Mobile]: {
    '& span': { textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '11.875rem', whiteSpace: 'nowrap' },
  },
};
