import { theme } from 'styles';

export const userProfileImage = {
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: 20,
};

export const miniUserProfileMenu = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: theme.white,
  fontSize: '1rem',
  '& label': { fontSize: '0.875rem' },
  [theme.Desktop]: { minWidth: '18.75rem' },
};

export const profileInfoText = {
  paddingLeft: '0.625em',
  marginBottom: 0,
  fontWeight: 500,
  '& label': {
    fontSize: '0.75rem',
    fontWeight: 450,
    color: theme.gray200,
  },
};

export const popoverWrapper = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'fixed !important',
  paddingTop: '0px !important',
  backgroundColor: theme.white,
  borderRadius: 4,
  '& label': { fontSize: '1.125rem' },
  [theme.Mobile]: {
    width: '100%',
    left: '0 !important',
    '& .ant-popover-content': {
      width: '100%',
    },
  },
};

export const logoutButton = {
  width: '100%',
  fontWeight: 400,
  fontSize: '0.875rem',
  marginTop: '0.375em',

  '&:hover': {
    borderColor: theme.primary_blue_hover,
    color: theme.primary_blue_hover,
    backgroundColor: 'transparent',
  },
};

export const accountAndSwitchButton = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export const usernames = {
  paddingLeft: '0.625em',
  fontSize: '1.25rem',
  marginBottom: '0.3125em !important',
  fontWeight: 600,
};

export const popoverButtonContainer = {
  padding: '0.125em 0.9375em',
  display: 'flex',
};
