import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import LocalizationPopup from 'components/popups/LocalizationPopup';
import { localizationContainer, localizationContent } from './styles';

const Localization = ({ isMobileView }) => {
  const currency = useCurrencies((currenciesState) => currenciesState.selectedCurrency);
  const { language } = useTranslations();
  const [isLocalizationPopupOpen, setLocalizationPopupOpen] = useState(false);

  const closeLocalizationPopup = () => setLocalizationPopupOpen(false);
  const openLocalizationPopup = () => setLocalizationPopupOpen(true);

  return (
    <div className="localization-panel" css={localizationContainer(isMobileView)}>
      <button onClick={openLocalizationPopup} css={localizationContent}>
        <span>{`${language?.code?.toUpperCase()} / ${currency?.symbol} ${currency?.code}`}</span>
      </button>
      <LocalizationPopup open={isLocalizationPopupOpen} closePopup={closeLocalizationPopup} />
    </div>
  );
};

Localization.propTypes = {
  isMobileView: PropTypes.bool,
};

export default Localization;
