import { paymentStatus, deliveryStatus, paymentMethods } from 'enums';

export const orderDeliveryDetails = (delivery) => ({
  isDeliveryPacked: delivery?.statusId === deliveryStatus.packed,
  isDeliveryOnGoing: delivery?.statusId === deliveryStatus.ongoing,
  isDeliveryCompleted: delivery?.statusId === deliveryStatus.delivered,
});

export const orderPaymentDetails = (payment) => {
  const isFiatManualPayment =
    payment?.cashPaymentMethodId === paymentMethods.cashOnDelivery ||
    payment?.cashPaymentMethodId === paymentMethods.bankTransfer;
  const isFiatPaymentCompleted = payment?.cashPaymentStatus === paymentStatus.completed;
  const isCryptoPaymentCompleted = payment?.cryptoPaymentStatus === paymentStatus.completed;

  return {
    isFiatManualPayment,
    isFiatPaymentCompleted,
    isCryptoPaymentCompleted,
    isPaymentCompleted: isFiatPaymentCompleted && isCryptoPaymentCompleted,
    isFiatPaymentBank: payment?.cashPaymentMethodId === paymentMethods.bankTransfer,
    isAutomaticPaymentsCompleted: (isFiatManualPayment || isFiatPaymentCompleted) && isCryptoPaymentCompleted,
    isFiatPaymentWithUsdt: payment?.cashPaymentMethodId === paymentMethods.usdt,
  };
};
