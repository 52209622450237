/* eslint-disable max-len */
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isNumber, isFunction, isArray, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Form, Input } from 'antd';
import { Button, Dropdown, Modal } from 'components/UIExternal';
import { USER_TYPE, userTypeInverse } from 'enums';
import { setError, getOneLifeProfileByEmail, updateManager, createManager } from 'services';
import { actionButtons } from './styles';

const emailRegex =
  /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email) => emailRegex.test(email);

export const EditManagerPopup = forwardRef(({ onSubmit }, ref) => {
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [form] = Form.useForm();
  const modalRef = useRef();
  const [manager, setManager] = useState(null);
  const [areaOptions, setAreasOptions] = useState(null);
  const isAreaManager = manager?.managerType === USER_TYPE.AREA_MANAGER;
  const isEdit = isNumber(manager?.feeAccountId);

  useImperativeHandle(ref, () => ({
    open: (managerToEdit) => {
      modalRef.current.open();
      managerToEdit?.id ? initializeUserData(managerToEdit) : setManager(managerToEdit);
    },
    close: () => modalRef.current.close(),
  }));

  const fetchOneLifeProfile = async (predefinedManager) => {
    const [res, err] = await getOneLifeProfileByEmail(predefinedManager.email);
    // We need to keep the old state because inside of it we have the managerType
    // ManagerType is stored inside the manager and only from there we can see what king of manager we add or edit
    err ? setError(err) : setManager((prev) => ({ ...prev, ...predefinedManager, ...res }));
  };

  const initializeUserData = async (managerToEdit) => {
    const isAreaMan = managerToEdit?.managerType === USER_TYPE.AREA_MANAGER;
    form.setFieldsValue({
      profileEmail: managerToEdit?.email,
      feeAccountId: managerToEdit?.feeAccountId,
      countries: isAreaMan ? managerToEdit.countries.at(0) : managerToEdit.countries,
      areas: managerToEdit.areas,
    });
    await fetchOneLifeProfile(managerToEdit);
    isAreaMan && initializeAreaOptions(managerToEdit?.countries);
  };

  const initializeAreaOptions = (countriesData) =>
    setAreasOptions(
      countries
        ?.flatMap((country) => country?.areas)
        ?.filter((area) =>
          isArray(countriesData)
            ? countriesData.find((el) => el.id === area?.countryId)
            : area?.countryId === countriesData?.id,
        ),
    );

  const handleValueChange = (changedField) => {
    const { profileEmail, countries: countriesValue } = changedField;

    // If email is changed fetch the profile from OneLife which has inserted email
    if (profileEmail) isValidEmail(profileEmail) && fetchOneLifeProfile({ email: profileEmail });

    // If countries is changed we need to load the new options for areas
    if (countriesValue && isAreaManager) {
      isNil(countriesValue) ? setAreasOptions(null) : initializeAreaOptions(countriesValue);
      form.setFieldsValue({ areas: [] });
    }
  };

  const handleSubmit = async (data) => {
    const body = { ...data, userTypeId: manager?.managerType, userId: manager?.userId };
    const method = isEdit ? updateManager : createManager;
    const [, err] = await method(body);

    if (err) return setError(err);

    isFunction(onSubmit) && onSubmit();
    modalRef.current.close();
  };

  const handleClose = () => {
    setManager(null);
    form.resetFields();
  };

  return (
    <Modal
      ref={modalRef}
      modalTitle={getText(isEdit ? 'editManagerType' : 'addManagerType', {
        type: getText(userTypeInverse[manager?.managerType]),
      })}
      onClose={handleClose}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleValueChange}
        onFinish={handleSubmit}
        validateMessages={{
          required: '${label} is required!',
          types: { email: '${label} is not a valid email!', number: '${label} is not a valid number!' },
        }}>
        <Form.Item
          label={getText('enterEmail')}
          name="profileEmail"
          rules={[{ required: true }, { type: 'email' }]}
          required>
          <Input disabled={isEdit} />
        </Form.Item>
        <Form.Item label={getText('selectAccount')} name="feeAccountId" rules={[{ required: true }]}>
          <Dropdown
            withSearch
            placeholder={getText('selectAccount')}
            options={manager?.accounts?.map((el) => ({ ...el, label: `#${el.id} ${el.nickName}` }))}
            uniqueKey="id"
            mapValue={(el) => el?.id}
          />
        </Form.Item>
        <Form.Item label={getText('selectCountry')} name="countries" rules={[{ required: true }]}>
          <Dropdown
            multiSelect={!isAreaManager}
            withSearch
            placeholder={getText('selectCountry')}
            options={countries}
            displayKey="name"
            uniqueKey="id"
          />
        </Form.Item>
        {isAreaManager && (
          <Form.Item label={getText('selectArea')} name="areas" rules={[{ required: true }]}>
            <Dropdown
              multiSelect
              withSearch
              placeholder={getText('selectArea')}
              options={areaOptions}
              displayKey="areaName"
              uniqueKey="areaId"
            />
          </Form.Item>
        )}
        <div css={actionButtons}>
          <Button small type="secondary">
            {getText('apply')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

EditManagerPopup.propTypes = {
  onSubmit: PropTypes.func,
};
