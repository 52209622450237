import { theme } from 'styles';

export const pageContainer = {
  padding: '0 2em',
};

// table config

export const textLinkStyle = {
  color: theme.primary_blue,
  padding: 0,
  '&:hover': {
    color: theme.primary_blue,
  },
};

export const actionStyle = {
  padding: 0,
};
