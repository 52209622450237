import { theme } from 'styles';

export const formBoxContainer = {
  padding: '1em',
  borderRadius: 4,
  border: `1px solid ${theme.gray500}`,
  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.4)',
  flex: '0 0 20rem',
};

export const formBoxTitle = {
  fontSize: '2rem',
};

export const formBoxSubTitle = {
  fontWeight: 500,
};
