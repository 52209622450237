import { getText, getDynamicTranslation } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { Tag } from 'components/UIExternal';
import { dealStatusInverse, dealStatusColor } from 'enums';
import { textLinkStyle, actionStyle } from './styles';

export const disputesColumns = () => [
  {
    render: (record) => (
      <Button type="link" linkTo={`/dispute/${record.id}`} css={textLinkStyle}>
        {getDynamicTranslation(record.dealTitle)?.title}
      </Button>
    ),
  },
  {
    name: getText('status'),
    render: ({ statusId }) => <Tag type={dealStatusColor[statusId]}>{getText(dealStatusInverse[statusId])}</Tag>,
    width: 100,
  },
  {
    name: getText('issuer'),
    value: 'issuerNames',
  },
  {
    name: getText('endDate'),
    value: 'endDate',
    dateTimeFormat: 'MMM DD,YYYY',
    width: 120,
  },
  {
    name: getText('creationDate'),
    value: 'createdDate',
    dateTimeFormat: 'MMM DD,YYYY',
    width: 120,
  },

  {
    name: '',
    render: (record) => (
      <Button type="link" linkTo={`/dispute/${record?.id}`} small css={actionStyle}>
        <Icon iconName="lar la-eye" size={20} />
      </Button>
    ),
    width: 40,
  },
];
