import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { useLocations, refetchCountries } from '@oneecosystem/dealshaker-core';
import { Input } from 'antd';
import { CountryDropdown, TextInput } from 'components/ui';
import { Button, useUrlParams, useDeepEffect } from 'components/UIExternal';
import { PageHeader } from 'components/shared';
import { createCountryArea, updateCountryArea } from 'services';
import useError from 'services/errorHandling/useError';
import { pageFilterCollapse } from 'styles';
import { addAreaBtns } from './styles';

export const AreasFilterBar = ({ paginationRef, filterKeys }) => {
  const { queryParams, setQueryParams, clearQueryParams } = useUrlParams();
  const { setError } = useError();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [selectedArea, setSelectedArea] = useState(null);
  const queryCountryId = queryParams?.[filterKeys.countryId];
  const areaId = queryParams?.[filterKeys.areaId];
  const selectedCountry = countries.find((el) => el.id === queryCountryId) ?? countries?.at(0);
  const isEdit = !!selectedArea?.areaId;

  useDeepEffect(() => {
    areaId !== selectedArea?.areaId && setSelectedArea(selectedCountry?.areas?.find((area) => area.areaId === areaId));
  }, [areaId]);

  useDeepEffect(() => {
    selectedArea?.areaId && setSelectedArea(null);
  }, [selectedCountry]);

  const handleAddUpdateArea = async () => {
    const method = isEdit ? updateCountryArea : createCountryArea;
    const [, err] = await method({
      ...selectedArea,
      areaName: selectedArea?.areaName?.trim(),
      countryId: selectedCountry.id,
    });

    clearQueryParams([filterKeys.areaId]);
    err ? setError(err) : refetchCountries();
  };

  const handleCancel = () => {
    setSelectedArea(null);
    clearQueryParams([filterKeys.areaId]);
  };

  return (
    <>
      <PageHeader title={getText('areas')} />
      <section css={pageFilterCollapse}>
        <section className="filters-container">
          <CountryDropdown
            noClear
            value={selectedCountry?.id}
            onChange={(val) => setQueryParams({ ...queryParams, [filterKeys.countryId]: val })}
            placeholder={getText('country')}
          />
          {!selectedArea ? (
            <TextInput
              showSearch
              showDelete
              onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.areaName]: val })}
              placeholder={getText('searchArea')}
            />
          ) : (
            <Input
              placeholder={getText('areaName')}
              value={selectedArea?.areaName}
              onChange={(e) => setSelectedArea({ ...selectedArea, areaName: e?.target?.value })}
            />
          )}
          <div className="flex-auto">
            {!selectedArea ? (
              <Button type="secondary" small onClick={() => setSelectedArea({})}>
                {getText('addArea')}
              </Button>
            ) : (
              <div css={addAreaBtns}>
                <Button type="primary" small onClick={handleAddUpdateArea} disabled={!selectedArea?.areaName?.trim()}>
                  {getText('submit')}
                </Button>
                <Button type="default" small onClick={handleCancel}>
                  {getText('cancel')}
                </Button>
              </div>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

AreasFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
  getData: PropTypes.func,
};
