import { switchSlider, slider } from './styles';
import PropTypes from 'prop-types';

export const CheckBoxTogglerSlider = ({ onChange, checked }) => {
  return (
    <label css={switchSlider} htmlFor="checkbox">
      <input type="checkbox" id="checkbox" onChange={onChange} checked={checked} />
      <div css={slider} className="round slider" />
    </label>
  );
};

CheckBoxTogglerSlider.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};
