import { commonStyles, theme } from 'styles';

export const searchBarContainer = (props) => [
  {
    position: 'relative',
    zIndex: 50,
    width: '100%',
  },
  ...commonStyles(props),
];

export const searchBarInputContainer = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.white,
  border: `1px solid ${theme.gray500}`,
  width: '100%',
};

export const searchBarInput = {
  fontSize: '0.8rem',
  lineHeight: 1,
  outline: 'none',
  padding: '0.4em 0.7em',
  border: 'none',
  background: 'transparent',
  flex: 1,

  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
};

export const searchBarInputIcon = {
  cursor: 'text',
  fontSize: 17,
  padding: '0.4em 0.6em 0.4em 0',
};

export const searchBarSuggestionsContainer = {
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: 0,
  right: 0,
  color: theme.gray200,
  maxHeight: '18.75rem',
  zIndex: 20,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  backgroundColor: theme.white,
  boxShadow: theme.boxShadow,
};

export const searchBarSuggestionItem = {
  padding: '0.8rem 1.5rem',
  color: theme.gray200,

  '&:focus, &:hover': {
    outline: 'none',
    backgroundColor: theme.primary,
    color: theme.gray300,
  },
};
