import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { PriceLabel } from 'components/shared';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { transactionsRowStatus } from './styles';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { Tag } from 'components/UIExternal';
import { validDate } from 'utils';
import moment from 'moment';
import { textMutedStyle, textLinkStyle } from '../../../styles';

export const getTransactionsWithOrderInfoTableColumns = (theme, getText = () => { }) => [
	{
		name: getText('transactionId'),
		render: row => (
			<>
				<Link css={textLinkStyle} to={`/financials/transactions/${row.id}/details`}>#{row.id}</Link>
				{row.externalId && <span>{`#${row.externalId}`}</span>}
			</>
		),
		width: 130,
	},
	{
		name: getText('dateCreation'),
		value: 'date',
		dateTimeFormat: 'DD MMM YYYY',
		width: 130,
	},
	{
		name: getText('buyer'),
		render: row => (
			<div>
				<strong>{row.buyerName}</strong>
				<br />
				<span css={textMutedStyle}>{row.buyerPhone}</span>
				<br />
				<span css={textMutedStyle}>{row.buyerEmail}</span>
				<br />
				<span css={textMutedStyle}>{row.buyerNickname}</span>
			</div>
		),
	},
	{
		name: getText('amount'),
		render: row => (
			<PriceLabel
				fiat={row.originalAmount}
				fiatOriginCurrency={row.originalCurrency}
				fiatDesiredCurrency={row.originalCurrency}
				crypto={row.oneCoinAmount || row.oespAmount}
				cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
				cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
				fiatOnly={false}
			/>
		),
		width: 220,
	},
	{
		name: getText('orderId'),
		render: row => <Link css={textLinkStyle} to="/orders">#{row.orderId}</Link>,
		width: 130,
	},
	{
		name: getText('reason'),
		value: 'reason',
		width: 180,
	},
	{
		name: getText('status'),
		render: row => (
			<div className={transactionsRowStatus}>
				<Tag type={TRANSACTION_STATUS_COLOR[row.statusId]} fontSize="0.725rem">
					{getText(TRANSACTION_STATUS_INVERSE[row.statusId])}
				</Tag>
				{row.clearingDate && (
					<div className="clearing-date">
						<span>on {validDate(row.clearingDate) && moment(row.clearingDate).format('DD MMM YYYY')}</span>
					</div>
				)}
			</div>
		),
		width: 180,
	},
];