import { theme } from 'styles';

export const couponsConfig = (data) => ({
  type: 'doughnut',
  data: {
    datasets: [
      {
        data,
        backgroundColor: [theme.green3, theme.primary_blue, theme.red_dark, theme.green, theme.purple2],
        borderJoinStyle: 'round',
        hoverOffSet: 4,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
  },
});

export const topSellingCategoriesConfig = (data) => ({
  type: 'bar',
  data: {
    axis: 'y',
    datasets: [
      {
        data,
        backgroundColor: [theme.blue2],
        borderWidth: 1,
      },
    ],
  },
  options: {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    parsing: {
      xAxisKey: 'value',
      yAxisKey: 'key',
    },
  },
});

export const paymentMethodConfig = (data) => ({
  type: 'doughnut',
  data: {
    datasets: [
      {
        data,
        backgroundColor: [theme.primary_orange, theme.red],
        borderJoinStyle: 'round',
        hoverOffSet: 4,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
  },
});

export const totalSalesConfig = (data) => {
  const labels = data?.map((x) => x.key);
  const values = data?.map((x) => x.value);

  return {
    type: 'line',
    data: {
      labels,
      datasets: [
        {
          fill: true,
          borderColor: theme.primary_blue,
          backgroundColor: theme.skyblue,
          tension: 0.1,
          data: values,
        },
      ],
    },
    options: {
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 5,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
          },
        },
      },
      plugins: {
        tootip: {
          position: 'nearest',
        },
        legend: {
          display: false,
          labels: {
            color: 'rgb(255, 99, 132)',
          },
        },
      },
    },
  };
};

export const topMerchantBySoldCouponsConfig = (data) => ({
  type: 'bar',
  data: {
    axis: 'y',
    datasets: [
      {
        data,
        backgroundColor: [theme.violet],
        borderWidth: 1,
      },
    ],
  },
  options: {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    parsing: {
      xAxisKey: 'value',
      yAxisKey: 'key',
    },
  },
});

export const topMerchantByTurnOverConfig = (data) => ({
  type: 'bar',
  data: {
    axis: 'y',
    datasets: [
      {
        data,
        backgroundColor: [theme.green3],
        borderWidth: 1,
      },
    ],
  },
  options: {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    parsing: {
      xAxisKey: 'value',
      yAxisKey: 'key',
    },
  },
});
