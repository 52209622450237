import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from 'lodash-es';
import { Col, Row } from 'antd';
import { Icon } from 'components/ui';
import { deliveryMethodSelect } from 'enums';
import { deliveryMethodStyle, tagListStyles, tagStyles, packageStyles } from './styles';

const DealDetailsPreview = ({ deal }) => {
  const { getText } = useTranslations();

  return (
    <Row>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('category')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">{getText(deal.category)}</span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('deliveryMethod')}</span>
      </Col>
      <Col lg={20} span={24}>
        <div css={deliveryMethodStyle}>
          <Icon iconName={deliveryMethodSelect[deal.deliveryMethodId]?.icon} size={16} />
          <span className="content">{getText(deliveryMethodSelect[deal.deliveryMethodId]?.label)}</span>
        </div>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('merchantName')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">{deal.merchantName}</span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('bvPoints')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">{deal.bvPoints}</span>
      </Col>
      {!isEmpty(deal.tags) && (
        <>
          <Col lg={4} span={24}>
            <span className="subtitle">{getText('tags')}</span>
          </Col>
          <Col lg={20} span={24}>
            <div css={tagListStyles}>
              {deal.tags?.map((tag, idx) => (
                <div key={idx} css={tagStyles}>
                  {tag}
                </div>
              ))}
            </div>
          </Col>
        </>
      )}
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('packageSize')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">
          <span css={packageStyles}>
            {getText('height')} {deal.height ?? 0} cm
          </span>
          <span css={packageStyles}>
            {getText('width')} {deal.width ?? 0} cm
          </span>
          <span css={packageStyles}>
            {getText('depth')} {deal.length ?? 0} cm
          </span>
        </span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('packageWeight')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content">
          <span css={packageStyles}>
            {getText('weight')} {`${deal?.weight ?? 0} ${deal?.weightTypeId === 1 ? 'gr' : 'kg'}`}
          </span>
        </span>
      </Col>
    </Row>
  );
};

DealDetailsPreview.propTypes = {
  deal: PropTypes.object,
};

export default DealDetailsPreview;
