import { getText } from '@veraio/strank';
import moment from 'moment';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { CopyText, Tag } from 'components/UIExternal';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE, PAYMENT_PROVIDERS_INVERT } from 'enums';
import { PriceLabel } from 'components/shared';

export const transactionDetailsHistoryTable = () => [
  {
    name: getText('id'),
    width: 110,
    render: (row) => (
      <div>
        <p>{row.id}</p>
        {!!row.externalId && row.externalId !== '0' && (
          <CopyText css={{ fontSize: '0.7rem', display: 'flex', alignItems: 'center' }}>{row.externalId}</CopyText>
        )}
      </div>
    ),
  },
  {
    name: getText('createdDate'),
    value: 'updateDate',
    dateTimeFormat: 'DD-MM-YYYY HH:mm',
    width: 180,
  },
  {
    name: getText('sender'),
    value: 'senderAccountId',
    // It is commented until the backend learn how to re-use models and stop do new model which are inconsistent and misses information
    // render: (row) => (
    //   <>
    //     {row.senderEmail && <p>{row.senderEmail}</p>}
    //     {row.senderName && <p>{row.senderName}</p>}
    //     {row.senderPhone && <p>{row.senderPhone}</p>}
    //   </>
    // ),
  },
  {
    name: getText('receiver'),
    value: 'receiverAccountId',
    // It is commented until the backend learn how to re-use models and stop do new model which are inconsistent and misses information
    // render: (row) => (
    //   <>
    //     {row.receiverEmail && <p>{row.receiverEmail}</p>}
    //     {row.receiverName && <p>{row.receiverName}</p>}
    //     {row.receiverPhone && <p>{row.receiverPhone}</p>}
    //   </>
    // ),
  },
  {
    name: getText('amount'),
    render: (row) => (
      <PriceLabel
        fiat={row.originalAmount}
        fiatOriginCurrency={row.originalCurrency}
        fiatDesiredCurrency={row.originalCurrency}
        crypto={row.oneCoinAmount || row.oespAmount}
        cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        fiatOnly={false}
      />
    ),
    width: 250,
  },
  {
    name: getText('paymentProvider'),
    render: (row) => getText(PAYMENT_PROVIDERS_INVERT[row.paymentProviderId]),
    width: 150,
  },
  {
    name: getText('status'),
    render: (row) => (
      <Tag type={TRANSACTION_STATUS_COLOR[row.statusId]}>{getText(TRANSACTION_STATUS_INVERSE[row.statusId])}</Tag>
    ),
    width: 130,
  },
  {
    name: getText('reason'),
    value: 'reason',
  },
];

export const transactionDetailsGenericInfo = () => [
  { key: 'orderId', label: getText('orderId') },
  { key: 'transactionId', label: getText('transactionId') },
  { key: 'externalTransactionId', label: getText('externalTransactionId') },
  { key: 'senderUserId', label: getText('senderUserId') },
  { key: 'senderEmail', label: getText('senderEmail') },
  { key: 'senderName', label: getText('senderName') },
  { key: 'senderPhone', label: getText('senderPhone') },
  { key: 'receiverUserId', label: getText('receiverUserId') },
  { key: 'receiverEmail', label: getText('receiverEmail') },
  { key: 'receiverName', label: getText('receiverName') },
  { key: 'receiverPhone', label: getText('receiverPhone') },
  {
    key: 'createdDate',
    label: getText('createdDate'),
    render: (row) => moment(row.createdDate).format('MMM DD,YYYY HH:MM'),
  },
  {
    key: 'status',
    label: getText('status'),
    render: (row) => (
      <Tag type={TRANSACTION_STATUS_COLOR[row.statusId]}>{getText(TRANSACTION_STATUS_INVERSE[row.statusId])}</Tag>
    ),
  },
];
