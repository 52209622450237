import { putReq, postReq, deleteReq } from 'services/axios/makeRequest';
import { createCountryAreaParams, updateCountryAreaParams } from 'services/models/countries';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Countries`;

export const updateCountryRestricted = (data) => putReq(`${baseUrl}/Update`, data);

export const createCountryArea = (options) => postReq(`${baseUrl}/CreateArea`, createCountryAreaParams(options));

export const updateCountryArea = async (options) => {
  const params = updateCountryAreaParams(options);
  const { areaId, countryId, areaName } = params;
  const [, err] = await putReq(`${baseUrl}/UpdateArea/${areaId}`, { countryId, areaName });
  return [err];
};

export const deleteCountryArea = (id) => deleteReq(`${baseUrl}/DeleteArea/${id}`);
