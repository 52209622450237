import { useUser } from 'stores/contexts/User';
import PrivateRoutes from 'screens/Private/PrivateRoutes';
import Login from 'screens/Public/Login';
import Unauthorized from 'screens/Public/Unauthorized';

const Routing = () => {
  const { isAuthorized, isAuthenticated } = useUser();

  if (!isAuthenticated) return <Login />;

  if (!isAuthorized) return <Unauthorized />;

  return <PrivateRoutes />;
};
export default Routing;
