import { useEffect, useRef, useState } from 'react';
import TreeItem from './TreeItem';
import { flattenTree } from './utils';

const Tree = ({
	data,
	titleKey,
	idKey = 'id',
	childrenKey,
	renderItem,
	minSearchLength = 3,
	searchText
}) => {
	const [foundCollapsedItemsIds, setFoundCollapsedItemsIds] = useState({
		searchText: null,
		ids: []
	});
	const flatTreeRef = useRef([]);

	useEffect(() => {
		if ((data || []).length > 0)
			flatTreeRef.current = flattenTree(data, childrenKey, idKey);
	}, [data.length]);

	useEffect(() => {
		updateFoundItemsIds(searchText);
	}, [searchText]);

	const updateFoundItemsIds = (search) => {
		if (search.length === 0) {
			setFoundCollapsedItemsIds({
				searchText: null,
				ids: []
			});
		} else if (search.length < minSearchLength) {
			foundCollapsedItemsIds.ids.length > 0 && setFoundCollapsedItemsIds({
				searchText: search,
				ids: []
			});
		} else {
			const foundItems = flatTreeRef.current.filter(item =>
				item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);

			const foundIds = [];
			foundItems.forEach(item => item.parentItem && foundIds.push(
				...[...Object.values(item.parentItem), item.id]
			));
			setFoundCollapsedItemsIds({
				searchText,
				ids: foundIds
			});
		}
	};

	return <>{data.map(rootItem =>
		<TreeItem
			level={0}
			idkey='id'
			key={rootItem[idKey]}
			item={rootItem}
			titleKey={titleKey}
			childrenKey={childrenKey}
			expandedItemsIds={foundCollapsedItemsIds}
			minSearchLength={minSearchLength}
			render={renderItem} />
	)}</>;
};

export default Tree;