import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/ui';
import { useUrlParams } from 'components/UIExternal';
import { CheckButton } from '../Button';
import { tableHead, tableHeadCell, tableHeadIcon, tableCellCheckButton } from './styles';

const TableHead = (props) => {
  const { columns, getData, data, hasSelection, selectRow, allSelected } = props;
  const [sort, setSort] = useState(null);
  const { queryParams, setQueryParams } = useUrlParams();

  const changeSort = (column) => () => {
    const { value } = column;
    const isDesc = sort === value;
    const newSort = isDesc ? `${value}Descending` : sort === `${value}Descending` ? null : value;
    setSort(newSort);
    getData({
      ...queryParams,
      sort: newSort && { sortField: value, sortOption: isDesc ? 'desc' : 'asc' },
    });
    setQueryParams({ ...queryParams, sortField: value, sortOption: isDesc ? 'desc' : 'asc' });
  };

  return (
    <div css={tableHead}>
      {hasSelection && !!data?.length && (
        <div css={tableHeadCell({ flex: '0 0 40px', render: true })}>
          <CheckButton onChange={selectRow} checked={allSelected} css={tableCellCheckButton} />
        </div>
      )}
      {columns.map((column, idx) => {
        const { sortable, name, value } = column;
        return sortable ? (
          <button key={name} css={tableHeadCell(column)} onClick={changeSort(column)}>
            <p>{name}</p>

            <Icon
              material
              iconName="arrow_downward"
              css={tableHeadIcon(sort === value, sort === `${value}Descending`)}
            />
          </button>
        ) : (
          <div key={idx} css={tableHeadCell(column)}>
            <p>{name}</p>
          </div>
        );
      })}
    </div>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
  hasSelection: PropTypes.bool,
  selectRow: PropTypes.func,
  allSelected: PropTypes.bool,
  data: PropTypes.array,
};

export default TableHead;
