import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { Col, Row } from 'antd';

const PromotionDetailsPreview = ({ deal }) => {
  const { getText } = useTranslations();
  const formatDate = (date) => moment(date).format('MMM DD, YYYY');

  return (
    <Row>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('launchDate')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content date">{formatDate(deal.startDate)}</span>
      </Col>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('endDate')}</span>
      </Col>
      <Col lg={20} span={24}>
        <span className="content date">{formatDate(deal.endDate)}</span>
      </Col>
    </Row>
  );
};

PromotionDetailsPreview.propTypes = {
  deal: PropTypes.object,
};

export default PromotionDetailsPreview;
