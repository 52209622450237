import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { checkButtonContainer, checkboxContainer } from './styles';

export const CheckButton = (props) => {
  const { checked, onChange, radio, children, className, disabled } = props;
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  const handleChange = () => {
    setCheck((prev) => {
      isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };

  return (
    <div
      role="button"
      aria-hidden="true"
      tabIndex={0}
      css={checkButtonContainer(disabled, className)}
      onClick={handleChange}>
      <span css={checkboxContainer(disabled, className, check, radio)} />
      {children}
    </div>
  );
};

CheckButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
