import { theme } from 'styles';

export const searchInput = (className) => [
  {
    '&.ant-input-affix-wrapper': {
      borderRadius: '20px',
      '& svg': {
        stroke: theme.gray100,
      },
    },
  },
  className,
];

export const linkBtn = {
  padding: '0 0.25em 0 0.125em',
  minHeight: 0,
};
