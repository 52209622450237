import { theme } from 'styles';

export const mainContainer = {
  marginLeft: 0,
  marginTop: '4em',
  '& h2': {
    fontSize: '2.375rem',
    marginTop: '3.75em',
    textAlign: 'center',
  },
  '& img': {
    [theme.Mobile]: { width: '100%' },
  },
  '& p': {
    fontSize: '1.125rem',
    marginBottom: '2.5em',
    textAlign: 'center',
    [theme.Desktop]: {
      width: '48%',
    },
  },
};
