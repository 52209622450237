import { Table } from 'components/UIExternal';
import { AddressesFilterBar } from 'components/screens';
import { businessAddressStatus } from 'enums';
import { getAllBusinessAddresses } from 'services';
import { addressesColumns } from './config';
import { pageContainer } from './styles';

const filterKeys = {
  status: 'statusIds',
  search: 'searchText',
  business: 'businessName',
  creationDateFrom: 'creationDateFrom',
  creationDateTo: 'creationDateTo',
  modifiedDateFrom: 'modifiedDateFrom',
  modifiedDateTo: 'modifiedDateTo',
};

const Addresses = () => {
  return (
    <section css={pageContainer}>
      <Table
        // addressesColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={addressesColumns()}
        getDataMethod={getAllBusinessAddresses}
        filterBar={AddressesFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.status]: [businessAddressStatus.pending] }}
      />
    </section>
  );
};

export default Addresses;
