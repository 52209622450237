import { Form, Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Icon } from 'components/ui';
import { Dropdown } from 'components/UIExternal';
import { FormEditButtons } from 'components/shared';
import { getCountryPhoneCodeOptions } from 'utils';
import { formContactInfoStyles, phoneCodeStyles } from './styles';

const ContactInformationEdit = ({ business, onEdit, onCancel }) => {
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [form] = Form.useForm();
  const phoneCodes = getCountryPhoneCodeOptions(countries);

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  const newExternalValues = {
    ...omit(business, 'phone'),
    phoneCode: phoneCodes?.find(code => Number(code.value) === Number(business?.phone?.split(' ')[0] ?? '')),
    phoneNumber: business?.phone?.split(' ')[1] ?? '',
  };

  const handleSaveForm = async (values) => {
    const formEditValues = {
      ...omit(values, 'phoneCode', 'phoneNumber'),
      phone: `${values.phoneCode.value} ${values.phoneNumber}`,
    };
    await onEdit(formEditValues);
    onCancel();
  };

  return (
    <Form
      form={form}
      initialValues={newExternalValues}
      onFinish={handleSaveForm}
      layout="inline
    "
      css={formContactInfoStyles}>
      <Form.Item
        name="phoneCode"
        data-id="Address-phoneCode"
        label={<Icon iconName="las la-phone-alt" />}
        rules={[mandatoryRule]}>
        <Dropdown
          noClear
          placeholder={getText('phoneCode')}
          options={phoneCodes}
          uniqueKey="value"
          displayKey="label"
          withSearch
          css={phoneCodeStyles}
          renderSelected={selected => selected && `+ ${selected.value}`}
        />
      </Form.Item>
      <Form.Item name="phoneNumber" data-id="Address-phone" rules={[mandatoryRule]}>
        <InputNumber className="phone-number" placeholder={getText('enterPhoneNumber')} />
      </Form.Item>
      <Form.Item
        label={<Icon iconName="las la-envelope" />}
        name="email"
        rules={[
          mandatoryRule,
          {
            max: 64,
            message: getText('fieldShouldNotExceedCountSymbols', {
              field: getText('emailAddress'),
              count: 64,
            }),
          },
          {
            type: 'email',
            message: getText('enterValidEmailAddress'),
          },
        ]}>
        <Input type="text" placeholder={getText('enterEmailAddress')} />
      </Form.Item>
      <Form.Item label={<Icon iconName="las la-globe" />} name="website">
        <Input type="text" placeholder={getText('enterMerchantWebsite')} />
      </Form.Item>
      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

ContactInformationEdit.propTypes = {
  business: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ContactInformationEdit;
