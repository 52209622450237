import { useState } from 'react';
import PropTypes from 'prop-types';
import { Slider, InputNumber } from 'antd';
import { mainContainer, inputsContainer } from './styles';

const RangeSlider = ({ min, max, label, onMinValueChange, onMaxValueChange }) => {
  const [inputMinValue, setInputMinValue] = useState(min);
  const [inputMaxValue, setInputMaxValue] = useState(max);
  const [inputMinPrevValue, setInputMinPrevValue] = useState(min);
  const [inputMaxPrevValue, setInputMaxPrevValue] = useState(max);

  const onSliderChange = (value) => {
    setInputMinValue(value[0]);
    setInputMaxValue(value[1]);
  };

  const onSliderAfterChange = (value) => {
    const [newMin, newMax] = value;

    if (value[0] !== inputMinPrevValue) {
      onMinValueChange(newMin);
      setInputMinPrevValue(newMin);
    }
    if (value[1] !== inputMaxPrevValue) {
      onMaxValueChange(newMax);
      setInputMaxPrevValue(newMax);
    }
  };

  const onMinChange = (value) => {
    setInputMinValue(value);
    onMinValueChange(value);
  };

  const onMaxChange = (value) => {
    setInputMaxValue(value);
    onMaxValueChange(value);
  };

  return (
    <div css={mainContainer}>
      <div css={inputsContainer}>
        <InputNumber value={inputMinValue} showCount={false} onChange={onMinChange} />
        <span>-</span>
        <InputNumber value={inputMaxValue} onChange={onMaxChange} />
        <label>{label}</label>
      </div>
      <Slider
        min={min}
        max={max}
        range
        onChange={onSliderChange}
        onAfterChange={onSliderAfterChange}
        value={[inputMinValue, inputMaxValue]}
        data-automation-id="index-7a1311a2-4e51-4ff3-bb13-3d6c15197f57"
      />
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  onMinValueChange: PropTypes.func,
  onMaxValueChange: PropTypes.func,
};

export default RangeSlider;
