export const appContainer = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
};

export const mainContainer = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'hidden',
};

export const contentContainer = {
  flex: 1,
  overflow: 'hidden auto',
};
