export const cacheKeys = {
  CACHED_TRANS: 'cached-translations',
  TRANSLATIONS_LOADING: 'translations-loading',
  LOADED_LANG: 'loaded-lang',
  STRAPI_JWT: 'strapi-jwt',
  LOGGED_MISSING_TRANS: 'logged-missing-translations',
};

export const getCacheVal = key => {
  const strValue = window.localStorage.getItem(key);
  let value = null;
  try {
    value = JSON.parse(strValue);
  } catch (e) {
    value = strValue;
  }

  return value;
};

export const setCacheVal = (key, val) => window.localStorage.setItem(key, JSON.stringify(val));

export const removeCacheVal = key => window.localStorage.removeItem(key);
