import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Table } from 'components/UIExternal';
import Routes from 'enums/Routes';
import { dealColumns } from '../Deals/config';
import { businessesColumns } from '../Businesses/config';
import { businessStatus, dealStatus } from 'enums';
import useError from 'services/errorHandling/useError';
import { getStatisticsDashboard, getAllDeals, getAllBusinesses } from 'services';
import {
  pageContainer,
  titleStyle,
  linkBtnStyle,
  statisticsContainer,
  block,
  dataNumbers,
  totalStyles,
} from './styles';

const HomePage = () => {
  const { setError } = useError();
  const { getText } = useTranslations();
  const [statsData, setStatsData] = useState({});

  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = async () => {
    const [res, err] = await getStatisticsDashboard();
    err ? setError(err) : setStatsData(res);
  };

  return (
    <section css={pageContainer}>
      <h2 css={titleStyle}>{getText('todaySummary')}</h2>
      <div css={statisticsContainer}>
        <div css={block}>
          <p css={dataNumbers}>{statsData?.totalDeals}</p>
          <span css={totalStyles}>{getText('total')}</span>
          <Button type="link" linkTo={Routes.Deals} small css={linkBtnStyle}>
            {getText('deals')}
          </Button>
        </div>

        <div css={block}>
          <p css={dataNumbers}>{statsData?.totalBusinesses}</p>
          <span css={totalStyles}>{getText('total')}</span>
          <Button type="link" linkTo={Routes.Businesses} small css={linkBtnStyle}>
            {getText('businesses')}
          </Button>
        </div>

        <div css={block}>
          <p css={dataNumbers}>{statsData?.totalAddresses}</p>
          <span css={totalStyles}>{getText('total')}</span>
          <Button type="link" linkTo={Routes.Addresses} small css={linkBtnStyle}>
            {getText('addresses')}
          </Button>
        </div>

        <div css={block}>
          <p css={dataNumbers}>{statsData?.totalRefunds}</p>
          <span css={totalStyles}>{getText('total')}</span>
          <Button type="link" linkTo={Routes.Refunds} small css={linkBtnStyle}>
            {getText('refunds')}
          </Button>
        </div>

        <div css={block}>
          <p css={dataNumbers}>{statsData?.totalDisputes}</p>
          <span css={totalStyles}>{getText('total')}</span>
          <Button type="link" linkTo={Routes.Disputes} small css={linkBtnStyle}>
            {getText('disputes')}
          </Button>
        </div>
      </div>

      <h2 css={titleStyle}>{getText('deals')}</h2>
      <Table
        // dealColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={dealColumns()}
        getDataMethod={() => getAllDeals({ pageSize: 3, pageIndex: 1, statusIds: dealStatus.pending })}
        hasPagination={false}
      />

      <h2 css={titleStyle}>{getText('businesses')}</h2>
      <Table
        // businessesColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={businessesColumns()}
        getDataMethod={() => getAllBusinesses({ pageSize: 3, pageIndex: 1, statusIds: businessStatus.pending })}
        hasPagination={false}
      />
    </section>
  );
};

export default HomePage;
