import { forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isPlainObject, has } from 'lodash-es';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { Input } from 'antd';
import { searchInput, linkBtn } from './styles';

export const SearchInput = forwardRef((props, ref) => {
  const {
    inputClass,
    placeholder,
    onSearch,
    onPressEnter,
    onIconClick,
    allowClear,
    value,
    noValue,
    defaultValue,
    className,
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    const closeIcon = inputRef.current?.input?.parentNode?.querySelector('.ant-input-clear-icon');
    if (closeIcon) closeIcon.onclick = (e) => isFunction(onPressEnter) && onPressEnter('', e);
  }, [onPressEnter]);

  const onChange = () => isFunction(onSearch) && onSearch(inputRef.current?.state?.value);

  return (
    <Input
      ref={(reference) => {
        isFunction(ref) && ref(reference);
        // eslint-disable-next-line no-param-reassign
        if (isPlainObject(ref) && has(ref, 'current')) ref.current = reference;
        inputRef.current = reference;
      }}
      allowClear={allowClear}
      className={inputClass ? `${inputClass} ${searchInput(className)}` : searchInput(className)}
      placeholder={placeholder}
      onChange={onChange}
      onPressEnter={(e) => onPressEnter(e?.target?.value, e)}
      value={noValue || !value ? undefined : value}
      defaultValue={defaultValue}
      prefix={
        <Button type="link" className={linkBtn} small onClick={onIconClick}>
          <Icon iconName="las la-search" />
        </Button>
      }
    />
  );
});

SearchInput.propTypes = {
  inputClass: PropTypes.string,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  isIconLeft: PropTypes.bool,
  onSearch: PropTypes.func,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  value: PropTypes.string,
  noValue: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
