import { theme } from 'styles';

export const titleStyle = {
  color: '#273B4A',
  fontSize: '2rem',
  marginBottom: 0,
};

export const formStyle = {
  border: `1px solid ${theme.gray500}`,
  padding: '1em',
  maxWidth: 700,
  marginTop: '2.5em',
  '& label': {
    fontSize: '0.8125rem',
    fontWeight: 500,
  },
  '& .ant-picker': { width: '100%' },
};

export const createNotificationFormContainer = {
  padding: '0 2em',
};

export const notificationCountry = {
  marginBottom: 0,
  '& .ant-select.ant-select-single': {
    backgroundColor: theme.white,
    height: '2.5rem',
  },
};

export const notificationSendTo = {
  fontWeight: 400,
  fontSize: '0.875rem',
  color: theme.gray100,
  '& .ant-select': {
    display: 'block',
  },
  '& .ant-select.ant-select-single': {
    backgroundColor: theme.white,
  },
  '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: `1px solid ${theme.gray500}`,
    backgroundColor: 'transparent',
    [theme.Mobile]: {
      height: '2.5rem',
    },
    [theme.Desktop]: {
      height: '2.5rem',
    },
  },
  '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
    border: `1px solid ${theme.gray500}`,
  },
  '& .ant-select-selector': {
    paddingTop: '0.1875em !important',
  },
  '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    borderColor: theme.gray500,
    boxShadow: 'none',
  },
};

export const bodyText = {
  [theme.Desktop]: {
    minHeight: '11rem !important',
  },
};

export const notificationActions = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1em',
  flexWrap: 'wrap-reverse',
};

export const backButtonStyle = {
  padding: '0.5em 1em 0.5em 0',
  '& > span': {
    fontSize: '2rem',
  },
};

export const breadcrumbsStyles = {
  marginTop: '2.5em',
  '& > li ': {
    display: 'flex',
    alignItems: 'center',
  },
};

export const notificationBreadcrumbs = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& > span': {
    fontSize: '1.5rem',
    color: theme.gray200,
  },
};

export const cancelButtonStyle = {
  color: theme.gray400,
};

export const mainBodyNotification = {
  '& > div': {
    display: 'block',
  },
  textWrap: 'wrap',
};

export const notificationCheckboxStyle = {
  textWrap: 'wrap',
  '& button': {
    textAlign: 'left',
  },
};
