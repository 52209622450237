import { getText } from '@veraio/strank';
import { pick, isNil } from '@veraio/core';
import { Skeleton } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { useOrders } from 'stores';
import { orderDetailsUserContainer } from './styles';

const UserAvatar = ({ user }) => {
  // Avatars for now are not used and returned from API - 1000 users from 480 000 have avatars
  // if (user?.avatar) return <img src={user?.avatar} className="order-details-user-avatar" alt="user" />;

  const arr = user?.name?.split(' ');
  // If the user do not have name show placeholder of UA instead of show nothing
  const letters = `${arr.at(0).charAt(0) ?? 'U'}${arr.at(-1).charAt(0) ?? 'A'}`;

  return <span className="order-details-user-avatar">{letters}</span>;
};

const UserBox = ({ user }) => (
  <section className="order-details-user-card">
    <h5 className="order-details-user-title gray">{getText(user?.businessName ? 'merchant' : 'buyer')}</h5>
    {isNil(user) ? (
      <Skeleton circle width="3rem" height="3rem" />
    ) : (
      <div className="order-details-user-name gray">
        <UserAvatar user={user} />
        <h5>{user?.name}</h5>
      </div>
    )}
    <hr className="order-details-user-info-separator" />
    <div className="order-details-user-info-row">
      <div className="order-details-user-info-icon-container">
        <Icon iconName="las la-id-card order-details-user-info-icon" />
        <span className="order-details-user-info-tooltip">{getText('userAccountId')}</span>
      </div>
      {isNil(user) ? <Skeleton width="5rem" /> : <span>{user.onelifeAccountId}</span>}
    </div>
    {user?.businessName && (
      <div className="order-details-user-info-row">
        <div className="order-details-user-info-icon-container">
          <Icon iconName="las la-briefcase order-details-user-info-icon" />
          <span className="order-details-user-info-tooltip">
            {getText('merchant')} {getText('business')} {getText('name')}
          </span>
        </div>
        {isNil(user) ? <Skeleton width="6rem" /> : <span>{user?.businessName}</span>}
      </div>
    )}
    <div className="order-details-user-info-row">
      <div className="order-details-user-info-icon-container">
        <Icon iconName="las la-envelope order-details-user-info-icon" />
        <span className="order-details-user-info-tooltip">
          {user?.businessName ? (
            <>
              {getText('merchant')} {getText('business')} {getText('email')}
            </>
          ) : (
            <>
              {getText('user')} {getText('email')}
            </>
          )}
        </span>
      </div>
      {isNil(user) ? <Skeleton width="7rem" /> : <span>{user?.email}</span>}
    </div>
    <div className="order-details-user-info-row">
      <div className="order-details-user-info-icon-container">
        <Icon iconName="las la-phone order-details-user-info-icon" />
        <span className="order-details-user-info-tooltip">
          {getText('user')} {getText('phoneNumber')}
        </span>
      </div>
      {isNil(user) ? <Skeleton width="5rem" /> : <span>{user?.phoneNumber}</span>}
    </div>
  </section>
);

export const OrderDetailsUsers = () => {
  const { buyer, business } = useOrders((ordersState) => pick(ordersState?.selectedOrder, ['buyer', 'business']));

  return (
    <section css={orderDetailsUserContainer}>
      <UserBox user={business} />
      <Icon iconName="las la-exchange-alt" className="order-details-user-trade-icon" />
      <UserBox user={buyer} />
    </section>
  );
};
