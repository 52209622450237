import { theme } from 'styles';
export const container = {
  padding: '0 2em',
};

export const pageContainer = {
  padding: '2em 0',
};

export const tableContainer = {
  marginTop: '3em',
};

export const statBox = {
  marginLeft: 'auto',
  border: `1px solid ${theme.gray500}`,
  padding: '0.2em 1em',
  borderRadius: 3,
  '& p': {
    margin: 0,
  },
  '& p:first-of-type': {
    fontSize: '1rem',
    color: theme.gray100,
    fontWeight: 500,
    [theme.Mobile]: {
      fontSize: '0.875rem',
    },
  },
};

export const textLinkStyle = {
  color: theme.primary_blue,
  padding: 0,
  '&:hover': {
    color: theme.primary_blue,
  },
};

export const textMutedStyle = {
  color: theme.gray300,
};