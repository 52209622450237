import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as ck from 'ckeditor5';
import { isFunction } from '@veraio/core';
import { TOOLBAR, PLUGINS, FONT_SIZE, HEADING, IMAGE, LINK, LIST, TABLE } from './config';
import 'ckeditor5/ckeditor5.css';
import './ckeditor-styles.css';

export const HtmlEditor = (props) => {
  const { value, placeholder, onChange } = props;
  const { toolbar, plugins, fontFamily, fontSize, heading, image, link, list, table } = props;
  const editorContainerRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const handleChange = (_, editor) => {
    const newValue = editor.getData();
    isFunction(onChange) && onChange(newValue);
  };

  const editorConfig = {
    toolbar: toolbar ?? TOOLBAR,
    plugins: plugins ?? PLUGINS,
    fontFamily: fontFamily ?? { supportAllValues: true },
    fontSize: fontSize ?? FONT_SIZE,
    heading: heading ?? HEADING,
    image: image ?? IMAGE,
    initialData: value ?? '',
    link: link ?? LINK,
    list: list ?? LIST,
    menuBar: { isVisible: true },
    placeholder: placeholder ?? 'Type or paste your content here!',
    table: table ?? TABLE,
  };

  return (
    <div className="editor-container" ref={editorContainerRef}>
      <div className="editor-container__toolbar" ref={editorToolbarRef} />
      <div ref={editorRef} className="editor-container__editor">
        {isLayoutReady && (
          <CKEditor
            onReady={(editor) => editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element)}
            editor={ck.DecoupledEditor}
            config={editorConfig}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
};

HtmlEditor.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  toolbar: PropTypes.object,
  plugins: PropTypes.array,
  fontFamily: PropTypes.object,
  fontSize: PropTypes.object,
  heading: PropTypes.object,
  image: PropTypes.object,
  link: PropTypes.object,
  list: PropTypes.object,
  table: PropTypes.object,
};
