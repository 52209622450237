import { theme } from 'styles';

export const containerClass = {
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  marginLeft: 0,
  padding: 0,
};

export const text = {
  fontSize: '0.875rem',
  color: theme.gray200,
};

export const linkItem = (isActive) => ({
  fontSize: '0.875rem',
  color: isActive ? theme.primary_blue_hover : theme.gray200,
});

export const separator = {
  margin: '0px 0.5em',
  fontSize: '0.875rem',
  color: theme.gray200,
};
