import { pick, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Tag } from 'components/UIExternal';
import { Skeleton } from 'components/UIExternal';
import { useOrders } from 'stores';
import { orderStatusColor, orderStatusInverse } from 'enums';
import { formatDefaultDate } from 'utils';
import { orderDetailsHeaderContainer } from './styles';

export const OrderDetailsHeader = () => {
  const { getText } = useTranslations();
  const { orderId, statusId, createdDate } = useOrders((ordersState) => ({
    orderId: ordersState?.selectedOrder?.id,
    ...pick(ordersState?.selectedOrder, ['statusId', 'createdDate']),
  }));

  return (
    <section css={orderDetailsHeaderContainer}>
      <div className="order-details-status-container">
        {isNil(orderId) ? <Skeleton height="2rem" width="5rem" /> : <h3>#{orderId}</h3>}
        {isNil(statusId) ? (
          <Skeleton height="1.5rem" width="6.5rem" />
        ) : (
          <Tag type={orderStatusColor[statusId]} className="order-details-status">
            {getText(orderStatusInverse[statusId])}
          </Tag>
        )}
      </div>
      {isNil(createdDate) ? (
        <Skeleton marginTop=".7em" height="1rem" width="7rem" />
      ) : (
        <p className="gray">{getText('placedOnDate', { date: formatDefaultDate(createdDate) })}</p>
      )}
    </section>
  );
};
