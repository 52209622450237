import { theme } from 'styles';

export const datePicker = {
  '&.ant-picker': {
    border: `1px solid ${theme.gray500}`,
    borderRadius: 2,
    // This font size is needed to equalize the padding with other inputs
    fontSize: '0.8rem',
    padding: '0.4em 0.7em',
  },
  '& .ant-picker-input': {
    flexDirection: 'row-reverse',
    '& > input': {
      fontSize: '0.8rem',
    },
  },
  '& .ant-picker-suffix': {
    margin: '-0.1875em 0.25em 0 0',
  },
};
