import { Table } from 'components/UIExternal';
import { DialsFilterBar } from 'components/screens';
import { dealStatus } from 'enums';
import { getAllDeals } from 'services';
import { dealColumns } from './config';
import { container } from './styles';

const filterKeys = {
  status: 'statusIds',
  category: 'categoryIds',
  search: 'searchText',
  countries: 'countryIds',
  phone: 'phone',
  username: 'username',
  email: 'email',
  creationDateFrom: 'creationDateFrom',
  creationDateTo: 'creationDateTo',
  publishDateFrom: 'publishDateFrom',
  publishDateTo: 'publishDateTo',
  modifiedDateFrom: 'modifiedDateFrom',
  modifiedDateTo: 'modifiedDateTo',
  priceValue: 'priceValue',
  priceSign: 'priceSign',
};

const Deals = () => {
  return (
    <div css={container}>
      <Table
        // dealColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={dealColumns()}
        getDataMethod={getAllDeals}
        filterBar={DialsFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.status]: [dealStatus.pending] }}
      />
    </div>
  );
};

export default Deals;
