import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations, getLanguageByCode } from '@veraio/strank';
import { Col, Form, Input } from 'antd';
import { TabPane, Tabs } from 'components/ui';
import { FormEditButtons } from '../../../shared';

const BusinessMainInformationEdit = ({ business, onEdit, onCancel }) => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();
  const [activeLanguage, setActiveLanguage] = useState(business?.businessDetails[0]?.languageCode ?? 'en');
  const formValuesRef = useRef(business?.businessDetails);

  useEffect(() => {
    form.resetFields();
  }, [activeLanguage]);

  const handleValueChange = (data) => {
    formValuesRef.current = formValuesRef.current.map((values) =>
      values?.languageCode === activeLanguage ? { ...values, ...data } : values,
    );
  };

  const newExternalValues = formValuesRef.current?.find((el) => el.languageCode === activeLanguage);

  const handleSaveForm = async () => {
    await onEdit({ businessDetails: formValuesRef.current }, 'businessDetails');
    onCancel();
  };

  return (
    <Col lg={24} span={24}>
      <Tabs destroyInactiveTabPane onChange={setActiveLanguage}>
        {business?.businessDetails.map((detail) => (
          <TabPane key={detail.languageCode} tab={getLanguageByCode(detail?.languageCode)?.name ?? 'English'}>
            <Form
              form={form}
              initialValues={newExternalValues}
              layout="vertical"
              style={{ width: '40%' }}
              onValuesChange={(_, formValues) => {
                handleValueChange(formValues);
              }}
              onFinish={handleSaveForm}>
              <Form.Item
                name="name"
                labelAlign="left"
                label={getText('name')}
                rules={[{ required: true, message: getText('fieldIsRequired') }]}>
                <Input type="text" placeholder={getText('enterMerchantName')} />
              </Form.Item>
              <Form.Item
                name="description"
                labelAlign="left"
                label={getText('description')}
                rules={[
                  { required: true, message: getText('fieldIsRequired') },
                  { max: 40000, message: getText('descriptionShorterThanSymbolsCount', { count: 40000 }) },
                ]}>
                <Input.TextArea type="text" placeholder={getText('pleaseDescribeYourBusiness')} />
              </Form.Item>
              <FormEditButtons onCancel={onCancel} />
            </Form>
          </TabPane>
        ))}
      </Tabs>
    </Col>
  );
};

BusinessMainInformationEdit.propTypes = {
  business: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default BusinessMainInformationEdit;
