import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, Tooltip } from 'antd';
import ReactPlayer from 'react-player';
import VideoThumbnail from 'react-video-thumbnail';
import { useTranslations } from '@veraio/strank';
import { CheckButton, useDeepEffect, CommentModal } from 'components';
import { ImageGalleryPanel, Icon } from 'components/ui';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import useError from 'services/errorHandling/useError';
import { removeMedia, updateDealImageVisibility } from 'services/api/mediaService';

import { merchantFileType } from 'enums';
import UploadFiles from '../../UploadFiles';
import {
  galleryModal,
  video,
  youTubePlayIcon,
  dealImages,
  dealImgContainer,
} from './styles';


export const DealImagesTab = ({ media, dealId }) => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [images, setImages] = useState(media);
  const [imagesForPreview, setImagesForPreview] = useState([]);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const imageToDeleteRef = useRef();

  useDeepEffect(() => {
    getMapImagesForPreview();
  }, [images]);

  const getMapImagesForPreview = () => {
    const newMedia = images.map((item) => {
      if (item.typeId === MediaTypesEnum.Link.id) {
        return {
          renderItem: () => renderVideo(`www.youtube.com/watch?v=${item.url}`),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      if (item.typeId === MediaTypesEnum.Video.id) {
        return {
          renderItem: () => renderVideo(item.url),
          renderThumbInner: () => renderThumbnail(item.url),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      return {
        thumbnail: item.thumbnailUrl || item.url,
        original: item.url,
      };
    });
    setImagesForPreview(newMedia);
  };

  const handleOpenDeleteModal = (img) => {
    imageToDeleteRef.current = img.id;
    setIsCommentModalVisible(true);
  };

  const handleRemoveImage = async (reason) => {
    const [, err] = await removeMedia(imageToDeleteRef.current, reason);
    if (err) return setError(err);

    setIsCommentModalVisible(false);
    setImages((prev) => prev.filter((img) => img?.id !== imageToDeleteRef.current));
  };

  const handleVisibilityChange = async (img) => {
    const [, err] = await updateDealImageVisibility(img?.id, !img?.isHidden);
    if (err) setError(err);

    setImages((prev) => prev.map((imgEl) => (imgEl.id === img.id ? { ...imgEl, isHidden: !img.isHidden } : imgEl)));
  };

  const handleUpload = (newImage) => {
    setImages((prevImages) => [...prevImages, newImage]);
  };

  const renderVideo = (url) => <ReactPlayer controls css={video} url={url} />;

  const renderThumbnail = (url) => (
    <div css={youTubePlayIcon}>
      <Icon iconName="las la-play" />
      <VideoThumbnail height={60} width={90} videoUrl={url} />
    </div>
  );

  return !images?.length ? (
    <Spin size="large" />
  ) : (
    <div>
      <UploadFiles
        updateList={handleUpload}
        mediaFiles={images ?? []}
        allowedFormat={merchantFileType.allowedFormat}
        dealId={dealId}
      />
      <div css={dealImages}>
        {images.map((img) => (
          <div key={img.id} css={dealImgContainer(img.isHidden)}>
            <div className="overlay">
              <Icon
                iconName="las la-eye"
                size="1.3rem"
                onClick={() => {
                  const imgIndex = images.map((image) => image.id).indexOf(img.id);
                  setSelectedImgIndex(imgIndex > -1 ? imgIndex : 0);
                  setIsPreviewModalVisible(true);
                }}
              />
              <Icon iconName="las la-trash" size="1.3rem" onClick={() => handleOpenDeleteModal(img)} />
            </div>
            <img src={img.url} alt="deal-img" />
            <CheckButton checked={!img.isHidden} onChange={() => handleVisibilityChange(img)}>
              <span className="text-label">{getText('visibleOnPublicPage')}</span>
              <Tooltip title={getText('hiddenImageHelp')} placement="right">
                <Icon iconName="las la-info-circle" size="1.3rem" />
                {/* in order to trigger the tooltip, the icon should be followed by a space character  */}
                &nbsp;
              </Tooltip>
            </CheckButton>
          </div>
        ))}
        <Modal
          centered
          width={1300}
          css={galleryModal}
          footer={null}
          open={isPreviewModalVisible}
          destroyOnClose
          onCancel={() => setIsPreviewModalVisible(false)}>
          <ImageGalleryPanel showNav startIndex={selectedImgIndex} items={imagesForPreview} />
        </Modal>
        <CommentModal
          title={getText('removePhoto')}
          isVisible={isCommentModalVisible}
          onCancel={() => setIsCommentModalVisible(false)}
          onAccept={handleRemoveImage}
        />
      </div>
    </div>
  );
};

DealImagesTab.propTypes = { media: PropTypes.array, reloadData: PropTypes.func, dealId: PropTypes.number };