import PropTypes from 'prop-types';
import { Input } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { feesContainer, inputStyle, actionBtn } from './styles';

export const FeeBox = ({ fee, currency, onChangeFee, onSaveFee, feeName }) => {
  const { getText } = useTranslations();
  const { setError } = useError();

  return (
    <div css={feesContainer}>
      <Input
        css={inputStyle}
        suffix="%"
        type="number"
        value={fee}
        onChange={(e) => onChangeFee(feeName, e.target.value)}
      />
      <div className="total flex space-between" style={{ marginTop: '0.5em' }}>
        <div>
          <span style={{ marginRight: '0.5em' }}>{getText('totalFee')}</span>
          <span className="currency">{currency}</span>
        </div>
        <Button
          type="secondary"
          small
          css={actionBtn(fee ? 'active' : 'inactive')}
          onClick={() => onSaveFee(fee, setError)}>
          {getText('change')}
        </Button>
      </div>
    </div>
  );
};

FeeBox.propTypes = {
  fee: PropTypes.number,
  feeName: PropTypes.string,
  currency: PropTypes.string,
  onChangeFee: PropTypes.func,
  onSaveFee: PropTypes.func,
};
