import { theme } from 'styles';

export const dealDescription = {
  fontSize: '1rem',
  lineHeight: 25,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
};

export const dealTitle = {
  fontSize: '1.125rem',
  lineHeight: 24,
  fontWeight: 500,
};

export const businessName = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '0.25em',
};

export const mr12 = {
  marginRight: '0.75em',
};

export const couponIcon = (isMobile, isVertical) => ({
  display: 'inline-block',
  fontSize: '1.125rem',
  lineHeight: 1,
  verticalAlign: 'unset',
  marginRight: '0.375em',
  marginLeft: !isMobile && !isVertical && '0.625em',
});

export const thumbnailContainer = {
  position: 'relative',
  height: '100%',
  paddingBottom: '57%',
  overflow: 'hidden',
  backgroundColor: theme.gray500,
  marginRight: '1.5em',
  borderRadius: 4,
  width: '100%',
};

export const thumbnail = {
  width: '100%',
  height: 'auto',
  position: 'absolute',
  top: '-9999px',
  bottom: '-9999px',
  left: '-9999px',
  right: '-9999px',
  margin: 'auto',
};

export const favoriteIcon = {
  position: 'absolute',
  bottom: '0.25rem',
  right: '0.25rem',
  color: theme.white,
  fontSize: '1.1875rem',
};

export const link = { width: '100%', marginBottom: '1.875em' };

export const dealRatingWrapper = {
  borderLeft: `1px solid ${theme.gray500}`,
  borderRight: `1px solid ${theme.gray500}`,
  paddingLeft: '0.5em',
  paddingRight: '0.75em',
};

export const starRatings = {
  '&.ant-rate': {
    fontSize: '1rem',
    color: theme.gray100,
  },
};

export const ratingsCountLabel = {
  fontSize: '0.8125rem',
  color: theme.gray200,
  textDecoration: 'underline',
};

export const dealEndDateWrapper = (isVertical) => ({
  '.la-clock': {
    fontSize: '1rem',
    lineHeight: '16px',
    marginRight: !isVertical && '0.5em',
  },
  '& .delivery-icon': {
    marginRight: !isVertical && '0.25em',
  },
  '& .la-question-circle': {
    fontSize: '0.875rem',
    marginLeft: !isVertical && '0.1875em',
  },
});

export const verticalStyleWrapper = {
  '& h2': {
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  '& label': {
    fontSize: '0.75rem',
  },
  '& p': {
    marginBottom: 0,
    fontSize: '0.875rem',
  },
};

export const couponInfoWrapper = (isMobile, isVertical) => ({
  marginTop: (isMobile || isVertical) && '0.5em',
  marginBottom: (isMobile || isVertical) && '0.5em',
});
