import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import queryString from 'query-string';
import { isNumber, pick } from '@veraio/core';

export const getOrdersListParams = (options) => {
  const {
    merchantCountryId,
    merchantEmail,
    merchantName,
    buyerCountryId,
    buyerEmail,
    dateFrom,
    dateTo,
    orderId,
    statusIds
  } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(merchantCountryId) && { merchantCountryId }),
      ...(merchantEmail?.length && { merchantEmail }),
      ...(merchantName?.length && { merchantName }),
      ...(isNumber(buyerCountryId) && { buyerCountryId }),
      ...(buyerEmail?.length && { buyerEmail }),
      ...(isNumber(orderId) && { orderId }),
      ...(dateFrom?.length && { dateFrom }),
      ...(dateTo?.length && { dateTo }),
      ...(statusIds?.length && { statusIds })
    },
    { arrayFormat: 'index' },
  );
};

export const ordersListModel = (res) => ({
  ...res,
  items: res.items.map((el) => ({ ...el, orderItems: el.orderItems.map(calculateDealPrice) })),
});
