export const categoriesSettingsContainer = {
  margin: '0 auto',
  padding: '0 1rem',
};

export const topBar = {
  display: 'flex',
  '& > h2': {
    width: '100%',
    marginRight: 'auto',
  },
};

export const topBarActions = {};

export const rootTitle = {
  textTransform: 'uppercase',
  marginBottom: '1rem',
  marginTop: '2rem',
};

export const modalActions = {
  display: 'flex',
  marginTop: '1.5em',
};

export const daysInput = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
};
