import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { DEFAULT_CRYPTO_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { Tag } from 'components/UIExternal';
import { useOrders, selectOrder } from 'stores';
import { orderStatusColor, orderStatusInverse, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { formatDefaultDate } from 'utils';
import { orderCardContainer } from './styles';
import { PriceLabel } from 'components/shared';

export const OrderCard = ({ order }) => {
  const orderId = useOrders((ordersState) => ordersState?.selectedOrder?.id);
  const item = order?.orderItems.at(0);
  const { getText } = useTranslations();

  return (
    <Button type="link" small css={orderCardContainer(orderId === order?.id)} onClick={() => selectOrder(order)}>
      <section className="order-card-header-container">
        <div className="order-card-status-container">
          <h4>#{order?.id}</h4>
          <Tag type={orderStatusColor[order.statusId]} className="order-card-status">
            {getText(orderStatusInverse[order.statusId])}
          </Tag>
        </div>
        <div className="gray">
          <Icon iconName="las la-calendar" size="1rem" margin="0 0.3em 0 0" />
          <span>{formatDefaultDate(order?.createdDate)}</span>
        </div>
      </section>
      <section className="order-card-deals-container">
        <img src={item?.media} alt={item?.name} className="order-card-deals-image" />
        <div className="order-card-deals-title-container">
          <h5 className="order-card-deals-title">{item?.title}</h5>
          {order?.orderItems.length > 1 && (
            <p className="order-card-deals-counter gray">
              {getText('orderMoreDealsCount', { count: order?.orderItems?.length - 1 })}
            </p>
          )}
        </div>
      </section>

      <section className="order-card-footer-container">
        <div className="gray">
          <span>
            <PriceLabel
              fiat={order?.totalPriceFiat}
              fiatOriginCurrency={order?.currencyCode}
              fiatDesiredCurrency={order?.currencyCode}
              crypto={order?.totalPriceCrypto}
              cryptoDesiredCurrency={
                (PAYMENT_METHODS_CURRENCIES[order?.payment?.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY)?.code
              }
              fiatOnly={false}
            />
          </span>
        </div>
      </section>
    </Button>
  );
};

OrderCard.propTypes = {
  order: PropTypes.object,
};
