import { useState, useEffect } from 'react';
import Icon from './Icon';

const LoadFonts = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    !isLoaded && (
      <div style={{ visibility: 'hidden' }}>
        <Icon iconName="close" material />
        <Icon iconName="close" material outlined />
        <Icon iconName="close" material twoTone />
        <Icon iconName="close" material round />
        <Icon iconName="close" material sharp />
        <Icon iconName="las la-times" />
      </div>
    )
  );
};

export default LoadFonts;
