import PropTypes from 'prop-types';
import { Skeleton } from 'components/UIExternal';
import TransactionAmountsTotals from '../TransactionAmountsTotals';
import { transactionsTotalsContainer, transactionsTotalsPanel } from './styles';

const TransactionsTotalsPanel = ({ totalsData }) => {
  return totalsData && totalsData.length > 0 ? (
    <div css={transactionsTotalsPanel}>
      <div css={transactionsTotalsContainer}>
        {totalsData.map((d, i) => (
          <TransactionAmountsTotals key={i} fiat={d.fiat} one={d.one} oesp={d.oesp} title={d.title} />
        ))}
      </div>
    </div>
  ) : (
    <Skeleton />
  );
};

TransactionsTotalsPanel.propTypes = {
  totalsData: PropTypes.arrayOf(
    PropTypes.shape({
      one: PropTypes.number,
      oesp: PropTypes.number,
      fiat: PropTypes.number,
      fiatCurrency: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export default TransactionsTotalsPanel;
