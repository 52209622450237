import { theme } from 'styles';

export const configurationsContainer = {
  padding: '2em 3em',
  display: 'flex',
  gap: '2em',
  alignItems: 'flex-start',
  flexFlow: 'row wrap',
  '&.mobileVersion': {
    maxHeight: '18.75rem',
  },
};

export const countriesDropdown = {
  width: '24rem',
  margin: '1em 0 0 auto',
};

export const subtabsContainer = {
  '.ant-tabs-top': {
    '& > .ant-tabs-nav': {
      margin: '12px 0 0 -12px',

      '&:before': {
        border: 'none',
      },
    },

    '& .ant-tabs-tab': {
      color: theme.gray300,
      height: 30,
      borderRadius: 15,
      border: '1px solid transparent',
      padding: '0 12px',
      marginLeft: 0,
      marginRight: 12,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active': {
      background: theme.blue2,
      '.ant-tabs-tab-btn': {
        color: theme.white,
      },
    },
    '& .ant-tabs-tab:hover': {
      color: theme.white,
      background: theme.blue2,
    },
    '& .ant-tabs-ink-bar': {
      background: 'transparent',
    },
  },
};
