import { theme } from 'styles';

export const checkboxContainer = ({ isBold }) => ({
  fontSize: '0.875rem',
  color: theme.gray100,
  fontWeight: isBold ? 500 : 400,
  padding: '0.375em',
  '&:hover': {
    backgroundColor: theme.ui_hover_rgba,
  },
  '& .ant-checkbox + span': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  '& .ant-checkbox-inner': {
    border: `2px solid ${theme.gray500}`,
  },
  '& .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner':
    {
      border: `2px solid ${theme.gray500}`,
    },
  '& .ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: 'transparent',
  },
  '& .ant-checkbox-checked .ant-checkbox-inner::after': {
    borderColor: theme.green2,
  },
  '& .ant-checkbox-inner::after': {
    top: '48%',
    left: '20%',
  },
  '& .ant-checkbox-checked + span': {
    fontWeight: 500,
  },
  '& .checkbox-icon': {
    fontSize: '1.5rem',
    color: theme.gray200,
  },
});
