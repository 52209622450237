import { getReq, postReq, putReq, deleteReq } from 'services/axios/makeRequest';
import { getAllNotificationsParams, getAllNotificationsBody, getNotificationByIdBody } from '../models/notifications';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Notifications`;

export const createNotifications = (values) => postReq(`${baseUrl}/Create`, values);

export const getAllNotifications = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/List`, getAllNotificationsParams(options));
  return [res && getAllNotificationsBody(res), err];
};

export const getManualNotifications = async (options) => getReq(`${baseUrl}/ManualList`, options);

export const getNotificationById = async (notificationId) => {
  const [res, err] = await getReq(`${baseUrl}/Get/${notificationId}`);
  return [res && getNotificationByIdBody(res), err];
};

export const deleteNotification = (notificationId) => deleteReq(`${baseUrl}/Delete/${notificationId}`);

export const markNotificationAsSeen = (notificationIds) => putReq(`${baseUrl}/MarkAsSeen`, { notificationIds });

export const markNotificationAsRead = (notificationIds) => putReq(`${baseUrl}/MarkAsRead`, { notificationIds });
