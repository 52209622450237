import queryString from 'query-string';
import { isNil, pick } from 'lodash-es';

export const businessesListParams = (options) => {
  const {
    searchText,
    userName,
    ownerName,
    email,
    countryIds,
    statusIds,
    creationDateFrom,
    creationDateTo,
    modifiedDateFrom,
    modifiedDateTo,
  } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(searchText && searchText?.length && { searchText }),
      ...(userName && userName?.length && { userName }),
      ...(ownerName && ownerName?.length && { ownerName }),
      ...(email && email?.length && { email }),
      ...(!isNil(countryIds) && { countryIds }),
      ...(!isNil(statusIds) && { statusIds }),
      ...(!isNil(creationDateFrom) && { creationDateFrom }),
      ...(!isNil(creationDateTo) && { creationDateTo }),
      ...(!isNil(modifiedDateFrom) && { modifiedDateFrom }),
      ...(!isNil(modifiedDateTo) && { modifiedDateTo }),
    },
    { arrayFormat: 'index' },
  );
};
