import { theme } from 'styles';

export const chartContainer = {
  padding: 30,
};

export const chartTitle = {
  fontWeight: 500,
};

export const totalCountStyles = {
  fontSize: 22,
  fontWeight: 500,
  color: theme.gray100,
};

export const labelStyles = (labelColor) => ({
  width: 10,
  height: 10,
  display: 'inline-block',
  backgroundColor: labelColor,
  margin: '0 10px',
});

export const labelLegend = {
  textTransform: 'uppercase',
};

export const strikeThrough = {
  textDecoration: 'line-through',
};

export const titleContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 24,
  '& > span': {
    color: theme.whiteGrayLightest,
    marginRight: '0.75em',
  },

  '& > p': {
    backgroundColor: theme.success,
    color: theme.white,
    padding: '0.375em',
    borderRadius: 4,
    fontWeight: 600,
  },
};
