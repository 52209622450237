import { theme } from 'styles';

export const filesContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  paddingBottom: '1em',
};

export const uploadedFile = {
  marginRight: '1em',
  display: 'flex',
  flexDirection: 'column',
  '& div.overlay': {
    display: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  '& .file-name': {
    fontWeight: 500,
    fontSize: '1rem',
    maxWidth: '14rem',
  },
};

export const imgContainer = {
  position: 'relative',
  padding: 0,
  width: '14rem',
  height: '12.5rem',
  overflow: 'hidden',
  '&:hover div.overlay': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,
  },
};

export const imageStyles = {
  width: '100%',
  height: 'auto',
  position: 'absolute',
  top: 0,
};

export const popupContainer = {
  '& img': {
    display: 'block',
    margin: '0 auto',
  },
  '& .file-name': {
    fontSize: '1.125rem',
    fontWeight: 500,
    margin: 0,
  },
  '& .file-description': {
    fontSize: '1rem',
    color: theme.gray300,
    margin: 0,
  },
};

export const toggleHiddenText = {
  width: '15.625rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  transition: 'all .5s ease-in-out',
  '&:hover': {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
};

export const downLoadBtn = {
  fontSize: '1rem',
  maxHeight: '2.5rem',
  '& a': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .inPopup': {
    marginLeft: '0.375em',
  },
};
