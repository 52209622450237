import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Input } from 'antd';
import { isNil, mapValues } from 'lodash-es';
import { Button, useDeepEffect } from 'components/UIExternal';
import { DISTRIBUTION_FEE } from 'enums';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions, formErrorMessage } from '../styles';

export const DistributionFeeConfiguration = ({ data, onReset, onUpdate, hasSelectedCountry }) => {
  const [formError, setFormError] = useState(null);
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (e) => {
    setValues((prev) => {
      const newValues = { ...prev, [e?.target.getAttribute('name')]: e?.target.value };
      const isValid = Object.values(newValues).reduce((acc, el) => acc + Number(el), 0) === 100;
      setFormError(isValid ? null : getText('configMaxAllowedPercent'));
      return newValues;
    });
  };

  if (isNil(data)) return <FormBoxLoader number={5} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFeeSponsor')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_SPONSOR}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_SPONSOR]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFeeCm')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_COUNTRY_MANAGER}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_COUNTRY_MANAGER]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFeeAm')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_AREA_MANAGER}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_AREA_MANAGER]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFeeRg')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_REGIONAL_DIRECTOR}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_REGIONAL_DIRECTOR]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsForCompany')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_COMPANY}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_COMPANY]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFeeMarketingBudgetPlatform')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('additionalServices')}</span>
        <Input
          name={DISTRIBUTION_FEE.DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET}
          value={values?.[DISTRIBUTION_FEE.DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET]}
          onChange={handleChange}
        />
      </div>
      {formError && <p css={formErrorMessage}>{formError}</p>}
      <div css={submitActions}>
        <Button small type="danger" onClick={() => onReset()} disabled={!hasSelectedCountry}>
          {getText('reset')}
        </Button>
        <Button
          small
          type="secondary"
          onClick={() => onUpdate(mapValues(values, Number))}
          disabled={Boolean(formError)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

DistributionFeeConfiguration.propTypes = {
  data: PropTypes.object,
  hasSelectedCountry: PropTypes.bool,
  onUpdate: PropTypes.func,
  onReset: PropTypes.func,
};
