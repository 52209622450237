import { theme } from 'styles';

export const sectionTitle = {
  fontSize: '2.375rem',
  color: theme.gray100,
};

export const container = {
  padding: '0 2em',
};
