import { Col } from 'antd';
import PropTypes from 'prop-types';

const NestedCol = ({ children, totalWidth, lg, span, className, offset }) => {
  const ratio = 24 / totalWidth;
  const newLg = Math.round(lg * ratio);

  return (
    <Col css={className} lg={newLg} span={span} offset={offset}>
      {children}
    </Col>
  );
};

NestedCol.propTypes = {
  lg: PropTypes.number,
  span: PropTypes.number,
  offset: PropTypes.number,
  totalWidth: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default NestedCol;
