import { useState, useRef } from 'react';
import parseHtml from 'html-react-parser';
import { useTranslations } from '@veraio/strank';
import { Button, useDeepEffect } from 'components/UIExternal';
import { loadMoreTextContainer, loadMoreTextButton } from './styles';

export const LoadMoreText = ({ text, limit }) => {
  const [options, setOptions] = useState({ isExpanded: false, isExtended: false, height: '1rem', limit });
  const { getText } = useTranslations();
  const containerRef = useRef();

  useDeepEffect(() => {
    handleContainerSize();
  }, [containerRef]);

  // Show load more if the text is larger than 1 row
  // We need this JS because the text is dynamic html and it is with dynamic children
  // It can be just one string or array of elements with different html inside
  const handleContainerSize = () => {
    let firstChild = containerRef.current?.firstChild;

    // If the first child is not HTML element, but string apply the calculations to the root element
    if (firstChild?.nodeType === 3) firstChild = containerRef.current;

    const computedStyles = firstChild && window.getComputedStyle(firstChild);
    const heightString = computedStyles?.getPropertyValue && computedStyles.getPropertyValue('line-height');
    const height = parseInt(heightString.match(/[\d.]+/)[0], 10);

    setOptions((prev) => ({ ...prev, height, isExtended: containerRef.current?.scrollHeight > height }));
  };

  return (
    <>
      <span ref={containerRef} css={loadMoreTextContainer(options)}>
        {parseHtml(text)}
      </span>
      {options.isExtended && (
        <Button
          type="link"
          onClick={() => setOptions((prev) => ({ ...prev, isExpanded: !prev.isExpanded }))}
          css={loadMoreTextButton}>
          {options.isExpanded ? getText('showLess') : getText('loadMore')}
        </Button>
      )}
    </>
  );
};

export default LoadMoreText;
