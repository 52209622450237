import { keyframes } from '@emotion/react';

const lock = keyframes`
  50% {top:-4%;}
	100% {top:-6%;}
`;

const colors = keyframes`
  50% {transform:scale(1.1);}
  100% {color:#ca3074;}
`;

export const mainContainer = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#332851',

  h1: {
    paddingTop: '5.625em',
    lineHeight: 0.9,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '1.7s',

    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: '#f6c667',
      zIndex: '-1',
      textAlign: 'center',
      animation: `${lock} .2s ease-in-out forwards`,
      animationDelay: '1.5s',
    },
  },

  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '2s',
  },
};
