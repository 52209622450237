import { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { TabPane, Tabs } from 'components/ui';
import { Table, useUrlParams, Button } from 'components/UIExternal';
import { ManagersFilterBar, EditManagerPopup, ConfirmPopup } from 'components/screens';
import { PageHeader } from 'components/shared';
import { USER_TYPE, userTypeInverse } from 'enums';
import { setError, getAllManagers, deleteManager } from 'services';
import { managersColumns } from './config';
import { container, addManagerButton } from './styles';

const filterKeys = {
  manager: 'managerType',
  name: 'name',
  email: 'email',
  countryId: 'countryId',
};

const Managers = () => {
  const { getText } = useTranslations();
  const modalRef = useRef();
  const confirmModalRef = useRef();
  const tableRef = useRef();
  const { clearQueryParams } = useUrlParams();

  const handleDeleteManager = async (id) => {
    const [, err] = await deleteManager(id);
    err ? setError(err) : tableRef.current.refreshData();
    confirmModalRef.current.close();
  };

  const handleOpenEditManager = (managerType, data) => modalRef.current.open({ managerType, ...data });

  const tableProps = (managerType) => ({
    ref: tableRef,
    columns: managersColumns({
      managerType,
      onEdit: (data) => handleOpenEditManager(managerType, data),
      onDelete: (data) => confirmModalRef.current?.open({ managerType, ...data }),
    }),
    getDataMethod: (tableOptions) => getAllManagers({ ...tableOptions, managerType }),
    filterBar: ManagersFilterBar,
    filterKeys,
  });

  return (
    <div css={container}>
      <Tabs destroyInactiveTabPane onChange={() => clearQueryParams([...Object.keys(filterKeys), 'pageNumber'])}>
        <TabPane key={1} tab={getText('countryManagers')}>
          <PageHeader title={getText('countryManagers')} />
          <Button
            small
            type="secondary"
            css={addManagerButton}
            onClick={() => handleOpenEditManager(USER_TYPE.COUNTRY_MANAGER)}>
            {getText('addManagerType', { type: getText(userTypeInverse[USER_TYPE.COUNTRY_MANAGER]) })}
          </Button>
          <Table {...tableProps(USER_TYPE.COUNTRY_MANAGER)} />
        </TabPane>
        <TabPane key={2} tab={getText('areaManagers')}>
          <PageHeader title={getText('areaManagers')} />
          <Button
            small
            type="secondary"
            css={addManagerButton}
            onClick={() => handleOpenEditManager(USER_TYPE.AREA_MANAGER)}>
            {getText('addManagerType', { type: getText(userTypeInverse[USER_TYPE.AREA_MANAGER]) })}
          </Button>
          <Table {...tableProps(USER_TYPE.AREA_MANAGER)} />
        </TabPane>
        <TabPane key={3} tab={getText('regionalDirectors')}>
          <PageHeader title={getText('regionalDirectors')} />
          <Button
            small
            type="secondary"
            css={addManagerButton}
            onClick={() => handleOpenEditManager(USER_TYPE.REGIONAL_DIRECTOR)}>
            {getText('addManagerType', { type: getText(userTypeInverse[USER_TYPE.REGIONAL_DIRECTOR]) })}
          </Button>
          <Table {...tableProps(USER_TYPE.REGIONAL_DIRECTOR)} />
        </TabPane>
      </Tabs>
      <EditManagerPopup ref={modalRef} onSubmit={() => tableRef.current.refreshData()} />
      <ConfirmPopup ref={confirmModalRef} onSubmit={handleDeleteManager} />
    </div>
  );
};

export default Managers;
