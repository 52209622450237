import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Button, Modal } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { MediaTypesValidation } from 'enums/MediaTypesEnum';
import { mr, mt } from 'styles';
import {
  imageStyles,
  imgContainer,
  filesContainer,
  uploadedFile,
  popupContainer,
  downLoadBtn,
  toggleHiddenText,
} from './styles';
import { formatDefaultDateTime } from 'utils';


export const UploadedFiles = ({ media }) => {
  const [fileDetails, setFileDetails] = useState({ showPopUp: false });

  const downloadImage = async (file) => {
    const image = await fetch(file.url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadFile = (file) => {
    file.typeId === MediaTypesValidation.pdf.id && window.open(file.url);
    setFileDetails({
      thumbnailUrl: file.thumbnailUrl,
      name: file.name,
      description: file.description,
      url: file.url,
      showPopUp: file.typeId !== MediaTypesValidation.pdf.id,
    });
  };

  return (
    <div css={filesContainer}>
      {media?.map((file) => (
        <div css={uploadedFile} key={file.id}>
          <Button type="default" css={imgContainer} onClick={() => handleUploadFile(file)}>
            <div className="overlay">
              <Icon iconName="lar la-eye" size={40} />
            </div>
            {file.typeId === MediaTypesValidation.pdf.id ? (
              <Icon iconName="las la-file" size={100} />
            ) : (
              <img src={file.thumbnailUrl} alt="" css={imageStyles} />
            )}
          </Button>
          <p css={toggleHiddenText} className="file-name">
            {file.name}
          </p>
          <p css={toggleHiddenText}>{file.description}</p>
          <p css={toggleHiddenText}>{formatDefaultDateTime(file.createdDate)}</p>

          <Button type="secondary" fullWidth css={downLoadBtn} onClick={() => downloadImage(file)}>
            <Icon iconName="las la-download" size={20} css={mr({ lg: 5 })} />
            <span>{getText('download')}</span>
          </Button>
        </div>
      ))}

      <Modal show={fileDetails.showPopUp} onClose={() => setFileDetails((prev) => ({ ...prev, showPopUp: false }))}>
        <div css={popupContainer}>
          <img src={fileDetails.thumbnailUrl} alt="" />
          <div css={mt({ lg: 20 })} className="flex">
            <div css={mr({ lg: 10 })}>
              <p css={toggleHiddenText} className="file-name">
                {fileDetails.name}
              </p>
              <p css={toggleHiddenText} className="file-description">
                {fileDetails.description}
              </p>
            </div>
            <Button type="secondary" fullWidth css={downLoadBtn} onClick={() => downloadImage(fileDetails)}>
              <Icon iconName="las la-download" size={20} />
              <span className="inPopup">{getText('download')}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

UploadedFiles.propTypes = { media: PropTypes.array };
