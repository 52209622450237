const MediaType = {
  Image: {
    id: 1,
    value: 'Image',
    allowedTypes: ['image/png', 'image/jpeg'],
    allowedSize: 4194304,
  },
  Video: {
    id: 2,
    value: 'Video',
    allowedTypes: ['video/mp4', 'video/mpeg', 'video/quicktime'],
    allowedSize: 52428800,
  },
  Link: {
    id: 3,
    value: 'Link',
  },

  getById: (id) => {
    const key = Object.keys(MediaType).find((el) => MediaType[el].id === id);
    return key && MediaType[key];
  },
};

export const MediaTypesValidation = {
  image: {
    id: 1,
    name: 'Image',
  },
  pdf: {
    id: 4,
    name: 'PDF',
  },
};

export default MediaType;
