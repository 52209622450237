import { theme } from 'styles';

export const filterRow = {
  '& > *:not(:last-child)': {
    marginBottom: '1em',
  },
  '& .section-subtitle': { fontSize: 16, fontWeight: 500 },
  '& .section-subtitle.merchant': { fontSize: 16, fontWeight: 500 },
  '& .input-label': { fontSize: 13, fontWeight: 500 },
};

export const categoriesContainer = {
  border: `1px solid ${theme.gray500}`,
  boxSizing: 'border-box',
  boxShadow: `0px 2px 8px ${theme.gray_rgba}`,
  borderRadius: 3,
  maxHeight: 500,
  fontSize: '0.875rem',
  marginBottom: '1.875em',
};
