import { theme } from 'styles';
import { Tabs as AntDTabs } from 'antd';
const AntDTabPane = AntDTabs.TabPane;

export const tabsContainer = ({ useBlackUnderline }) => ({
  '&.ant-tabs-top': {
    '& > .ant-tabs-nav': {
      marginBottom: 0,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.gray100,
    },
    '& .ant-tabs-tab:hover': {
      color: theme.gray100,
    },
    '& .ant-tabs-tab': {
      color: theme.gray300,
    },
    '& .ant-tabs-ink-bar': {
      background: useBlackUnderline ? theme.gray100 : theme.primary_yellow,
    },
  },
});

export const Tabs = AntDTabs;
export const TabPane = AntDTabPane;
