import PropTypes from 'prop-types';
import { useTranslations, getLanguageByCode } from '@veraio/strank';
import { Col, Row } from 'antd';
import { TabPane, Tabs } from 'components/ui';
import { TextChanged } from 'components/UIExternal';

const BusinessMainInformationPreview = ({ business, textChange }) => {
  const { getText } = useTranslations();

  return (
    <Col lg={24} span={24}>
      <Tabs>
        {business?.businessDetails.map((detail) => (
          <TabPane key={detail.languageCode} tab={getLanguageByCode(detail?.languageCode)?.name ?? 'English'}>
            <Row>
              <Col lg={4} span={24}>
                <label>{getText('name')}</label>
              </Col>
              <Col lg={20} span={24}>
                <TextChanged {...textChange('businessDetails', 'name', detail.languageCode)} />
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('description')}</label>
              </Col>
              <Col lg={20} span={24}>
                <TextChanged {...textChange('businessDetails', 'description', detail.languageCode)} />
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </Col>
  );
};

BusinessMainInformationPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
  textChange: PropTypes.func,
};

export default BusinessMainInformationPreview;
