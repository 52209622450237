import { theme } from 'styles';

export const notificationDetailContainer = {
  padding: '0 2em',
  minWidth: 700,
};

export const breadcrumbsStyles = {
  marginTop: '2.5em',
  '& > li ': {
    display: 'flex',
    alignItems: 'center',
  },
};

export const notificationBreadcrumbs = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > span': {
    fontSize: '1.5rem',
    color: theme.gray200,
  },
};

export const backButtonStyle = {
  padding: '0.5em 1em 0.5em 0',
  '& > span': {
    fontSize: '2rem',
  },
};

export const titleStyle = {
  color: '#273B4A',
  fontSize: '2rem',
  marginBottom: 0,
  marginRight: '0.75em',
};

export const statusContainer = (isSend) => ({
  display: 'flex',
  backgroundColor: isSend ? theme.green2 : theme.red,
  color: theme.white,
  borderRadius: 3,
  height: 'fit-content',
  padding: '0.125em 0.75em ',
});

export const statusLabel = {
  fontSize: '0.6875rem',
  lineHeight: 1.5,
  textTransform: 'uppercase',
};

export const detailContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const contentContainer = {
  borderBottom: `1px solid ${theme.gray500}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2em 0 1em',

  '& > div': {
    marginRight: '3em',

    '& :last-of-type': {
      marginRight: 0,
    },
  },
  '& > p': {
    marginRight: '3em',

    '& :last-of-type': {
      marginRight: 0,
    },
  },
};

export const statusDotContainer = {
  display: 'flex !important',
  flexDirection: 'row',
  alignItems: 'center',

  '& > p': {
    marginBottom: 0,
  },
};

export const notificationIcon = {
  width: '2rem',
  boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
  borderRadius: '50%',
};
