import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, useDeepEffect } from 'components/UIExternal';
import { DEAL_FEE } from 'enums/settings';
import { useUser } from 'stores/contexts/User';
import { isCryptoBanned } from 'utils/cryptoBan';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const DealFeeConfiguration = ({ data, onUpdate, onReset, hasSelectedCountry }) => {
  const { geoLocation } = useUser();
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (e) => setValues((prev) => ({ ...prev, [e?.target.getAttribute('name')]: e?.target.value }));

  if (isNil(data)) return <FormBoxLoader number={6} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee09Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_0_9_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_0_9_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee1029Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_10_29_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_10_29_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee3049Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_30_49_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_30_49_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee5069Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_50_69_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_50_69_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee7089Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_70_89_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_70_89_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealsFee90100Percent', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={DEAL_FEE.WITH_90_100_PERCENT_CRYPTO}
          value={values?.[DEAL_FEE.WITH_90_100_PERCENT_CRYPTO]}
          onChange={handleChange}
        />
      </div>
      <div css={submitActions}>
        <Button small type="danger" onClick={() => onReset()} disabled={!hasSelectedCountry}>
          {getText('reset')}
        </Button>
        <Button small type="secondary" onClick={() => onUpdate(values)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

DealFeeConfiguration.propTypes = {
  data: PropTypes.object,
  hasSelectedCountry: PropTypes.bool,
  onUpdate: PropTypes.func,
  onReset: PropTypes.func,
};
