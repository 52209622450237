import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { isDesktop } from 'react-device-detect';
import DealSkeleton from '../DealSkeleton/DealSkeleton';
import { oneItemCarouselWrapper, dealContainer, inputWrapper } from './styles';

const CarouselSkeleton = ({ numberOfDealsPerSlide }) =>
  !numberOfDealsPerSlide ? (
    isDesktop ? (
      <div css={oneItemCarouselWrapper}>
        <div style={{ width: '48%' }}>
          <Skeleton.Image size="large" />
        </div>
        <div css={inputWrapper}>
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
        </div>
      </div>
    ) : (
      <div css={oneItemCarouselWrapper}>
        <DealSkeleton numberOfDeals={1} />
      </div>
    )
  ) : (
    <div css={dealContainer}>
      <DealSkeleton numberOfDeals={numberOfDealsPerSlide} />
    </div>
  );

CarouselSkeleton.propTypes = {
  numberOfDealsPerSlide: PropTypes.number,
};
export default CarouselSkeleton;
