import { paymentMethods } from './orders';

export default {
  AccountBalance: {
    id: 1,
    value: 'AccountBalance',
  },
  CashOnDelivery: {
    id: 2,
    value: 'CashOnDelivery',
  },
  Crypto: {
    id: 3,
    value: 'Crypto',
  },
};

export const isCashExternalPayment = (paymentMethod) => paymentMethod === paymentMethods.usdt;

export const EXTERNAL_PAYMENT_METHODS = {
  USDT: {
    name: 'USDT',
    code: 'USDT',
    precision: 5,
  },
};
