import { theme } from 'styles';

export const notificationTime = {
  opacity: 0.6,
  fontSize: '0.75rem',
};

export const notificationType = {
  fontSize: '0.75rem',
  marginRight: '0.5em',
};

export const notificationContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0.25em',
  width: '100%',
  cursor: 'pointer',

  '& *': {
    cursor: 'pointer',
  },

  '&:hover': {
    backgroundColor: theme.ui_hover_rgba,
  },

  '& .icon': {
    width: '2rem',
    marginRight: '1em',
  },

  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0.3125em',
    width: '100%',

    '& .titleTimeContainer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      fontWeight: 400,
      width: '100%',
      '& .la-dot-circle': {
        opacity: 0.6,
      },

      '& .title': {
        fontSize: '0.75rem',
        marginRight: '0.5em',
      },

      '& .time': {
        opacity: 0.6,
        fontSize: '0.75rem',
      },
    },
    '& .message': {
      fontSize: '0.875rem',
      width: '100%',
    },
  },
};
