import { isMobile } from 'react-device-detect';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import UserHeaderActions from './UserHeaderActions';
import { navigationContainer } from './styles';

const Header = ({ toggleNavigationDrawer, isVisible }) => (
  <nav css={navigationContainer}>
    <div className="content-container">
      {isMobile && !isVisible && (
        <Button type="info" onClick={toggleNavigationDrawer} small>
          <Icon iconName="las la-bars" color="black" />
        </Button>
      )}
      <UserHeaderActions />
    </div>
  </nav>
);

export default Header;
