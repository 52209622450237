export const block = {
  fontSize: '2rem',
  margin: '1.25em 0',
};

export const inputContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const submitActionButtons = {
  '& > *': {
    marginRight: '0.75em',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
};

export const countryDropdown = {
  width: '18.75rem',
  margin: 0,
};

export const deletePopUpText = {
  fontSize: '1.25rem',
  textAlign: 'center',
  marginBottom: '1.25em',
};

export const deleteActionButtons = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 3em',
};
