import { theme } from 'styles';

export const orderDetailsPaymentContainer = {
  '& .order-details-payment-info-row': {
    display: 'flex',
    alignItems: 'baseline',

    '&:last-child': { marginBottom: '2em' },

    '& .order-details-payment-info-label': {
      width: '9.5rem',
    },
  },
  '& .order-details-payment-sub-label': {
    fontSize: '0.9rem',
  },
  '& .order-details-payment-method-card': {
    background: theme.gray700,
    borderRadius: 8,
    padding: '1.5em',
    margin: '1em 0',
    display: 'flex',
    alignItems: 'center',
    '&:last-of-type': {
      marginBottom: 0,
    },

    '& .order-details-payment-method': {
      flex: 1,
    },
    '& .order-details-payment-method-avatar': {
      background: theme.white,
      padding: '0.3em',
      borderRadius: '50%',
      fontSize: '2rem',
      marginRight: '1rem',
    },
    '& .order-details-payment-price-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '& .order-details-payment-price': {
        marginBottom: '0.5em',
      },
    },
  },
  '& .order-details-payment-bank-details': {
    borderTop: `1px solid ${theme.gray500}`,
    marginTop: '1.5em',
    padding: '1.5em 0',

    '& .order-details-payment-bank-details-title': {
      marginBottom: '1em',
    },
  },

  '& .order-details-payment-crypto-headline': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    marginBottom: '1em',
  },

  '& .order-details-payment-crypto-details': {
    display: 'flex',
    gap: '3em',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',

    '& .order-details-payment-crypto-details-item': {
      maxWidth: 300,
    },
  },
};
