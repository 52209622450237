import moment from 'moment';

export const formatDefaultDate = (date) => moment(date).format('DD/MM/YYYY');

export const formatDefaultDateForDatePicker = (date) => moment(date).format('DD-MM-YYYY');

export const formatDefaultDateTime = (date) => moment(date).format('DD/MM/YYYY hh:mm');

export const formatTime = (date) => moment(date).format('hh:mm');

export const isValidDate = (date) =>
  new Date() < new Date(date) || new Date().toDateString() === new Date(date).toDateString();

export const isValidDaysPeriod = (start, end) => new Date(start) < new Date(end) || new Date(start) === new Date(end);

export const formatDescriptiveDateTime = date => moment(date).format('DD MMM YYYY hh:mm');
