import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Col } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { TextChanged } from 'components/UIExternal';
import { businessTypeInverse, businessType, businessesRiskLevel } from 'enums';
import { labelTitle } from './styles';

const BusinessAdditionalInformationPreview = ({ business, textChange }) => {
  const { getText } = useTranslations();
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  return (
    <>
      <Col lg={4} span={24}>
        <label>{getText('dealsRunOnDialShaker')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('intendedDeals')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('merchantType')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('typeId', null, null, (val) => getText(businessTypeInverse[val]))} />
      </Col>
      {business?.typeId === businessType?.business && (
        <>
          <Col lg={4} span={24}>
            <label>{getText('companyRegistrationNumber')}</label>
          </Col>
          <Col lg={20} span={24}>
            <TextChanged {...textChange('registrationNumber')} />
          </Col>
        </>
      )}
      {business?.typeId === businessType?.business && (
        <>
          <Col lg={4} span={24}>
            <label>{getText('vatNumber')}</label>
          </Col>
          <Col lg={20} span={24}>
            <TextChanged {...textChange('vatNumber')} />
          </Col>
        </>
      )}
      {isAdmin && (
        <>
          <Col lg={4} span={24}>
            <span css={labelTitle}>{getText('bvPointsAllowed')}</span>
          </Col>
          <Col lg={20} span={24}>
            <TextChanged
              {...textChange('allowsBVPoints', null, null, (val) => (val ? getText('yes') : getText('no')))}
            />
          </Col>
        </>
      )}
      <Col lg={4} span={24}>
        <label>{getText('currency')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('currencyCode')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('merchantRiskLevel')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('paymentClearingRisk', null, null, (val) => getText(businessesRiskLevel[val]))} />
      </Col>
    </>
  );
};

BusinessAdditionalInformationPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
  textChange: PropTypes.func,
};

export default BusinessAdditionalInformationPreview;
