import { theme } from 'styles';

export const subTitle = {
  display: 'inline-block',
  color: theme.background100,
  fontSize: '1rem',
  fontWeight: 700,
  marginBottom: '0.625em',
};

export const businessWrapper = {
  paddingLeft: '1em',
  borderBottom: `1px solid ${theme.gray500}`,
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
};
