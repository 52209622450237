import { getText } from '@veraio/strank';
import parseHtml from 'html-react-parser';
import moment from 'moment';
import { Tag } from 'components/UIExternal';
import { validDate } from 'utils/moment';
import { dealStatusColor, dealStatusInverse } from 'enums';

export const DEAL_STATUS_HISTORY_COLUMNS = () => [
  {
    name: getText('changedAt'),
    render: (deal, NullItem) =>
      validDate(deal?.createdDate) ? (
        <span>{moment(deal.createdDate).format('DD/MM/YYYY hh:mm:ss')}</span>
      ) : (
        <NullItem />
      ),
    width: 110,
  },
  {
    name: getText('changedBy'),
    value: 'createdBy',
    width: 180,
  },
  {
    name: getText('reason'),
    render: (deal, NullItem) => (deal?.reason ? parseHtml(deal.reason) : <NullItem />),
  },
  {
    name: getText('status'),
    render: (deal) => <Tag type={dealStatusColor[deal?.statusId]}>{getText(dealStatusInverse[deal?.statusId])}</Tag>,
    width: 100,
  },
];
