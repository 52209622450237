import { Button } from 'components/UIExternal';
import { actions, categoryTreeItem, title } from './styles';
import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';

const CategoryTreeItem = ({ category, onChangePeriod }) => {
	return <div data-id="category-settings-row" css={categoryTreeItem}>
		<div css={title}>{category.name}</div>
		<div css={actions}>
			<strong>{category.clearingDays} {getText('days')}</strong>
			<Button type="link" small onClick={() => onChangePeriod(category)}>
				<Icon iconName="las la-pen" />
			</Button>
		</div>
	</div>;
};

export default CategoryTreeItem;