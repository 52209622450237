import { OrderDetailsHeader } from './Header';
import { OrderDetailsUsers } from './Users';
import { OrderDetailsDeals } from './Deals';
import { OrderDetailsDelivery } from './Delivery';
import { OrderDetailsPayment } from './Payment';
import { orderDetailsContainer } from './styles';

export const OrderDetails = () => (
  <section css={orderDetailsContainer}>
    <OrderDetailsHeader />
    <hr />
    <OrderDetailsUsers />
    <OrderDetailsDeals />
    <OrderDetailsPayment />
    <OrderDetailsDelivery />
  </section>
);
