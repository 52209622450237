export const container = {
  padding: '0 32px',
  '& .header': { marginTop: 40 },
  '& .filterCollapse': {
    borderRadius: 28,
    width: '100%',
    border: 'none',
    borderBottom: ' none',
    marginBottom: 15,
  },
};

export const addManagerButton = {
  display: 'block',
  margin: '0 0 1em auto',
};
