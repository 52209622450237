import { theme } from 'styles';

export const pageContainer = {
  width: '100% !important',
  zIndex: 100,
  padding: '0 2em',

  '& .header': { marginTop: '2.5em' },

  '& > *': {
    marginBottom: '2.5em',
  },
};

export const textLinkStyle = {
  color: theme.primary_blue,
  whiteSpace: 'normal',
  padding: 0,
  '&:hover': {
    borderColor: 'transparent',
    color: theme.primary_blue,
  },
};

export const actionStyle = {
  display: 'flex',
  color: `${theme.primary_blue} !important`,
  fontSize: '1.25rem !important',
  padding: 0,

  '&:hover': {
    borderColor: 'transparent',
    opacity: 1,
    color: theme.primary_blue,
  },
  '& :focus ': { color: theme.primary_blue },
};
