export const loadMoreTextContainer = ({ isExpanded, height, limit }) => [
  {
    overflow: 'hidden',
    display: 'inline-grid',
    height: !isExpanded ? height : 'auto',

    '& > :first-of-type': {
      maxWidth: isExpanded ? 'auto' : limit ?? 270,
    },
  },
];

export const loadMoreTextButton = {
  color: 'inherit',
  padding: 0,
  fontWeight: 600,
  border: 'none',
  minHeight: 0,
  position: 'relative',
  marginLeft: '0.5em',
};
