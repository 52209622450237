import { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Modal } from 'components/UIExternal';
import { userTypeInverse } from 'enums';
import { actionButtons } from './styles';

export const ConfirmPopup = forwardRef(({ onSubmit }, ref) => {
  const { getText } = useTranslations();
  const modalRef = useRef();
  const [manager, setManager] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setManager(data);
      modalRef.current.open();
    },
    close: () => {
      modalRef.current.close();
    },
  }));

  return (
    <Modal ref={modalRef} modalTitle={getText('areYouSure')}>
      <h5>
        {getText('deleteManagerInfo', { name: manager?.name, type: getText(userTypeInverse[manager?.managerType]) })}
      </h5>
      <div css={actionButtons}>
        <Button small type="success" onClick={() => onSubmit(manager.id)}>
          {getText('apply')}
        </Button>
        <Button small type="danger" onClick={() => modalRef.current.close()}>
          {getText('cancel')}
        </Button>
      </div>
    </Modal>
  );
});

ConfirmPopup.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
};
