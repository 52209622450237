import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import Routes from 'enums/Routes';
import ErrorImg from 'assets/images/errorPages/page-not-found-404.png';
import { mainContainer } from './styles';

const NotFound = () => {
  const { getText } = useTranslations();

  return (
    <div css={mainContainer} className="content-container flex-column flex-center">
      <img src={ErrorImg} alt="404 img" />
      <h2>{getText('pageDoesNotExist')}</h2>
      <p>{getText('pageNotFound')}</p>
      <Button type="primary" small linkTo={Routes.HomePage}>
        {getText('goHome')}
      </Button>
    </div>
  );
};

export default NotFound;
