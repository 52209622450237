import { useTranslations } from '@veraio/strank';
import { Tabs } from 'components/ui';
import { container } from './styles';
import { Route, useHistory } from 'react-router-dom';
import Routes from 'enums/Routes';
import { PageHeader } from 'components/shared';
import { SettlementTab, ClearingTab, BundledTransactions, TransactionsTab } from './Tabs';
import { getPaymentPendingAmounts, setError } from 'services';
import { useEffect, useState } from 'react';
import TransactionDetails from '../TransactionDetails';

const tabs = { clearing: 'clearing', settlement: 'settlement', list: 'transactions' };

const Transactions = () => {
	const { getText } = useTranslations();
	const history = useHistory();
	const [pendingClearingSettlementAmounts, setPendingClearingSettlementAmounts] = useState({});
	const locationPath = window.location.pathname.split('/').pop();
	const handleTabChange = tab => history.push(`${Routes.Transactions}/${tab}`);

	const fetchMyClearingSettlementPendingAmounts = async () => {
		const [res, err] = await getPaymentPendingAmounts();
		if (err) return setError(err);

		setPendingClearingSettlementAmounts(res);
	};

	useEffect(() => {
		fetchMyClearingSettlementPendingAmounts();
	}, []);

	const tabItems = [
		{
			key: tabs.list,
			label: getText(tabs.list),
			children: <TransactionsTab />
		},
		{
			key: tabs.clearing,
			label: getText(tabs.clearing),
			children: <ClearingTab pendingClearingSettlementAmounts={pendingClearingSettlementAmounts} />
		},
		{
			key: tabs.settlement,
			label: getText(tabs.settlement),
			children: <SettlementTab pendingClearingSettlementAmounts={pendingClearingSettlementAmounts} />
		},
	];

	return (
		<div css={container}>
			<PageHeader title={getText('Financials')} />
			<Tabs
				destroyInactiveTabPane
				defaultActiveKey={tabs.list}
				activeKey={locationPath}
				onChange={key => handleTabChange(key)}
				items={tabItems}
			/>
			<Route exact path={Routes.TransactionDetails} component={TransactionDetails} />
			<Route exact path={Routes.TransactionBundledTransactions} component={BundledTransactions} />
		</div >
	);
};

export default Transactions;
