/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction, isString } from 'lodash-es';
import { Spin } from 'antd';
import { Icon } from '../../ui';
import { useIsMounted } from '../hooks';
import { buttonContainer, spinnerBtn } from './styles';

const externalLinkExpression =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;

const Button = (props) => {
  const {
    children,
    onClick,
    disabled,
    leftIcon,
    rightIcon,
    preventDefault,
    stopPropagation,
    linkTo,
    loading: loadingProp = false,
    target = '_blank',
    className,
  } = props;
  const [loading, setLoading] = useState(loadingProp);
  const buttonStyles = buttonContainer(props, loading);
  const isMounted = useIsMounted();

  useEffect(() => {
    loadingProp !== loading && setLoading(loadingProp);
  }, [loadingProp]);

  const handleClick = async (e) => {
    if (disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    stopPropagation && e.stopPropagation();
    if (isFunction(onClick)) {
      setLoading(true);
      await onClick(e);
      isMounted.current && setLoading(false);
    }
  };

  return linkTo ? (
    externalLinkExpression.test(linkTo) ? (
      <a
        href={linkTo}
        css={buttonStyles}
        {...(isString(className) && { className })}
        target={target}
        rel="noreferrer"
        onClick={handleClick}>
        {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
      </a>
    ) : (
      <Link to={linkTo} css={buttonStyles} {...(isString(className) && { className })} onClick={handleClick}>
        {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
      </Link>
    )
  ) : (
    <button onClick={handleClick} css={buttonStyles} {...(isString(className) && { className })}>
      {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
      {children}
      {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
      <Spin spinning={loading} size="small" css={spinnerBtn} />
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['primary', 'secondary', 'default', 'success', 'warning', 'info', 'danger', 'link']),
  outline: PropTypes.bool,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  color: PropTypes.string,
  backColor: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  target: PropTypes.string,
};

export default Button;
