import { theme } from 'styles';

export const treeItem = (level) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: `${level}rem`,
  marginBottom: '0.5rem',
  paddingBottom: '0.5rem',
  borderBottom: `1px solid ${theme.gray500}`,
});

export const expandCollapseButton = {
  width: '30px',
  height: '30px',
  borderRadius: '15px',
  textAlign: 'center',
  background: theme.gray900,
  marginRight: '10px',

  '& > i': {
    margin: 0,
  },
};

export const noChildItem = {
  paddingLeft: '50px',
  width: '100%',
};

export const withChildItem = {
  width: '100%',
};
