import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import Routes from 'enums/Routes';
import Header from 'components/layouts/Header';
import NavigationPanel from 'components/layouts/NavigationPanel';
import HomePage from './HomePage';
import Deals from './Deals';
import { Orders } from './Orders';
import Businesses from './Businesses';
import Addresses from './Addresses';
// REMOVE TAG: Hide Refunds
// import Refunds from './Refunds';
import Managers from './Managers';
import Disputes from './Disputes';
import DealDetail from './DealDetailPage';
import DisputeDetail from './DisputeDetailPage';
import RefundDetail from './RefundDetailPage';
import AddressDetail from './AddressDetailPage';
import BusinessDetail from './BusinessDetailPage';
import { Messages, StartChat, OngoingChat } from './Messages';
import Settings from './Settings';
import { Notifications, CreateNotification, NotificationDetails } from './Notifications';
import Statistics from './Statistics';
import Transactions from './Transactions';
import NotFound from '../Public/NotFound';
import { appContainer, mainContainer, contentContainer } from './styles';

const PrivateRoutes = () => {
  const [isVisible, setIsVisible] = useState(!isMobile);
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  const toggleNavigationDrawer = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section css={appContainer}>
      <NavigationPanel toggleNavigationDrawer={toggleNavigationDrawer} isVisible={isVisible} />
      <section css={mainContainer}>
        <Header toggleNavigationDrawer={toggleNavigationDrawer} isVisible={isVisible} />
        <main css={contentContainer}>
          <Switch>
            <Route exact path={Routes.HomePage} component={HomePage} />
            <Route exact path={Routes.Deals} component={Deals} />
            <Route exact path={Routes.Orders} component={Orders} />
            <Route exact path={Routes.Businesses} component={Businesses} />
            <Route exact path={Routes.Addresses} component={Addresses} />
            {/* REMOVE TAG: Hide Refunds
            <Route exact path={Routes.Refunds} component={Refunds} /> */}
            <Route exact path={Routes.Disputes} component={Disputes} />
            {isAdmin && <Route exact path={Routes.Managers} component={Managers} />}
            <Route exact path={Routes.Statistics} component={Statistics} />
            <Route exact path={Routes.DealDetail} component={DealDetail} />
            <Route exact path={Routes.DisputeDetail} component={DisputeDetail} />
            <Route exact path={Routes.RefundDetail} component={RefundDetail} />
            <Route exact path={Routes.AddressDetail} component={AddressDetail} />
            <Route exact path={Routes.BusinessDetail} component={BusinessDetail} />
            <Route exact path={Routes.Messages} component={Messages} />
            <Route exact path={Routes.StartChat} component={StartChat} />
            <Route exact path={Routes.OngoingChat} component={OngoingChat} />
            {isAdmin && <Route path={Routes.Settings} component={Settings} />}
            <Route exact path={Routes.Notifications} component={Notifications} />
            <Route exact path={Routes.CreateNotification} component={CreateNotification} />
            <Route exact path={Routes.NotificationDetail} component={NotificationDetails} />
            <Route path={Routes.Transactions} component={Transactions} />
            <Route component={NotFound} />
          </Switch>
        </main>
      </section>
    </section>
  );
};

export default PrivateRoutes;
