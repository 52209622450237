import { getReq, postReq, putReq, patchReq } from 'services/axios/makeRequest';
import { businessesListParams } from 'services/models/businesses';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Businesses`;

export const getAllBusinesses = (options) => getReq(`${baseUrl}/List?${businessesListParams(options)}`);

export const getBusinessById = (businessId) => getReq(`${baseUrl}/Get/${businessId}`);

export const updateBusinessDetails = (id, options) => putReq(`${baseUrl}/Update/${id}`, options);

export const updateBusinessStatus = (data) => patchReq(`${baseUrl}/UpdateStatus`, data);

export const addCommentToBusiness = (data) => postReq(`${baseUrl}/Comment`, data);
