import moment from 'moment';

export const defaultDate = '0001-01-01T00:00:00';

export const dateTimeFormat = (date, format) => moment(date).format(format);

export const validDate = (date) => (date === defaultDate ? null : date);

export const removeTimeZone = (date) => date.format('YYYY-MM-DDTHH:mm:ss');

export const endOfTheDay = (inputDate) => inputDate.hours(23).minutes(59).seconds(59);

export const convertUTCDateToLocalDate = (date) => {
  let temp = date;
  temp = temp.replace(/Z$/, '');
  if (temp) {
    const tempDate = new Date(temp);
    const newDate = new Date(tempDate.getTime() + tempDate.getTimezoneOffset() * 60 * 1000);
    const offset = tempDate.getTimezoneOffset() / 60;
    const hours = tempDate.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
};

export const getTimeFromNow = (inputDate) => moment(convertUTCDateToLocalDate(inputDate)).fromNow();

export const mergeDateTime = (date, time) => {
  if (!moment.isMoment(date) || !moment.isMoment(time)) return;

  return moment(date).set({ hour: time.hour(), minute: time.minute(), second: time.second() });
};
