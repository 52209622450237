import { getText } from '@veraio/strank';
import { Collapse } from 'antd';
import { Dropdown, useUrlParams } from 'components/UIExternal';
import { DatePicker, TextInput } from 'components/ui';
import { PAYMENT_PROVIDERS_SELECT } from 'enums';
import { pageFilterCollapse } from 'styles';

export const TransactionsFilterBar = ({ paginationRef, filterKeys }) => {
  const { queryParams } = useUrlParams();

  const handleChange = (keyValuePair) => paginationRef.current?.applyFilters(keyValuePair);

  return (
    <>
      <Collapse css={pageFilterCollapse}>
        <Collapse.Panel header={getText('filters')}>
          <section className="filters-container">
            <div className="flex-auto">
              <h5>{getText('paymentDate')}</h5>
              <div className="filters-date-pickers">
                <div>
                  <label>{getText('from')}</label>
                  <DatePicker
                    onChange={(value) => handleChange({ [filterKeys.creationDateFrom]: value || null })}
                    defaultValue={queryParams[filterKeys.creationDateFrom]}
                  />
                </div>
                <div>
                  <label>{getText('to')}</label>
                  <DatePicker
                    onChange={(value) => handleChange({ [filterKeys.creationDateTo]: value || null })}
                    defaultValue={queryParams[filterKeys.creationDateTo]}
                  />
                </div>
              </div>
            </div>

            <div>
              <label>{getText('merchantEmail')}</label>
              <TextInput
                showDelete
                onSearch={(value) => handleChange({ [filterKeys.merchantEmail]: value })}
                placeholder={getText('searchByEmail')}
                defaultValue={queryParams[filterKeys.merchantEmail]}
              />
            </div>
            <div>
              <label>{getText('buyerEmail')}</label>
              <TextInput
                showDelete
                onSearch={(value) => handleChange({ [filterKeys.buyerEmail]: value })}
                placeholder={getText('searchByEmail')}
                defaultValue={queryParams[filterKeys.buyerEmail]}
              />
            </div>
            <div>
              <label>{getText('orderId')}</label>
              <TextInput
                showDelete
                placeholder={getText('searchByOrderId')}
                onSearch={(value) => handleChange({ [filterKeys.orderId]: value })}
                defaultValue={queryParams[filterKeys.orderId]}
              />
            </div>
            <div>
              <label>{getText('externalTransactionId')}</label>
              <TextInput
                showDelete
                placeholder={getText('searchByExternalTransactionId')}
                onSearch={(value) => handleChange({ [filterKeys.externalTransactionId]: value })}
                defaultValue={queryParams[filterKeys.externalTransactionId]}
              />
            </div>
            <div>
              <label>{getText('paymentProvider')}</label>
              <Dropdown
                small
                placeholder={getText('paymentProvider')}
                options={PAYMENT_PROVIDERS_SELECT()}
                value={Number(queryParams[filterKeys.paymentProviderId])}
                onChange={(selection) => handleChange({ [filterKeys.paymentProviderId]: selection?.value })}
              />
            </div>
          </section>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
