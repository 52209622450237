export const commentsListContainer = {
  listStyleType: 'none',
  maxHeight: 100,
  overflow: 'hidden',
  scrollbarWidth: 'thin',
  position: 'relative',
  overflowY: 'scroll',
  '& :where(ul, ol)': {
    paddingLeft: '1em',
  },
  '& a': {
    color: '#1890ff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
