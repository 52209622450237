import { theme } from 'styles';

export const spinner = {
  margin: '1.875em 1.875em !important',
};

export const navigationWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  alignItems: 'center',

  '.specs-container': {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      paddingLeft: '0',
      paddingRight: '0.5em',
    },

    '& .deal-name': {
      fontSize: '1.375rem',
      fontWeight: 500,
      color: theme.background100,
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      marginRight: '0.625em',
    },
  },

  '.actions-container': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
    marginBottom: '1em',
  },

  '& .decline-btn': {
    color: '#F73C25 !important',
  },

  '& .la-arrow-left': {
    fontSize: '1.375rem',
    color: theme.background100,
    marginTop: '-0.25em',
  },
};

export const userDetailsWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  paddingBottom: '0.75em',
  '& span': {
    color: theme.blue2,
    marginRight: '0.625em',
  },
  '& label': {
    color: theme.gray100,
  },
  [theme.Desktop]: {
    paddingLeft: '0.9375em',
  },
};

export const userDetailsLine = {
  marginBottom: '1em',
  marginTop: '1em',
  padding: '2em 0 0',
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: 0,
    borderBottom: `1px solid ${theme.gray400}`,
    borderLeft: '14rem solid transparent',
    borderRight: '14rem solid transparent',
    content: '""',
    height: 1,
    width: '100%',
  },
  [theme.Desktop]: {
    borderBottom: `1px solid ${theme.gray500}`,
  },
};

export const subtitle = {
  display: 'inline-block',
  fontSize: '1rem',
  color: theme.background100,
  marginBottom: '1.875em',
  [theme.Mobile]: {
    marginBottom: '0.9375em',
  },
};

export const avatar = {
  width: '2.1875rem',
  borderRadius: 50,
  marginTop: '0.3125em',
};

export const nameDateWrapper = {
  '& p': {
    marginBottom: 0,
    fontSize: '1rem',
    color: theme.gray100,
  },
  '& label': {
    color: theme.gray200,
  },
};

export const descriptionWrapper = {
  '& label': {
    display: 'inline-block',
    marginBottom: 4,
    color: theme.blue2,
    fontWeight: 500,
    [theme.Mobile]: {
      marginTop: '0.625em',
    },
  },
  '& p': {
    marginBottom: 0,
  },
};

export const responsiveImg = {
  border: 'none',
  background: 'transparent',
  padding: 0,
  marginTop: 8,
  width: '2.6875rem',
  height: '1.5rem',
  overflow: 'hidden',
  borderRadius: 4,
  marginRight: '0.5em',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '&:hover': {
    borderColor: 'transparent',
  },
};

export const galleryModal = {
  '& .ant-modal-content': {
    [theme.Desktop]: { padding: '0 4.625em' },
    [theme.Mobile]: { padding: '0 0.25em' },
  },
  '& .ant-modal-body': { padding: '0.625em 0' },
  '& .image-gallery-slide': {
    width: '100%',
    [theme.Desktop]: { height: '41.25rem' },
    [theme.Mobile]: { height: '31.25rem' },
    backgroundColor: theme.gray500,
    paddingBottom: '1',
    borderRadius: 4,
  },
  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  '& .image-gallery-right-nav': {
    padding: 0,
    filter: 'none',
    right: -55,
    [theme.Mobile]: { right: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
  '& .image-gallery-left-nav': {
    filter: 'none',
    padding: 0,
    left: -55,
    [theme.Mobile]: { left: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
};

export const transactionContainer = {
  fontWeight: 600,
  padding: '0px 1em',
  '& > p': {
    margin: 0,
    '& > span': {
      fontWeight: 400,
    },
  },
};

export const transactionStatus = {
  display: 'flex',
  alignItems: 'center',
};

export const disputeImgBtnStyle = {
  fontSize: '0.9375rem',
  padding: 0,
  marginTop: '0.5em',
  overflow: 'hidden',
  marginRight: '0.5em',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '&:hover': {
    borderColor: 'transparent',
  },
};

export const noAvatar = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
