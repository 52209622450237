import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, useDeepEffect } from 'components/UIExternal';
import { MOBILE_VERSION } from 'enums';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const MobileVersionConfiguration = ({ data, onUpdate }) => {
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (e) => setValues((prev) => ({ ...prev, [e?.target.getAttribute('name')]: e?.target.value }));

  if (isNil(data)) return <FormBoxLoader number={2} />;

  return (
    <>
      <h3>{getText('merchant')}</h3>
      <div css={configurationSettingsInput}>
        <span>Android</span>
        <Input
          name={MOBILE_VERSION.MOBILE_VERSION_MERCHANT_ANDROID}
          value={values?.[MOBILE_VERSION.MOBILE_VERSION_MERCHANT_ANDROID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>IOS</span>
        <Input
          name={MOBILE_VERSION.MOBILE_VERSION_MERCHANT_IOS}
          value={values?.[MOBILE_VERSION.MOBILE_VERSION_MERCHANT_IOS]}
          onChange={handleChange}
        />
      </div>

      <h3>{getText('buyer')}</h3>
      <div css={configurationSettingsInput}>
        <span>Android</span>
        <Input
          name={MOBILE_VERSION.MOBILE_VERSION_BUYER_ANDROID}
          value={values?.[MOBILE_VERSION.MOBILE_VERSION_BUYER_ANDROID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>IOS</span>
        <Input
          name={MOBILE_VERSION.MOBILE_VERSION_BUYER_IOS}
          value={values?.[MOBILE_VERSION.MOBILE_VERSION_BUYER_IOS]}
          onChange={handleChange}
        />
      </div>
      <div css={submitActions}>
        <Button small type="secondary" onClick={() => onUpdate(values)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

MobileVersionConfiguration.propTypes = {
  data: PropTypes.object,
  hasSelectedCountry: PropTypes.bool,
  onUpdate: PropTypes.func,
  onReset: PropTypes.func,
};
