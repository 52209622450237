import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Global } from '@emotion/react';
import axios from 'axios';
import { setStrankConfig } from '@veraio/strank';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import { setDealshakerCoreConfig } from '@oneecosystem/dealshaker-core';
import { Toaster, LoadFonts } from 'components/ui';
import { UserProvider } from 'stores/contexts/User';
import BaseApp from 'screens/App';
import { trackGeoLocation } from 'services';
import * as request from 'services/axios/makeRequest';
import { globalStyles } from './styles';

setAuthConfig({
  identity: { domain: identityUrl, clientId: 'DealShaker' },
  oneLifeDomain: apiUrls.oneLife,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location.reload();
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

setDealshakerCoreConfig({
  dealshakerPortalApiDomain: apiUrls.dealShakerPortal,
  dealshakerAdminApiDomain: apiUrls.dealshakerAdmin,
  oneLifeApiDomain: apiUrls.oneLife,
  requestInstance: {
    get: request.getReq,
    post: request.postReq,
    put: request.putReq,
    patch: request.patchReq,
    delete: request.deleteReq,
  },
});

setStrankConfig({ parseHtml: true, environment: strankClient.environment, storage: window.localStorage });

const App = () => {
  return (
    <Router>
      <Toaster />
      <LoadFonts />
      <Global styles={globalStyles} />
      <UserProvider>
        <BaseApp />
      </UserProvider>
    </Router>
  );
};

const container = document.getElementById('dealshaker-administration-root');
const root = createRoot(container);
root.render(<App />);
