import * as ck from 'ckeditor5';

export const TOOLBAR = {
  shouldNotGroupWhenFull: true,
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'removeFormat',
    '|',
    'link',
    'alignment',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent',
  ],
};

export const PLUGINS = [
  ck.AccessibilityHelp,
  ck.Alignment,
  ck.Autoformat,
  ck.AutoLink,
  ck.Autosave,
  ck.Bold,
  ck.CloudServices,
  ck.Code,
  ck.CodeBlock,
  ck.Essentials,
  ck.FontBackgroundColor,
  ck.FontColor,
  ck.FontSize,
  ck.Heading,
  ck.Indent,
  ck.IndentBlock,
  ck.Italic,
  ck.Link,
  ck.List,
  ck.ListProperties,
  ck.Paragraph,
  ck.PasteFromMarkdownExperimental,
  ck.PasteFromOffice,
  ck.RemoveFormat,
  ck.SelectAll,
  ck.Strikethrough,
  ck.TextTransformation,
  ck.Underline,
  ck.Undo,
];

export const FONT_SIZE = {
  options: [10, 12, 14, 'default', 18, 20, 22],
  supportAllValues: true,
};

export const HEADING = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3',
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Heading 4',
      class: 'ck-heading_heading4',
    },
    {
      model: 'heading5',
      view: 'h5',
      title: 'Heading 5',
      class: 'ck-heading_heading5',
    },
    {
      model: 'heading6',
      view: 'h6',
      title: 'Heading 6',
      class: 'ck-heading_heading6',
    },
  ],
};

export const IMAGE = {
  toolbar: [
    'toggleImageCaption',
    'imageTextAlternative',
    '|',
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    '|',
    'resizeImage',
  ],
};

export const LINK = {
  addTargetToExternalLinks: true,
  defaultProtocol: 'https://',
  decorators: {
    toggleDownloadable: {
      mode: 'manual',
      label: 'Downloadable',
      attributes: {
        download: 'file',
      },
    },
  },
};

export const LIST = {
  properties: {
    styles: true,
    startIndex: true,
    reversed: true,
  },
};

export const TABLE = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
};
