import { theme } from 'styles';

export const modalContainer = {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  overflow: 'hidden auto',
  display: 'block',
};

export const modalScrollContainer = ({ large, small }) => [
  {
    minHeight: 'calc(100vh - 56px)',
    maxWidth: '31.25rem',
    margin: '1.75em auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  large && {
    '@media (min-width: 992px)': {
      maxWidth: 800,
    },
  },
  small && {
    '@media (min-width: 576px)': {
      maxWidth: '18.75rem',
    },
  },
];

export const modalContentContainer = {
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'flex-start',
  width: '100%',
  position: 'relative',
  backgroundColor: theme.white,
  padding: '1.5em',
  borderRadius: 8,
};

export const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2em',
  paddingBottom: '1em',
  borderBottom: `1px solid ${theme.borderLight}`,
};

export const closeIconStyles = {
  margin: '0 0 0 auto',
  marginBottom: '2em',
  cursor: 'pointer',
};
