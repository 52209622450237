import PropTypes from 'prop-types';
import { isNil } from '@veraio/core';
import { Skeleton } from 'antd';
import { LoadMoreText } from 'components/ui';
import { formatDefaultDateTime } from 'utils';
import { commentsListContainer } from './styles';

export const CommentsList = ({ comments }) => {
  return isNil(comments) ? (
    <Skeleton count={4} width={270} height={22} />
  ) : (
    <ul css={commentsListContainer}>
      {comments.map((item) => (
        <li key={item.createdDate} css={{ marginBottom: '0.25em' }}>
          <span className="bolder-text">{`${item.managerName ?? item.createdBy} [${formatDefaultDateTime(
            item.createdDate,
          )}]: `}</span>
          <LoadMoreText text={item.comment} />
        </li>
      ))}
    </ul>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.array,
};
