import { theme } from 'styles';

export const ordersListContainer = {
  borderRadius: 5,
  border: `1px solid ${theme.gray500}`,
  flex: '2 0 0',
  padding: '0 0 1em',
  overflowX: 'hidden',
  marginBottom: '1em',
  display: 'flex',
  flexDirection: 'column',

  '& .list': {
    margin: '0 0 0.7em',
    overflowY: 'auto',
    flex: 1,
  },
};

export const orderNoOrdersFound = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',

  '& img': {
    height: '7rem',
    margin: '0 0 1em',
  },
};
