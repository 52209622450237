import disputeIcon from 'assets/icons/notifications/icn-dispute.png';
import dealIcon from 'assets/icons/notifications/icn-deal.png';
import businessIcon from 'assets/icons/notifications/icn-business.png';
import manual from 'assets/icons/notifications/icn-manual.png';
import refund from 'assets/icons/notifications/icn-refund.png';

export const notificationType = {
  // MANAGER NOTIFICATIONS
  ManagerFirstBusinessCreated: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  ManagerNewBusinessCreated: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  ManagerEditedBusiness: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  ManagerNewBusinessAddressCreated: {
    icon: businessIcon,
    url: ({ businessAddressId }) => `/address/${businessAddressId}`,
  },
  ManagerEditedBusinessAddress: {
    icon: businessIcon,
    url: ({ businessAddressId }) => `/address/${businessAddressId}`,
  },
  ManagerNewDealCreated: {
    icon: dealIcon,
    url: ({ dealId }) => `/deal/${dealId}`,
  },
  ManagerEditedDeal: {
    icon: dealIcon,
    url: ({ dealId }) => `/deal/${dealId}`,
  },
  ManagerDisputeStarted: {
    icon: disputeIcon,
    url: ({ disputeId }) => `/dispute/${disputeId}`,
  },
  ManagerCouponRefundRequested: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  ManagerCouponRefundApproved: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  ManagerCouponRefundDenied: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  // ADMIN NOTIFICATIONS
  AdminFirstBusinessCreated: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  AdminNewBusinessCreated: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  AdminEditedBusiness: {
    icon: businessIcon,
    url: ({ businessId }) => `/business/${businessId}`,
  },
  AdminNewBusinessAddressCreated: {
    icon: businessIcon,
    url: ({ businessAddressId }) => `/address/${businessAddressId}`,
  },
  AdminEditedBusinessAddress: {
    icon: businessIcon,
    url: ({ businessAddressId }) => `/address/${businessAddressId}`,
  },
  AdminNewDealCreated: {
    icon: dealIcon,
    url: ({ dealId }) => `/deal/${dealId}`,
  },
  AdminEditedDeal: {
    icon: dealIcon,
    url: ({ dealId }) => `/deal/${dealId}`,
  },
  AdminDisputeStarted: {
    icon: disputeIcon,
    url: ({ disputeId }) => `/dispute/${disputeId}`,
  },
  AdminCouponRefundRequested: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  AdminCouponRefundApproved: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  AdminCouponRefundDenied: {
    icon: refund,
    url: ({ refundId }) => `/refund/${refundId}`,
  },
  // MANUAL NOTIFICATIONS
  Manual: {
    icon: manual,
    url: () => {},
  },
};
