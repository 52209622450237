import { theme } from 'styles';

export const amountsBox = {
  marginRight: '4rem',
  whiteSpace: 'nowrap',

  '& h6': {
    textTransform: 'uppercase',
    fontSize: '0.725rem',
    color: theme.gray300,
  },

  '& span.amount-label': {
    marginTop: '0.5rem',
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    fontWeight: 'normal',
    display: 'inline-block',
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRight: '2px solid',
    borderColor: theme.gray500,

    '&:first-child': {
      paddingLeft: '0',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
};
