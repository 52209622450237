import { theme } from 'styles';

export const orderCardContainer = (isActive) => ({
  padding: '1em',
  display: 'block',
  backgroundColor: isActive ? theme.skyblue : 'transparent',
  border: 'none',
  borderBottom: `1px solid ${theme.gray500}`,
  width: '100%',
  textAlign: 'left',

  '&:hover': {
    backgroundColor: isActive ? theme.skyblue : theme.light_blue_hover,
    borderColor: theme.gray500,
  },

  '& .gray': {
    color: theme.dark_gray,
  },

  '& .order-card-header-container': {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    marginBottom: '1.5em',

    '& .order-card-status-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',

      '& .order-card-status': {
        fontSize: '0.7rem',
        padding: '0.4em 0.6em',
      },
    },
  },
  '& .order-card-deals-container': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5em',

    '& .order-card-deals-image': {
      maxWidth: '5rem',
      maxHeight: '4rem',
      borderRadius: 5,
      marginRight: '1em',
    },
    '& .order-card-deals-title-container': {
      overflow: 'hidden',
      flex: 1,

      '& .order-card-deals-title': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },

      '& .order-card-deals-counter': {
        marginTop: '0.6em',
      },
    },
  },
  '& .order-card-footer-container': {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
  },
});

// loading component styles
export const loadingOrderContainer = {
  padding: '1em',
  border: '1px solid #DCDDE0',
};

export const loadingOrderCardRow = {
  display: 'flex',
  gap: 20,
  marginBottom: '1em',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:last-of-type': {
    marginBottom: 0,
  },
};
