import { getText } from '@veraio/strank';
import ClearingSettlementSharedFilterBar from '../ClearingSettlementSharedFilterBar';
import { SETTLEMENT_SORT_SELECT } from 'enums';


const SettlementFilterBar = (props) => <ClearingSettlementSharedFilterBar
	{...props}
	sortOptions={SETTLEMENT_SORT_SELECT(getText)}
/>;

export default SettlementFilterBar;