import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { useTranslations } from '@veraio/strank';
import { softLogout } from '@oneecosystem/authenticate';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores/contexts/User';
import defaultUserImage from 'assets/images/footer_user_default.svg';
import {
  userProfileImage,
  miniUserProfileMenu,
  accountAndSwitchButton,
  usernames,
  logoutButton,
  popoverWrapper,
  profileInfoText,
  popoverButtonContainer,
} from './styles';

const UserMiniProfile = () => {
  const { userInfo } = useUser();
  const { getText } = useTranslations();

  const contentSingleAccount = (
    <>
      <div css={miniUserProfileMenu}>
        <div css={accountAndSwitchButton}>
          {userInfo && <p css={usernames}>{`${userInfo.firstName} ${userInfo.lastName}`}</p>}
        </div>
        <p css={profileInfoText}>
          <label>{getText('phoneNumber')}:</label>
          <br />
          {userInfo?.phone ? userInfo.phone : '-'}
        </p>
        <p css={profileInfoText}>
          <label>{getText('emailAddress')}:</label>
          <br />
          {userInfo?.email ? userInfo.email : '-'}
        </p>
        <p css={profileInfoText}>
          <label>{getText('username')}:</label>
          <br />
          {userInfo?.username ? userInfo.username : '-'}
        </p>
        <p css={profileInfoText}>
          <label>{getText('postcode')}:</label>
          <br />
          {userInfo?.postcode ? userInfo.postcode : '-'}
        </p>
      </div>
      <Button small type="default" css={logoutButton} onClick={softLogout}>
        {getText('logOut')}
      </Button>
    </>
  );

  return (
    <Popover content={contentSingleAccount} overlayClassName={popoverWrapper} placement="bottomRight" trigger="click">
      <div css={popoverButtonContainer}>
        {userInfo?.avatarUrl ? (
          <img src={userInfo.avatarUrl} css={userProfileImage} alt="prop" />
        ) : (
          <img src={defaultUserImage} css={userProfileImage} alt="prop" />
        )}
      </div>
    </Popover>
  );
};

UserMiniProfile.propTypes = {
  userInfo: PropTypes.object,
};
export default UserMiniProfile;
