import { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { Notification, Icon } from 'components/ui';
import Routes from 'enums/Routes';
import useError from 'services/errorHandling/useError';
import { getAllNotifications, markNotificationAsSeen } from 'services/api/notificationsService';
import noNotifications from 'assets/images/noNotifications.svg';
import { popoverWrapper, badgeContainer, badgeNumber, notificationMessageButton } from './styles';

const NotificationsMiniPanel = () => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isPopupShown, setIsPopupSown] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    fetchNotifications();
    const notificationsInterval = setInterval(() => fetchNotifications(), 60000);
    return () => clearInterval(notificationsInterval);
  }, []);

  const fetchNotifications = async () => {
    const [res, err] = await getAllNotifications({ pageNumber: 1, pageSize: 5, isSeen: false });
    res ? setNotifications(res) : setError(err);
  };

  const markAsSeen = async () => {
    const [res, err] = await markNotificationAsSeen(notifications?.items.map((el) => el.id));
    err && setError(err);
    fetchNotifications();
    return res;
  };

  const togglePopup = () => {
    !!isPopupShown && notifications?.items?.length && markAsSeen();
    setIsPopupSown((prev) => !prev);
  };

  return (
    <Popover
      content={
        <div className="flex-column flex-center">
          {notifications?.items?.length ? (
            <>
              <h3> {getText('newNotifications')}</h3>
              {notifications?.totalCount &&
                notifications.items?.map((notification, idx) => <Notification data={notification} key={idx} />)}
            </>
          ) : (
            <>
              <img src={noNotifications} alt="Logo" />
              <h4>{getText('youDontHaveNewNotifications')}</h4>
            </>
          )}
          <Button type="link" linkTo={Routes.Notifications} css={notificationMessageButton} onClick={togglePopup}>
            {getText('goToAllNotifications')}
          </Button>
        </div>
      }
      overlayClassName={popoverWrapper}
      open={isPopupShown}
      onOpenChange={togglePopup}
      placement="bottomRight"
      trigger="click">
      {/* We need this div because Popover puts ref to its only child to append onClick method which opens the popup
        But our button do not accept ref and it is not acceptable to have ref on button, so we just add parent span */}
      <span>
        <Button type="link" small css={badgeContainer}>
          <Icon iconName="las la-bell" size={26} />
          {notifications?.totalCount > 0 && (
            <span css={badgeNumber(notifications?.totalCount)}>{notifications?.totalCount}</span>
          )}
        </Button>
      </span>
    </Popover>
  );
};

export default NotificationsMiniPanel;
