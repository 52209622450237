import PropTypes from 'prop-types';
import { formBoxContainer, formBoxTitle, formBoxSubTitle } from './styles';

export const FormBox = ({ title, subTitle, children }) => (
  <article css={formBoxContainer}>
    {title && <h3 css={formBoxTitle}>{title}</h3>}
    {subTitle && <h4 css={formBoxSubTitle}>{subTitle}</h4>}
    {children}
  </article>
);

FormBox.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
};
