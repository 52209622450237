import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/UIExternal';
import { mockTableRes } from 'utils';
import { pageContainer, container } from './styles';
import { DEAL_STATUS_HISTORY_COLUMNS } from './config';

export const DealStatusHistoryTab = ({ deal }) => {
  const tableRef = useRef();

  return (
    <section css={pageContainer}>
      <div css={container}>
        <Table
          ref={tableRef}
          columns={DEAL_STATUS_HISTORY_COLUMNS()}
          getDataMethod={mockTableRes(deal?.statusHistory)}
        />
      </div>
    </section>
  );
};

DealStatusHistoryTab.propTypes = { deal: PropTypes.object };
