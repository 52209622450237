import { getReq, patchReq, postReq, putReq } from 'services/axios/makeRequest';
import { getAllDealsParams, getAllDealsBody, getDealByIdBody, getDealCommentsParams } from 'services/models/deals';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Deals`;

export const getAllDeals = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/List?${getAllDealsParams(options)}`);
  return [getAllDealsBody(res), err];
};

export const getDealById = async (id) => {
  const [res, err] = await getReq(`${baseUrl}/Get/${id}`);
  return [getDealByIdBody(res), err];
};

export const getDealComments = async (id, options) => {
  const [res, err] = await getReq(`${baseUrl}/GetDealComments/${id}?${getDealCommentsParams(options)}`);
  return [res, err];
};

export const updateDealStatus = async (data) => patchReq(`${baseUrl}/UpdateStatus`, data);

export const addCommentToDeal = (data) => postReq(`${baseUrl}/Comment`, data);

export const updateDeal = async (data, dealId) => putReq(`${baseUrl}/Update/${dealId}`, data);
