/* eslint-disable max-len */
import { getText } from '@veraio/strank';
import { flattenDeep, isFunction } from 'lodash-es';

const validators = {
  required: {
    regExp: /\S/,
    msg: 'Required field',
  },
  email: {
    regExp:
      /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid Email',
  },
  username: {
    regExp: /^$|^[A-Za-z0-9_+\-.!#$'^`~@]{1,25}$/,
    msg: 'Invalid username',
  },
  password: {
    regExp: /^$|^.{6,}$/,
    msg: 'Invalid password',
  },
  phone: {
    regExp: /^$|^[0-9]{10}$/,
    msg: 'Invalid phone number',
  },
  postCode: {
    regExp: /^$|^[0-9]{6}$/,
    msg: 'Invalid post code',
  },
  price: {
    regExp: /^(?:[1-9][0-9]{0,})\.[0-9]{1,2}|[1-9][0-9]{0,}$/,
    msg: 'Invalid number',
  },
  positiveInt: {
    regExp: /^0|[1-9][0-9]{0,}$/,
    msg: 'Invalid number',
  },
  validHex: {
    regExp: /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i,
    msg: 'Invalid HEX color',
  },
};

export default (value, ...inputValidators) => {
  const setOfValidators = flattenDeep(inputValidators);

  for (const validator of setOfValidators) {
    const validatorName = validator?.name ?? validator;
    const render = validator?.render;
    const { regExp, msg } = validators[validatorName];

    // Bypass null/undefined because the regExp api is stupid and if you pass it null/undefined it will convert them to string
    if (!regExp.test(value ?? '')) {
      return {
        msg,
        ...(isFunction(render) && { render }),
      };
    }
  }

  return null;
};

export const parseJson = (string, defaultValue) => {
  try {
    const parsed = JSON.parse(string);
    return parsed;
  } catch (e) {
    return defaultValue;
  }
};

export const rules = {
  minMaxAsync: (min, max, value) =>
    value < min
    ? Promise.reject(new Error(getText('mustBeMoreThan', { value: min })))
    : value > max
      ? Promise.reject(new Error(getText('mustBeLessThan', { value: max })))
      : Promise.resolve(value)
};