import queryString from 'query-string';
import { isNil, pick, uniqBy, isNumber } from 'lodash-es';

export const getAllManagersParams = (options) => {
  const { name, email, countryId, managerType } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(name && name?.length && { name }),
      ...(email && email?.length && { email }),
      ...(!isNil(countryId) && { countryId }),
      ...(!isNil(managerType) && { managerType }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAllManagersResponse = (response) => ({
  totalCount: response.totalCount,
  items: response.items.map((manager) => ({
    ...manager,
    countries: uniqBy(
      manager?.countries?.map((country) => ({
        ...country,
        areas: manager?.areas?.filter((item) => item.countryId === country.id),
      })),
      'id',
    ),
  })),
});

export const updateManagersBody = (body) => {
  const { feeAccountId } = body;

  return body?.areas?.length
    ? body.areas.map((el) => ({ areaId: el?.areaId, countryId: el?.countryId, feeAccountId }))
    : body?.countries?.map((el) => ({ countryId: el?.id, feeAccountId }));
};

export const createManagerBody = (body) => {
  const { userId, userTypeId, feeAccountId } = body;

  const configs = body?.areas?.length
    ? body?.areas?.map((el) => ({ areaId: el?.areaId, countryId: el?.countryId, feeAccountId }))
    : body?.countries?.map((el) => ({ countryId: el?.id, feeAccountId }));

  return {
    ...(!isNil(userId) && userId?.length && { profileUserId: userId }),
    ...(isNumber(userTypeId) && { userTypeId }),
    ...(!isNil(configs) && !!configs?.length && { configs }),
  };
};
