import { softLogout } from '@oneecosystem/authenticate';
import { Button } from 'components/UIExternal';
import { mainContainer } from './styles';

const Unauthorized = () => (
  <div css={mainContainer}>
    <h1>403</h1>
    <h2>Access forbidden</h2>
    <Button type="default" small onClick={softLogout}>
      Logout
    </Button>
  </div>
);

export default Unauthorized;
