import { theme } from 'styles';

export const successWrapper = {
  width: '100%',
  maxWidth: '56.25rem',
  backgroundColor: `${theme.green3}`,
  borderRadius: '32px',
};

export const errorWrapper = {
  width: '100%',
  maxWidth: '56.25rem',
  backgroundColor: `${theme.pale_yellow}`,
  borderRadius: '32px',
};

export const infoWrapper = {
  width: '100%',
  maxWidth: '56.25rem',
  backgroundColor: `${theme.primary_blue}`,
  borderRadius: '32px',
};
