import { theme } from 'styles';

export const chartContainer = {
  padding: '1.875em',
};

export const chartTitle = {
  fontWeight: 500,
};

export const totalCountStyles = {
  fontSize: '1.375rem',
  fontWeight: 500,
  color: theme.gray100,
};
