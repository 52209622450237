import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { pageContainer } from '../../../styles';
import { useTranslations } from '@veraio/strank';
import { getBundledTransactions, setError } from 'services';
import { Button, Table } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { headerContainer, transactionsTableContainer } from './styles';
import { getTransactionsWithOrderInfoTableColumns } from './config';
import { useTheme } from '@emotion/react';

const BundledTransactions = () => {
	const { getText } = useTranslations();
	const history = useHistory();
	const { transactionId } = useParams();
	const tableRef = useRef();
	const theme = useTheme();

	const fetchBundledTransactions = async id => {
		const [res, err] = await getBundledTransactions(id);
		if (err) return setError(err);

		return [{ items: res }, err];
	};

	return <section css={pageContainer}>
		<div css={headerContainer}>
			<Button type="link" onClick={() => history.goBack()} small>
				<Icon iconName="las la-arrow-left" />
			</Button>
			<h2 className="flex flex-center deal-name">#{transactionId}</h2>
		</div>
		<div className={transactionsTableContainer} data-id="merchant-office-clearing-tab-filters">
			<Table
				ref={tableRef}
				hasPagination
				pageSize={10}
				withPageDropdown
				getDataMethod={() => fetchBundledTransactions(transactionId)}
				columns={getTransactionsWithOrderInfoTableColumns(theme, getText)}
			/>
		</div>
	</section>;
};

export default BundledTransactions;