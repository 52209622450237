import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, useDeepEffect } from 'components/UIExternal';
import { WALLETS_FEE } from 'enums';
import { useUser } from 'stores';
import { isCryptoBanned } from 'utils';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const WalletsFeeConfiguration = ({ data, onUpdate }) => {
  const { geoLocation } = useUser();
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (e) => setValues((prev) => ({ ...prev, [e?.target.getAttribute('name')]: e?.target.value }));

  if (isNil(data)) return <FormBoxLoader number={6} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('companyWallet', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={WALLETS_FEE.COMPANY_WALLET_ID}
          value={values?.[WALLETS_FEE.COMPANY_WALLET_ID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('marketingWallet', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={WALLETS_FEE.MARKETING_WALLET_ID}
          value={values?.[WALLETS_FEE.MARKETING_WALLET_ID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('backupWallet', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={WALLETS_FEE.BACKUP_WALLET_ID}
          value={values?.[WALLETS_FEE.BACKUP_WALLET_ID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('additionalServices', { crypto: isCryptoBanned(geoLocation) })}</span>
        <Input
          name={WALLETS_FEE.ADDITIONAL_SERVICE_WALLET_ID}
          value={values?.[WALLETS_FEE.ADDITIONAL_SERVICE_WALLET_ID]}
          onChange={handleChange}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('settlementAccountID')}</span>
        <Input
          name={WALLETS_FEE.SETTLEMENT_ONELIFE_ACCOUNT_ID}
          value={values?.[WALLETS_FEE.SETTLEMENT_ONELIFE_ACCOUNT_ID]}
          onChange={handleChange}
        />
      </div>
      <div css={submitActions}>
        <Button small type="secondary" onClick={() => onUpdate(values)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

WalletsFeeConfiguration.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
