import { getText, getDynamicTranslation } from '@veraio/strank';
import { Button, Tag } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { DealPrice } from 'components/shared';
import { dealStatusColor, dealStatusInverse } from 'enums';
import { textLinkStyle, actionStyle } from './styles';

// dealColumns should be function because it has getText inside which throw error if it is used before configuration
export const dealColumns = () => [
  {
    name: getText('title'),
    render: (record) => (
      <Button type="link" linkTo={`/deal/${record.id}`} css={textLinkStyle}>
        {getDynamicTranslation(record.title)?.title}
      </Button>
    ),
  },
  {
    name: getText('status'),
    render: ({ statusId }) => <Tag type={dealStatusColor[statusId]}>{getText(dealStatusInverse[statusId])}</Tag>,
    width: 100,
  },
  {
    name: getText('name'),
    render: (record) => <>{`${record.name} - ${record.username}`}</>,
  },
  {
    name: getText('price'),
    value: 'price',
    render: (deal) => <DealPrice showFinalPrice deal={deal} />,
  },
  {
    name: getText('launchDate'),
    value: 'startDate',
    dateTimeFormat: 'MMM DD,YYYY',
    width: 120,
    sortable: true,
  },
  {
    name: getText('endDate'),
    value: 'endDate',
    dateTimeFormat: 'MMM DD,YYYY',
    width: 100,
    sortable: true,
  },
  {
    name: getText('modified'),
    value: 'modifiedDate',
    dateTimeFormat: 'MMM DD,YYYY, HH:mm:ss ',
    width: 160,
    sortable: true,
  },
  {
    name: '',
    render: (record) => (
      <Button type="link" linkTo={`/deal/${record?.id}`} small css={actionStyle}>
        <Icon iconName="lar la-eye" size={20} />
      </Button>
    ),
    width: 45,
  },
];
