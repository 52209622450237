export const filtersContainer = {
  '& .filters-container': {
    '& .nowrap span': {
      whiteSpace: 'nowrap',
    },
    '& .variable-search': {
      maxWidth: '20rem',
      '& .small-filter': {
        maxWidth: '380px',
      },
      '& .filters-search-field': {
        width: '100%',
      },
    },
    '& .small-filter': {
      maxWidth: '120px',
    },
    '& .align-right': {
      width: 'auto',
      marginLeft: 'auto',
    },
    '& .sort-field': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      '& > label': {
        whiteSpace: 'nowrap',
      },
    },
  },
};
