import { useTranslations } from '@veraio/strank';
import { Tabs } from 'components/ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import GeneralCountryConfigurations from './GeneralCountryConfigurations';
import CountryAreas from './CountryAreas';
import CountryConfiguration from './CountryConfiguration';
import { subtabsContainer } from './styles';

const tabs = { general: 'country-general', countryAreas: 'country-areas', cryptoBan: 'crypto-ban' };

const CountryConfigurations = () => {
	const { getText } = useTranslations();
	const [activeTab, setActiveTab] = useState(tabs.general);
	const history = useHistory();

	const tabItems = [
		{
			key: tabs.general,
			label: getText(tabs.general),
			children: <GeneralCountryConfigurations />,
		},
		{
			key: tabs.countryAreas,
			label: getText(tabs.countryAreas),
			children: <CountryAreas />,
		},
		{
			key: tabs.cryptoBan,
			label: getText(tabs.cryptoBan),
			children: <CountryConfiguration />,
		},
	];

	const handleTabChange = tab => {
		history.replace({
			search: ''
		});
		setActiveTab(tab);
	};

	return (
		<div css={subtabsContainer}>
			<Tabs
				destroyInactiveTabPane
				defaultActiveKey={tabs.general}
				activeKey={activeTab}
				onTabClick={handleTabChange}
				items={tabItems}
			/>
		</div>
	);
};

export default CountryConfigurations;
