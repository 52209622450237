import { Table } from 'components/UIExternal';
import { disputeStatus } from 'enums';
import { getAllDisputes } from 'services';
import { DisputesFilterBar } from 'components/screens';
import { disputesColumns } from './config';
import { pageContainer } from './styles';

const filterKeys = {
  status: 'statusIds',
};

const Disputes = () => {
  return (
    <section css={pageContainer}>
      <Table
        // disputesColumns should be function because it has getText inside which throw error if it is used before configuration
        columns={disputesColumns()}
        getDataMethod={getAllDisputes}
        filterBar={DisputesFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.status]: [disputeStatus.pending] }}
      />
    </section>
  );
};

export default Disputes;
