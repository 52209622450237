import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';
import { ceil } from 'exact-math';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { DEFAULT_CRYPTO_CURRENCY } from '@oneecosystem/dealshaker-core';
import { labelStyles, labelLegend, strikeThrough, chartContainer, chartTitle, totalCountStyles } from './styles';

const DoughnutChart = (props) => {
  const { data, title, totalCount, config, showPercentage, chartHeight } = props;
  const canvasRef = useRef();
  const { getText } = useTranslations();
  let chart = null;

  const labelElement = !isNil(config) && config(data).data?.datasets.find((x) => x !== undefined);

  useEffect(() => {
    chart = new Chart(canvasRef.current, !isNil(config) && config(data));
    return () => chart.destroy();
  }, [data]);

  const toggleLabel = (e, idx) => {
    chart.getDatasetMeta(0).data[idx].hidden = !chart.getDatasetMeta(0).data[idx].hidden;
    e.target.classList.toggle(strikeThrough);
    chart.update();
  };

  return (
    <div css={chartContainer}>
      {title && <h4 css={chartTitle}>{title}</h4>}
      {totalCount > 0 && <p css={totalCountStyles}>{totalCount}</p>}
      <div>
        <div style={{ marginBottom: '1em' }}>
          <canvas ref={canvasRef} height={chartHeight} />
        </div>
        <table>
          {data?.map(
            (x, idx) =>
              x.percentage > 0 && (
                <tbody key={idx}>
                  <tr>
                    <td>
                      <span css={labelElement && labelStyles(labelElement.backgroundColor[idx], idx)} />
                      <button css={labelLegend} onClick={(e) => toggleLabel(e, idx)}>
                        {x.key === 'PriceOneCoin' ? DEFAULT_CRYPTO_CURRENCY.symbol : getText(x.key)}
                      </button>
                    </td>
                    <td>{showPercentage && <span>{ceil(x.percentage || 0, -2)}%</span>}</td>
                  </tr>
                </tbody>
              ),
          )}
        </table>
      </div>
    </div>
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.array,
  chartStyles: PropTypes.any,
  title: PropTypes.string,
  totalCount: PropTypes.number,
  config: PropTypes.func,
  showPercentage: PropTypes.bool,
  chartHeight: PropTypes.string,
};

export default DoughnutChart;
