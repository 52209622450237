import PropTypes from 'prop-types';
import { Table } from 'components/UIExternal';
import { mockTableRes } from 'utils';
import { pageContainer } from './styles';
import { BUSINESS_STATUS_HISTORY_COLUMNS } from './config';

export const BusinessStatusHistoryTab = ({ business }) => (
  <section css={pageContainer}>
    <Table columns={BUSINESS_STATUS_HISTORY_COLUMNS()} getDataMethod={mockTableRes(business?.statusHistory)} />
  </section>
);

BusinessStatusHistoryTab.propTypes = { business: PropTypes.object };
