import { Tag, TAG_TYPE } from 'components/UIExternal';
import { isNil } from '@veraio/core';
import { infoTag } from './styles';

export const historyLabelColor = {
  newText: TAG_TYPE.RED_LIGHT,
  oldText: TAG_TYPE.GREEN,
};

export const TextChanged = ({ oldText, newText, containerStyles }) => {
  const hasOldText = Boolean(oldText);
  const hasNewText = Boolean(newText);

  if (isNil(oldText) && isNil(newText)) return <span>N/A</span>;
  if (oldText === newText || isNil(newText)) return <span>{oldText}</span>;

  return (
    <div css={containerStyles}>
      {hasOldText && (
        <Tag css={infoTag} type={historyLabelColor.oldText}>
          {oldText}
        </Tag>
      )}
      {hasNewText && (
        <Tag css={infoTag} type={historyLabelColor.newText}>
          {newText}
        </Tag>
      )}
    </div>
  );
};
