import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { useUrlParams } from 'components/UIExternal';
import { PageHeader } from 'components/shared';
import { disputeStatusColor, disputeStatusSelect, disputeStatusInverse } from 'enums';

export const DisputesFilterBar = ({ paginationRef, filterKeys, defaultQueryParams }) => {
  const { queryParams } = useUrlParams();
  const queryStatus = queryParams[filterKeys.status] && queryParams[filterKeys.status]?.map((el) => Number(el));

  return (
    <PageHeader
      title={getText('disputes')}
      statusProps={{
        defaultValue: queryStatus ?? defaultQueryParams[filterKeys.status],
        onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.status]: value }),
        options: disputeStatusSelect(),
        tagProps: { type: disputeStatusColor, value: disputeStatusInverse },
      }}
    />
  );
};

DisputesFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
  defaultQueryParams: PropTypes.object,
};
