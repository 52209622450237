import PropTypes from 'prop-types';
import { useTranslations, getLanguageByCode } from '@veraio/strank';
import { Col, Row } from 'antd';
import { TabPane, Tabs } from 'components/ui';

const MainInformationPreview = ({ deal }) => {
  const { getText } = useTranslations();

  return (
    <Col lg={24} span={24}>
      <Tabs>
        {deal?.dealDetails?.map((detail, index) => (
          <TabPane key={`${detail?.languageCode}-${index}`} tab={getLanguageByCode(detail?.languageCode)?.name}>
            <Row>
              <Col lg={4} span={24}>
                <span className="subtitle">{getText('title')}</span>
              </Col>
              <Col lg={20} span={24}>
                <span className="content">{detail.title}</span>
              </Col>
              <Col lg={4} span={24}>
                <span className="subtitle">{getText('description')}</span>
              </Col>
              <Col lg={20} span={24}>
                <span className="content">{detail.description}</span>
              </Col>
              <Col lg={4} span={24}>
                <span className="subtitle">{getText('couponTermsOfUse')}</span>
              </Col>
              <Col lg={20} span={24}>
                <span className="content">{detail.couponTermsOfUse}</span>
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </Col>
  );
};

MainInformationPreview.propTypes = {
  deal: PropTypes.object,
};

export default MainInformationPreview;
