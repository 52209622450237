import { theme, commonStyles } from 'styles';

export const TAG_TYPE = {
  GREEN: 'green',
  GREEN_LIGHT: 'green light',
  BLUE: 'blue',
  BLUE_LIGHT: 'blue light',
  YELLOW: 'yellow',
  YELLOW_LIGHT: 'yellow light',
  GRAY: 'gray',
  GRAY_LIGHT: 'gray light',
  RED: 'red',
  RED_LIGHT: 'red light',
  PURPLE: 'purple',
  PURPLE_LIGHT: 'purple light',
  WARNING: 'warning',
  WARNING_LIGHT: 'warning light',
};

const backgroundColorMap = {
  [TAG_TYPE.GREEN]: theme.green,
  [TAG_TYPE.GREEN_LIGHT]: theme.green3,
  [TAG_TYPE.BLUE]: theme.blue,
  [TAG_TYPE.BLUE_LIGHT]: theme.primary_blue,
  [TAG_TYPE.GRAY]: theme.background100,
  [TAG_TYPE.GRAY_LIGHT]: theme.gray500,
  [TAG_TYPE.YELLOW]: theme.primary_yellow,
  [TAG_TYPE.YELLOW_LIGHT]: theme.pale_yellow,
  [TAG_TYPE.RED]: theme.red_dark,
  [TAG_TYPE.RED_LIGHT]: theme.red,
  [TAG_TYPE.PURPLE]: theme.purple,
  [TAG_TYPE.PURPLE_LIGHT]: theme.purple2,
  [TAG_TYPE.WARNING]: theme.warning,
  [TAG_TYPE.WARNING_LIGHT]: theme.warningLight,
};

const colorMap = (type) =>
  [
    TAG_TYPE.GREEN,
    TAG_TYPE.BLUE,
    TAG_TYPE.BLUE_LIGHT,
    TAG_TYPE.GRAY,
    TAG_TYPE.RED,
    TAG_TYPE.RED_LIGHT,
    TAG_TYPE.PURPLE,
    TAG_TYPE.WARNING,
  ].includes(type)
    ? theme.white
    : theme.gray100;

export const tagContainer = (props) => {
  const { type = TAG_TYPE.GREEN_LIGHT } = props;
  const backgroundColor = backgroundColorMap[type] ?? theme.green3;
  const color = colorMap(type);

  return [
    {
      backgroundColor,
      color,
      borderRadius: '5rem',
      padding: '0.1em 0.5em',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '0.65rem',
    },
    ...commonStyles(props),
  ];
};
