import { TAG_TYPE } from 'components/UIExternal';
import { invert } from 'lodash-es';

export const TRANSACTION_STATUS = {
  pending: 1,
  failed: 2,
  created: 3,
  successful: 4,
  reverted: 5,
  cancelled: 6,
  partiallyPaid: 8,
  awaitingClearing: 10,
  awaitingSettlement: 7,
  settled: 9,
};

export const TRANSACTION_STATUS_COLOR = {
  [TRANSACTION_STATUS.pending]: TAG_TYPE.WARNING,
  [TRANSACTION_STATUS.failed]: TAG_TYPE.RED,
  [TRANSACTION_STATUS.created]: TAG_TYPE.BLUE,
  [TRANSACTION_STATUS.successful]: TAG_TYPE.GREEN,
  [TRANSACTION_STATUS.reverted]: TAG_TYPE.GREEN_LIGHT,
  [TRANSACTION_STATUS.cancelled]: TAG_TYPE.RED_LIGHT,
  [TRANSACTION_STATUS.partiallyPaid]: TAG_TYPE.YELLOW,
  [TRANSACTION_STATUS.awaitingClearing]: TAG_TYPE.WARNING,
  [TRANSACTION_STATUS.awaitingSettlement]: TAG_TYPE.YELLOW,
  [TRANSACTION_STATUS.settled]: TAG_TYPE.GREEN,
};

export const TRANSACTION_STATUS_INVERSE = invert(TRANSACTION_STATUS);

export const TRANSACTIONS_CURRENCIES = {
  ONE: 'ONE',
  EUR: 'Euro',
  OESP: 'OESP',
};

export const CLEARING_SORT_SELECT = (getText) => [
  { label: getText && getText('oldestByCreationDate'), value: CLEARING_SORT_OPTIONS.creationDateAsc },
  { label: getText && getText('newestByCreationDate'), value: CLEARING_SORT_OPTIONS.creationDateDesc },
  { label: getText && getText('oldestByClearingDate'), value: CLEARING_SORT_OPTIONS.clearingDateAsc },
  { label: getText && getText('newestByClearingDate'), value: CLEARING_SORT_OPTIONS.clearingDateDesc },
];

export const CLEARING_SORT_OPTIONS = {
  creationDateAsc: 0,
  creationDateDesc: 1,
  clearingDateAsc: 2,
  clearingDateDesc: 3,
};

export const SETTLEMENT_SORT_SELECT = (getText) => [
  { label: getText && getText('oldestBySettlementDate'), value: SETTLEMENT_SORT_OPTIONS.creationDateAsc },
  { label: getText && getText('newestBySettlementDate'), value: SETTLEMENT_SORT_OPTIONS.creationDateDesc },
];

export const SETTLEMENT_SORT_OPTIONS = {
  creationDateAsc: 0,
  creationDateDesc: 1,
};

export const TRANSACTION_STATUS_SELECT = (getText) =>
  Object.entries(TRANSACTION_STATUS).map(([key, value]) => ({
    label: getText && getText(key),
    value,
  }));

export const CURRENCY_SELECT = Object.entries(TRANSACTIONS_CURRENCIES).map(([key, value]) => ({
  label: key,
  value
}));