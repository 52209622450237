import { getText } from '@veraio/strank';
import { Input } from 'antd';
import CategoryTreeItem from 'components/screens/Settings/CategoriesSettings/CategoryTreeItem';
import { TextInput, Tree } from 'components/ui';
import { Button, Modal } from 'components/UIExternal';
import { useRef, useState } from 'react';
import { topBar, modalActions, rootTitle, categoriesSettingsContainer, daysInput } from './styles';
import { FormBox } from '../../FormBox';

const CategoriesTree = ({ categories, onCategoryChange }) => {
	const [categoryClearingDaysValue, setCategoryClearingDaysValue] = useState(null);
	const editCategoryDataRef = useRef({});
	const [changeValueModalOpen, setChangeValueModalOpen] = useState(false);
	const [searchText, setSearchText] = useState('');

	const handleOpenChangePeriod = (categoryItem) => {
		editCategoryDataRef.current = {
			categoryItem,
		};

		setCategoryClearingDaysValue(categoryItem.clearingDays);
		setChangeValueModalOpen(true);
	};

	const handleCancelChangePeriod = () => setChangeValueModalOpen(false);

	const handleSubmitChangePeriod = async () => {
		await onCategoryChange(editCategoryDataRef.current.categoryItem.id, categoryClearingDaysValue);

		editCategoryDataRef.current.categoryItem.clearingDays = categoryClearingDaysValue;
		setCategoryClearingDaysValue(null);
		setChangeValueModalOpen(false);
	};

	const handleSearch = value => {
		setSearchText(value);
	};

	const renderItem = item => <CategoryTreeItem category={item} onChangePeriod={handleOpenChangePeriod} />;

	const productsCategories = categories
		&& categories.length > 0
		&& categories[0].subCategories;

	const servicesCategories = categories
		&& categories.length > 0
		&& categories[1].subCategories;

	return <>
		<FormBox data-id="categories-trees">
			<div css={topBar}>
				<h2>{getText('categoriesClearingPeriods')}</h2>
				<div>
					<TextInput
						showSearch
						showDelete
						placeholder={getText('searchByKeyword')}
						onSearch={handleSearch}
					/>
				</div>
			</div>
			{productsCategories && productsCategories.length > 0 && <div css={categoriesSettingsContainer}>
				<h5 css={rootTitle}>{categories[0].name}</h5>
				<Tree
					data={productsCategories}
					titleKey='name'
					childrenKey='subCategories'
					renderItem={renderItem}
					searchText={searchText}
					minSearchLength={2}
				/>
			</div>}

			{servicesCategories && servicesCategories.length > 0 && <div css={categoriesSettingsContainer}>
				<h5 css={rootTitle}>{categories[1].name}</h5>
				<Tree
					data={servicesCategories}
					titleKey='name'
					childrenKey='subCategories'
					renderItem={renderItem}
					searchText={searchText}
					minSearchLength={2}
				/>
			</div>}
		</FormBox>

		<Modal modalTitle={getText('editClearingPeriod')} show={changeValueModalOpen} width={580} zIndex={1100} onClose={handleCancelChangePeriod} footer={null}>
			<strong>{editCategoryDataRef.current?.categoryItem?.name}</strong>

			<div css={daysInput}>
				<Input
					value={categoryClearingDaysValue}
					onChange={ev => setCategoryClearingDaysValue(Number(ev.target.value))}
				/>
				<span>{getText('days')}</span>
			</div>

			<div css={modalActions}>
				<Button type="link" small onClick={handleCancelChangePeriod}>
					{getText('cancel')}
				</Button>
				<Button type="secondary" small onClick={handleSubmitChangePeriod}>
					{getText('saveChanges')}
				</Button>
			</div>
		</Modal>
	</>;
};

export default CategoriesTree;