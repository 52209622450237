import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { TextChanged } from 'components/UIExternal';
import { Col } from 'antd';
import { historyChanges } from './styles';

const BusinessAddressPreview = ({ textChange }) => {
  const { getText } = useTranslations();

  return (
    <>
      <Col lg={4} span={24}>
        <label>{getText('country')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('country')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('area')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('area')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('stateProvince')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('state')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('city')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('city')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('postcode')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('postcode')} />
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('addressLine')}</label>
      </Col>
      <Col lg={20} span={24}>
        <TextChanged {...textChange('street')} css={historyChanges} />
      </Col>
    </>
  );
};

BusinessAddressPreview.propTypes = {
  deal: PropTypes.object,
  textChange: PropTypes.func,
};

export default BusinessAddressPreview;
