import { getText } from '@veraio/strank';
import moment from 'moment';
import { notificationType } from 'enums';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import {
  linkBthStyles,
  typeTime,
  notificationIcon,
  dateStyles,
  statusContainer,
  statusDot,
  notificationIsRead,
} from './styles';

// systemTableColumns should be function because it has getText inside which throw error if it is used before configuration
export const systemTableColumns = () => [
  {
    render: (notification) => (
      <img css={notificationIcon} src={notificationType[notification.type]?.icon} alt={notification.type} />
    ),
    width: 50,
  },
  {
    name: getText('title'),
    render: (notification) => {
      const timeContainer = (
        <div css={notificationIsRead(notification.isRead)} className="time">
          <span>{moment(notification.publishDate ?? notification.createdAt).format('DD/MM/YYYY')}</span>
          <span>{moment(notification.publishDate ?? notification.createdAt).format('HH:mm')}</span>
        </div>
      );

      return (
        <div css={typeTime}>
          <h4 css={notificationIsRead(notification.isRead)}>
            {getText(`${notification.type}Title`, notification.additionalData)}
          </h4>
          {timeContainer}
        </div>
      );
    },
    width: 160,
  },
  {
    name: getText('message'),
    render: (notification) => (
      <p css={notificationIsRead(notification.isRead)}>
        {getText(`${notification.type}Message`, notification.additionalData)}
      </p>
    ),
  },
];

export const manualTableColumns = (handleDeleteNotification) => [
  {
    render: (notification) => (
      <img css={notificationIcon} src={notificationType[notification.type]?.icon} alt={notification.type} />
    ),
    width: 50,
  },
  {
    name: getText('title'),
    render: (notification) => {
      const timeContainer = (
        <div className="time">
          <span>{moment(notification.publishDate ?? notification.createdAt).format('DD/MM/YYYY')}</span>
          <span>{moment(notification.publishDate ?? notification.createdAt).format('HH:mm')}</span>
        </div>
      );

      return (
        <div>
          <h4 className="title">{notification?.title}</h4>
          {timeContainer}
        </div>
      );
    },
    width: 160,
  },
  {
    name: getText('message'),
    render: (notification) => <div>{notification?.message}</div>,
  },
  {
    name: getText('audience'),
    render: (notification) => (
      <div>
        <p css={dateStyles}>{getText(notification.audience)}</p>
      </div>
    ),
    width: 140,
  },
  {
    name: `${getText('createdBy')}/ ${getText('createdDate')}`,
    render: (notification) => (
      <div>
        <h3>{notification.creatorName}</h3>
        <p css={dateStyles}>{`${notification.createdBy} / ${moment(notification.createdAt).format('DD/MM/YYYY')}`}</p>
      </div>
    ),
    width: 140,
  },
  {
    name: getText('status'),
    render: (notification) => (
      <div css={statusContainer}>
        <div css={statusDot(notification.publishDate)} />
        <p>
          {!notification.publishDate || moment().diff(notification.publishDate) > 0
            ? getText('send')
            : getText('notSend')}
        </p>
      </div>
    ),
    width: 100,
  },
  {
    width: 70,
    render: (notification) =>
      moment().diff(notification.publishDate) < 0 && (
        <Button type="link" small onClick={() => handleDeleteNotification(notification.id)} css={linkBthStyles}>
          <Icon iconName="las la-trash" size={15} />
        </Button>
      ),
  },
];
