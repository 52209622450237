import { useState } from 'react';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { omit } from 'lodash-es';
import { Col, Row } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import {
  MainInformationPreview,
  DealDetailsPreview,
  PricingDealsPreview,
  PromotionDetailsPreview,
  OtherPreview,
  MainInformationEdit,
  DealDetailsEdit,
  PricingDealsEdit,
  PromotionDetailsEdit,
  OtherEdit,
} from 'components/screens';
import { updateDeal } from 'services';
import useError from 'services/errorHandling/useError';
import { wrapper, editFormSectionBtn } from './styles';

export const DealInformationTab = ({ deal, onEditDeal, isAdmin, isRestrictedCategory }) => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDealDetails, setIsEditingDealDetails] = useState(false);
  const [isEditingPricing, setIsEditingPricing] = useState(false);
  const [isEditingDates, setIsEditingDates] = useState(false);
  const [isEditingOther, setIsEditingOther] = useState(false);

  const handleEditMainInformation = () => {
    if (isAdmin || !isRestrictedCategory) setIsEditing((prev) => !prev);
  };
  const handleEditDealDetails = () => {
    if (isAdmin || !isRestrictedCategory) setIsEditingDealDetails((prev) => !prev);
  };
  const handleEditPricingDeals = () => {
    if (isAdmin || !isRestrictedCategory) setIsEditingPricing((prev) => !prev);
  };
  const handleEditPromotionDetails = () => {
    if (isAdmin || !isRestrictedCategory) setIsEditingDates((prev) => !prev);
  };
  const handleEditOther = () => {
    setIsEditingOther((prev) => !prev);
  };

  const handleEditDeal = async (values, keysToOmit) => {
    const requestData = {
      ...omit(deal, keysToOmit),
      ...values,
    };

    const body = {
      ...omit(
        requestData,
        'businessDescription',
        'businessStatusId',
        'businessTypeId',
        'businessTitle',
        'comments',
        'dealAddresses',
        'dealDetails',
        'discountedPrice',
        'discountedPriceCrypto',
        'discountedPriceFiat',
        'media',
        'merchantName',
        'name',
        'priceCrypto',
        'priceFiat',
        'statusHistory',
        'id',
      ),
      dealDetails: requestData?.dealDetails?.map((el) => ({
        ...omit(el, 'couponTermsOfUse'),
        termsAndConditions: el.couponTermsOfUse,
      })),
    };
    const [, err] = await updateDeal(body, deal?.id);
    if (err) return setError(err);
    toast.success(getText('successfullyEditedBusinessAddress'));
    await onEditDeal();
  };

  return (
    <Row css={wrapper} gutter={[0, 10]}>
      {/* mainInformation */}
      <Col lg={24} span={24}>
        <span className="title">{getText('mainInformation')}</span>
        {(isAdmin || !isRestrictedCategory) && (
          <Button type="default" css={editFormSectionBtn} onClick={handleEditMainInformation}>
            {isEditing ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
      </Col>
      {isEditing ? (
        <MainInformationEdit onCancel={() => setIsEditing(false)} deal={deal} onEdit={handleEditDeal} />
      ) : (
        <MainInformationPreview deal={deal} />
      )}

      <Col lg={24} span={24}>
        <hr />
      </Col>

      {/* deal Details*/}
      <Col lg={24} span={24}>
        <span className="title">{getText('dealDetails')}</span>
        {(isAdmin || !isRestrictedCategory) && (
          <Button type="default" onClick={handleEditDealDetails} css={editFormSectionBtn}>
            {isEditingDealDetails ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
        {isEditingDealDetails ? (
          <DealDetailsEdit onCancel={() => setIsEditingDealDetails(false)} deal={deal} onEdit={handleEditDeal} />
        ) : (
          <DealDetailsPreview deal={deal} />
        )}
      </Col>

      <Col lg={24} span={24}>
        <hr />
      </Col>

      {/* pricing Deals */}
      <Col lg={24} span={24}>
        <span className="title">{getText('pricingCoupons')}</span>
        {(isAdmin || !isRestrictedCategory) && (
          <Button type="default" css={editFormSectionBtn} onClick={handleEditPricingDeals}>
            {isEditingPricing ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
        {isEditingPricing ? (
          <PricingDealsEdit onCancel={() => setIsEditingPricing(false)} deal={deal} onEdit={handleEditDeal} />
        ) : (
          <PricingDealsPreview deal={deal} />
        )}
      </Col>

      <Col lg={24} span={24}>
        <hr />
      </Col>

      {/* promotion Details */}
      <Col lg={24} span={24}>
        <span className="title">{getText('promotionDetails')}</span>
        {(isAdmin || !isRestrictedCategory) && (
          <Button type="default" css={editFormSectionBtn} onClick={handleEditPromotionDetails}>
            {isEditingDates ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
        {isEditingDates ? (
          <PromotionDetailsEdit deal={deal} onEdit={handleEditDeal} onCancel={() => setIsEditingDates(false)} />
        ) : (
          <PromotionDetailsPreview deal={deal} />
        )}
      </Col>

      <Col lg={24} span={24}>
        <hr />
      </Col>

      {/* other */}
      <Col lg={24} span={24}>
        <span className="title">{getText('other')}</span>
        {(isAdmin || !isRestrictedCategory) && (
          <Button type="default" css={editFormSectionBtn} onClick={handleEditOther}>
            {isEditingOther ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
        {isEditingOther ? (
          <OtherEdit deal={deal} onEdit={handleEditDeal} onCancel={() => setIsEditingOther(false)} />
        ) : (
          <OtherPreview deal={deal} />
        )}
      </Col>

      <Col lg={24} span={24}>
        <hr />
      </Col>
    </Row>
  );
};

DealInformationTab.propTypes = {
  deal: PropTypes.object,
  onEditDeal: PropTypes.func,
  isAdmin: PropTypes.bool,
  isRestrictedCategory: PropTypes.bool,
};

export default DealInformationTab;
