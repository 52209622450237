import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { oneItemCarouselWrapper } from './styles';

const DealSkeleton = ({ hideInput, numberOfDeals }) => {
  const multipleDeals = () => {
    const dealsArray = [];
    for (let i = 0; i < numberOfDeals; i++) {
      dealsArray.push(
        <div key={i} css={oneItemCarouselWrapper}>
          <Skeleton.Image size="large" />
          {!hideInput && (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          )}
        </div>,
      );
    }
    return dealsArray;
  };
  return multipleDeals();
};
DealSkeleton.propTypes = {
  hideInput: PropTypes.bool,
  numberOfDeals: PropTypes.number,
};
export default DealSkeleton;
