import PropTypes from 'prop-types';
import moment from 'moment';
import { Page, Document, Image, Text, View } from '@react-pdf/renderer';
import { useTranslations } from '@veraio/strank';
import { styles } from './styles';

export const StatisticsPdfTemplate = ({
  imageSrc,
  totalDeals,
  totalMerchants,
  totalSalesStatsPerRegion,
  redeemedCoupons,
  fromDateSales,
  toDateSales,
}) => {
  const { getText } = useTranslations();
  const { container, header, overviewRow, overviewBox, imageStyles, footer } = styles;

  return (
    <Document>
      <Page style={container}>
        <View style={header}>
          <Text style={header.text}>{getText('statisticsOverview')}</Text>
          <Text style={header.date}>
            {moment(fromDateSales).format('DD-MM-yyyy')} - {moment(toDateSales).format('DD-MM-yyyy')}
          </Text>
        </View>
        <View style={overviewRow}>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{totalDeals}</Text>
            <Text style={overviewBox.text}>{getText('totalDeals')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{totalMerchants}</Text>
            <Text style={overviewBox.text}>{getText('totalMerchants')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{totalMerchants}</Text>
            <Text style={overviewBox.text}>{getText('totalSales')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{totalSalesStatsPerRegion}</Text>
            <Text style={overviewBox.text}>{getText('couponsRedeemed')}</Text>
          </View>
          <View style={overviewBox}>
            <Text style={overviewBox.stats}>{redeemedCoupons}</Text>
            <Text style={overviewBox.text}>{getText('totalStatsRegion')}</Text>
          </View>
        </View>
        <View>
          <Image style={imageStyles} src={imageSrc} />
        </View>
        <View>
          <Text style={footer.date}>{moment().format('DD-MM-yyyy')}</Text>
        </View>
      </Page>
    </Document>
  );
};

StatisticsPdfTemplate.propTypes = {
  imageSrc: PropTypes.any,
  totalDeals: PropTypes.number,
  totalMerchants: PropTypes.number,
  totalSalesStatsPerRegion: PropTypes.number,
  redeemedCoupons: PropTypes.number,
  currency: PropTypes.any,
  fromDateSales: PropTypes.string,
  toDateSales: PropTypes.string,
};
