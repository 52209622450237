import { theme } from 'styles';

export const addressBox = {
  padding: '1em',
  [theme.Desktop]: {
    boxShadow: '0px 0px 4px 1px #00000014',
  },
  [theme.Mobile]: {
    border: `2px solid ${theme.gray500}`,
  },

  '& .address-card-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    wordBreak: 'break-all',
  },

  '& .address-card-edit-button': {
    padding: '0.35em',
    marginLeft: '0.4em',
    '& i': {
      margin: 0,
    },
  },

  '& .address-card-separator': {
    margin: '0.5em 0 0.8em',
    color: theme.gray500,
  },

  '& .address-card-label': {
    fontSize: '0.8125rem',
    fontWeight: 500,
    color: theme.gray200,
    display: 'block',
    margin: '0.5em 0 0.2em',
  },

  '& .address-card-text': {
    margin: '0 0 0.625em',
  },
};

export const addressFormEdit = {
  '& .ant-form-item': {
    marginBottom: '1em',
  },
};

export const formDateAndTimeEdit = {
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
  '& .ant-picker': {
    width: '100%',
  },
};

export const phoneNumber = {
  display: 'flex',
  gap: '0.5em',
  justifyContent: 'space-between',
  width: '100%',
  '& .ant-row.ant-form-item:first-child': {
    width: '50%',
    display: 'inline-block',
    maxHeight: '2rem',
    maxWidth: '9.375rem',
    [theme.Mobile]: {
      width: '25%',
      minWidth: '5rem',
    },
  },
  '& .ant-row.ant-form-item:nth-child(2)': {
    width: '100%',
    display: 'inline-block',
    float: 'right',
  },
  '& .phone-number': {
    width: '100%',
    '& .ant-input-number-handler-wrap': { display: 'none' },
    '& .ant-input-number-input': { height: '2rem' },
  },
};

export const mandatoryStar = {
  marginRight: '0.25em',
  color: 'red',
  fontSize: '0.875rem',
  fontFamily: 'SimSun, sans-serif',
  lineHeight: 1,
};

export const editFormSectionBtn = {
  padding: '0.3em',
  minHeight: 'auto',
  marginLeft: '0.5em',
};

export const phoneCodeStyles = {
  fontSize: '0.875rem',
  lineHeight: '1.5715',
  height: '2.125rem',
};
