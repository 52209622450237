import { Table } from 'components/UIExternal';
import { getAllClearing } from 'services/api/transactions';
import { filterKeys, getClearingColumns, sortingKeys } from './config';
import { pageContainer, tableContainer } from '../../styles';
import { ClearingFilterBar, TransactionsTotalsPanel } from 'components/screens';
import { getText } from '@veraio/strank';
import { CLEARING_SORT_OPTIONS } from 'enums';

const ClearingTab = ({ pendingClearingSettlementAmounts = {} }) => {
  const {
    pendingClearingCoinWalletBalance,
    pendingClearingCashWalletBalance,
    pendingClearingOespWalletBalance,
    pendingSettlementCoinWalletBalance,
    pendingSettlementCashWalletBalance,
    pendingSettlementOespWalletBalance,
  } = pendingClearingSettlementAmounts;

  const totals = [
    {
      one: pendingClearingCoinWalletBalance,
      fiat: pendingClearingCashWalletBalance,
      oesp: pendingClearingOespWalletBalance,
      title: getText('awaitingClearing'),
    },
    {
      one: pendingSettlementCoinWalletBalance,
      fiat: pendingSettlementCashWalletBalance,
      oesp: pendingSettlementOespWalletBalance,
      title: getText('readyForSettlement'),
    },
  ];

  const initQueryParams = {
    [sortingKeys.sorting]: CLEARING_SORT_OPTIONS.clearingDateDesc,
  };

  return <section css={pageContainer}>
    <div data-id="merchant-office-clearing-tab-totals">
      <TransactionsTotalsPanel totalsData={totals} />
    </div>

    <div css={tableContainer} data-id="merchant-office-clearing-table">
      <Table
        getDataMethod={getAllClearing}
        columns={getClearingColumns()}
        filterBar={ClearingFilterBar}
        filterKeys={filterKeys}
        sortingKeys={sortingKeys}
        defaultQueryParams={initQueryParams}
      />
    </div>
  </section>;
};

export default ClearingTab;
