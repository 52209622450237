import { theme } from 'styles';

export const navigationContainer = {
  backgroundColor: theme.white,
  boxShadow: `0px 3px 3px ${theme.gray_rgba}`,
  position: 'sticky',
  top: 0,
  zIndex: 1000,
};

export const actionsPanel = {
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  justifyContent: 'flex-end',
};
