import { theme } from 'styles';

export const popup = {
  '& .ant-modal-footer': {
    border: 'none',
    display: 'flex',
    paddingLeft: '1.25em',
    paddingBottom: '1.25em',
  },
  '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)': {
    backgroundColor: theme.gray600,
    color: theme.gray100,
    border: 'none',
  },
  '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover': {
    backgroundColor: theme.gray600,
    color: theme.gray100,
  },
  '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active': {
    backgroundColor: theme.gray600,
    color: theme.gray100,
  },
  '& .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within': {
    boxShadow: 'none',
  },
  '& .ant-radio-button-wrapper': {
    fontSize: '0.75rem',
    border: 'none',
    color: theme.gray200,
    width: '100%',
    height: 'auto',
  },
  '& .ant-radio-button-wrapper:hover': {
    backgroundColor: theme.gray600,
    color: theme.gray100,
  },
  '& .ant-modal-content': {
    borderRadius: 8,
  },
};

export const popupContent = {
  display: 'flex',
  padding: '1.875em',
  paddingBottom: 0,
};

export const columnContainer = {
  margin: '1em 0',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};

export const columnTitle = {
  display: 'inline-block',
  marginBottom: '1em',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: '500',
};

export const columnListOptionsContainer = {
  maxHeight: 280,
  overflowY: 'scroll',
  flex: 1,
};

export const verticalSeparator = {
  width: 1,
  backgroundColor: theme.gray500,
  marginLeft: '1.25em',
  marginRight: '1.25em',
};

export const languageOption = {
  display: 'block',
  border: 'none',
};

export const cancelButton = {
  '&.ant-btn': {
    height: 'initial',
    padding: '0.625em 1.9375em',
  },
};
