import { Table } from 'components/UIExternal';
import { TransactionsFilterBar } from 'components/screens';
import { getAllTransactions } from 'services';
import { filterKeys, getPaymentsColumns } from './config';
import { pageContainer } from '../../styles';

const TransactionsTab = () => (
  <section css={pageContainer}>
    <Table
      getDataMethod={getAllTransactions}
      columns={getPaymentsColumns()}
      filterBar={TransactionsFilterBar}
      filterKeys={filterKeys}
    />
  </section>
);

export default TransactionsTab;
