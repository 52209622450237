import { Tooltip } from 'antd';
import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { USER_TYPE } from 'enums';
import { theme } from 'styles';

const renderLocations = (locations) => <h5 className="items">{!!locations?.length && locations.join(', ')}</h5>;

export const managersColumns = (options) =>
  [
    {
      name: getText('accountId'),
      value: 'feeAccountId',
      width: 120,
    },
    {
      name: getText('name'),
      value: 'name',
      flex: '1 0 0',
    },
    {
      name: getText('phoneNumber'),
      value: 'phone',
      flex: '1 0 0',
    },
    {
      name: getText('email'),
      value: 'email',
      flex: '1 0 0',
      render: (manager) => (
        <Tooltip title={manager.id} getPopupContainer={(triggerNode) => triggerNode}>
          <span>{manager.email}</span>
        </Tooltip>
      ),
    },
    {
      name: options.managerType === USER_TYPE.AREA_MANAGER ? getText('areas') : getText('countries'),
      flex: '2 0 0',
      render: (manager) =>
        renderLocations(
          options.managerType === USER_TYPE.AREA_MANAGER
            ? manager.areas.map((area) => area.areaName)
            : manager.countries.map((country) => country.name),
        ),
    },
    {
      name: getText('actions'),
      width: 80,
      flex: '0 1 auto',
      render: (manager) => (
        <>
          <Icon iconName="las la-pen" size={20} onClick={() => options.onEdit(manager)} />
          <Icon
            iconName="las la-trash"
            size={20}
            onClick={() => options.onDelete(manager)}
            style={{ color: theme.red, marginLeft: 10 }}
          />
        </>
      ),
    },
  ].filter(Boolean);
