import { useRef } from 'react';
import { Spin } from 'antd';
import { isBoolean } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useLocations, refetchCountries } from '@oneecosystem/dealshaker-core';
import { Table } from 'components/UIExternal';
import { CountryConfigurationFilterTab } from 'components/screens';
import { PageHeader } from 'components/shared';
import { showSuccessToast } from 'utils/showSuccessToast';
import { mockTableRes } from 'utils/mockTableRes';
import { updateCountryRestricted } from 'services';
import useError from 'services/errorHandling/useError';
import { countryConfigurationColumns } from './config';
import { container } from './styles';

const filterKeys = {
  name: 'name',
  isCryptoRestricted: 'isCryptoRestricted',
};

const CountryConfiguration = () => {
  const countries = useLocations((locationsState) => locationsState.countries);
  const { getText } = useTranslations();
  const { setError } = useError();
  const tableRef = useRef();

  const updateCryptoRestrictedCountry = async (countryId, isCryptoRestricted) => {
    const [, editError] = await updateCountryRestricted({ countryId, isCryptoRestricted });
    if (editError) return setError(editError);

    const refetchErr = await refetchCountries();
    if (refetchErr) return setError(refetchErr);

    showSuccessToast(getText('successfullyChangedCountryStatus'));
    tableRef.current.getData();
  };

  const filterResults = (data, options) => {
    let filteredData = [...data];

    if (options[filterKeys.name]) filteredData = filteredData.filter((el) => el?.id === options[filterKeys.name]);

    if (isBoolean(options[filterKeys.isCryptoRestricted]))
      filteredData = filteredData.filter((el) => el.isCryptoRestricted === options[filterKeys.isCryptoRestricted]);

    return filteredData;
  };

  return (
    <section css={container}>
      <PageHeader title={getText('countryConfiguration')} />
      {countries?.length ? (
        <Table
          ref={tableRef}
          getDataMethod={mockTableRes(countries, filterResults)}
          columns={countryConfigurationColumns(updateCryptoRestrictedCountry)}
          filterBar={CountryConfigurationFilterTab}
          filterKeys={filterKeys}
        />
      ) : (
        <Spin size="large" />
      )}
    </section>
  );
};

export default CountryConfiguration;
