import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import Empty from 'assets/images/statusProgressIcons/empty-gray-stroke.svg';
import Focus from 'assets/images/statusProgressIcons/empty-blue-stroke.svg';
import Success from 'assets/images/statusProgressIcons/checked-icn.svg';
import Warning from 'assets/images/statusProgressIcons/warning-icn.svg';
import Error from 'assets/images/statusProgressIcons/error-icn.svg';
import { iconContainer } from './styles';

const StatusProgressIcon = ({ customClassName, status }) => {
  const renderStatusIcon = () => {
    let icon;

    switch (status) {
      case 'empty':
        icon = <img src={Empty} alt="Empty checkbox" />;
        break;
      case 'focus':
        icon = <img src={Focus} alt="Focused checkbox" />;
        break;
      case 'success':
        icon = <img src={Success} alt="Success checkbox" />;
        break;
      case 'warning':
        icon = <img src={Warning} alt="Warning checkbox" />;
        break;
      case 'error':
        icon = <img src={Error} alt="Error checkbox" />;
        break;
      default:
        icon = <img src={Empty} alt="Empty checkbox" />;
    }

    return icon;
  };

  return (
    <div css={iconContainer} {...(isString(customClassName) && { className: customClassName })}>
      {renderStatusIcon()}
    </div>
  );
};

StatusProgressIcon.propTypes = {
  status: PropTypes.string,
  customClassName: PropTypes.string,
};

export default StatusProgressIcon;
