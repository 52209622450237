import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Col } from 'antd';
import { othersWrapper, othersFeaturesWrapper } from './styles';

const OtherPreview = ({ deal }) => {
  const { getText } = useTranslations();

  return (
    <div css={othersWrapper}>
      <Col lg={4} span={24}>
        <span className="subtitle">{getText('predefinedTerms')}</span>
      </Col>
      <div css={othersFeaturesWrapper}>
        {deal.appointmentInAdvance && (
          <Col lg={20} span={24}>
            <span className="content">{getText('appointmentInAdvance')}</span>
          </Col>
        )}
        {deal.extendCouponValidity && (
          <Col lg={{ span: 20 }} span={24}>
            <span className="content">{getText('extendCouponValidity')}</span>
          </Col>
        )}
        {deal.mandatoryPaperCopy && (
          <Col lg={{ span: 20 }} span={24}>
            <span className="content">{getText('mandatoryPaperCopy')}</span>
          </Col>
        )}
        {deal.promotionValidForAllProducts && (
          <Col lg={{ span: 20 }} span={24}>
            <span className="content">{getText('promotionValidForAllProducts')}</span>
          </Col>
        )}
        {deal.singlePurchasePerUser && (
          <Col lg={{ span: 20 }} span={24}>
            <span className="content">{getText('singlePurchasePerUser')}</span>
          </Col>
        )}
      </div>
    </div>
  );
};

OtherPreview.propTypes = {
  deal: PropTypes.object,
};

export default OtherPreview;
