import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'components/UIExternal';

export const FormBoxLoader = ({ number }) =>
  Array(number ?? 1)
    .fill()
    .map((_, index) => (
      <Fragment key={`formBoxLoader${index}`}>
        <Skeleton height="1rem" width="6rem" marginTop="1.5em" />
        <Skeleton height="1.8rem" width="100%" marginTop="0.2em" />
      </Fragment>
    ));

FormBoxLoader.propTypes = {
  number: PropTypes.number,
};
