import ChatMiniPanel from 'components/layouts/Header/components/ChatMiniPanel';
import { NotificationsMiniPanel, UserMiniProfile } from './components';
import { actionsPanel } from './styles';

const UserActionPanel = () => {
  return (
    <div css={actionsPanel}>
      <ChatMiniPanel />
      <NotificationsMiniPanel />
      <UserMiniProfile />
    </div>
  );
};

export default UserActionPanel;
