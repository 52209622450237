import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    padding: '1.5em',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '2em',
    text: {
      fontSize: '2rem',
      color: '#222',
    },
    date: {
      fontSize: '1rem',
      textAlign: 'right',
    },
  },
  overviewRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  overviewBox: {
    border: '1px solid gray',
    borderRadius: 3,
    padding: '0.5em',
    width: '6.5rem',
    marginBottom: '4em',
    stats: {
      marginBottom: '0.375em',
      fontSize: '1rem',
      fontWeight: 500,
    },
    text: {
      fontSize: '0.625rem',
    },
  },
  footer: {
    date: {
      fontSize: '0.5rem',
      textAlign: 'right',
    },
  },
});
