import PropTypes from 'prop-types';
import { Col } from 'antd';
import { Icon } from 'components/ui';
import { TextChanged } from 'components/UIExternal';
import { businessDetailsWrapper, historyChanges } from './styles';

const ContactInformationPreview = ({ business, textChange }) => {
  return (
    <>
      <Col span={24} lg={4}>
        <div css={businessDetailsWrapper}>
          <Icon iconName="las la-phone-alt" />
          <TextChanged {...textChange('phone')} containerStyles={historyChanges} />
        </div>
      </Col>
      <Col span={24} lg={6}>
        <div css={businessDetailsWrapper}>
          <Icon iconName="las la-envelope" />
          <TextChanged {...textChange('email')} containerStyles={historyChanges} />
        </div>
      </Col>
      {business?.website && (
        <Col span={24} lg={9}>
          <div css={businessDetailsWrapper}>
            <Icon iconName="las la-globe" />
            <TextChanged {...textChange('website')} containerStyles={historyChanges} />
          </div>
        </Col>
      )}
    </>
  );
};

ContactInformationPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
  textChange: PropTypes.func,
};

export default ContactInformationPreview;
