import { createStore, useStore } from '@veraio/core';
import { cloneDeep } from 'lodash-es';
import { setError, getOrdersList } from 'services';
import { orderDeliveryDetails, orderPaymentDetails } from 'utils';

const initState = {
  orderList: null,
  selectedOrder: null,
};

const ordersStore = createStore(initState);

export const initOrdersStore = (state) => {
  ordersStore.setState((prev) => ({ ...prev, ...(state ?? {}) }));
};

export const resetOrdersStore = () => ordersStore.setState(initState);

export const fetchOrders = async (options) => {
  const [res, err] = await getOrdersList(options);
  err
    ? setError(err)
    : ordersStore.setState((prev) => ({
        ...prev,
        orderList: res.items,
        selectedOrder: cloneDeep(prev?.selectedOrder ?? res.items?.at(0)),
      }));

  return res;
};

export const selectOrder = (selectedOrder) => {
  ordersStore.setState((prev) => ({ ...prev, selectedOrder: cloneDeep(selectedOrder) }));
};

export const selectedOrderDeliveryDetails = () => {
  const { selectedOrder } = ordersStore.getState();
  const { delivery } = selectedOrder ?? {};

  return orderDeliveryDetails(delivery);
};

export const selectedOrderPaymentDetails = () => {
  const { selectedOrder } = ordersStore.getState();
  const { payment } = selectedOrder ?? {};

  return orderPaymentDetails(payment);
};

export const useOrders = (callback) => useStore(ordersStore, callback);
