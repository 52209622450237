import { theme } from 'styles';

export const galleryModal = {
  '& .ant-modal-content': {
    [theme.Desktop]: { padding: '0 4.625em' },
    [theme.Mobile]: { padding: '0 0.25em' },
  },
  '& .ant-modal-body': { padding: '0.625em 0' },
  '& .image-gallery-slide': {
    width: '100%',
    [theme.Desktop]: { height: '41.25rem' },
    [theme.Mobile]: { height: '31.25rem' },
    backgroundColor: theme.gray500,
    paddingBottom: '1',
    borderRadius: 4,
  },
  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  '& .image-gallery-right-nav': {
    padding: 0,
    filter: 'none',
    right: -55,
    [theme.Mobile]: { right: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
  '& .image-gallery-left-nav': {
    filter: 'none',
    padding: 0,
    left: -55,
    [theme.Mobile]: { left: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
};

export const youTubePlayIcon = {
  position: 'relative',
  '& .la-play': {
    position: 'absolute',
    background: theme.gray_rgba,
    color: 'white',
    left: '1.875rem',
    top: '1.25rem',
    borderRadius: '50%',
    height: '1.25rem',
    width: '1.25rem',
  },
};

export const video = {
  width: '100% !important',
  height: '100% !important',
  position: 'absolute',
  left: 0,
  top: 0,
  '& iframe': {
    height: '100%',
    width: '100%',
  },
};

export const dealImages = {
  display: 'flex',
  gap: '2rem',
};

export const dealImgContainer = (isHidden) => ({
  width: '10rem',
  height: '10rem',
  position: 'relative',
  img: {
    position: 'static',
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  '&:hover .overlay': {
    backgroundColor: `${theme.gray100}40`,
    opacity: 1,
    cursor: 'pointer',
  },
  '& .overlay': {
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 0.5s',
    position: 'absolute',
    content: '""',
    height: '100%',
    width: '100%',
    '& i': {
      color: theme.white,
    },
  },
  ...(isHidden && {
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      background: `${theme.gray600}90`,
      zIndex: 1,
      opacity: 1,
      transition: 'opacity 0.3s ease',
      pointerEvents: 'none',
    },
    '&:hover': {
      '&:before': {
        content: "''",
        opacity: 0,
      },
    },
  }),
});
