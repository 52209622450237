import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Menu, Checkbox, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { getAllCategories } from 'services';
import useError from 'services/errorHandling/useError';
import {
  categoriesFilterContent,
  checkbox,
  menuItem,
  actionButtonStyle,
  arrowStyle,
  checkboxContainer,
} from './styles';

export const CategoryFilter = ({ onChange, value }) => {
  const { setError } = useError();
  const { getText } = useTranslations();
  const { SubMenu } = Menu;
  const [allCategories, setAllCategories] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const mappedValue = value?.map((el) => Number(el)) ?? [];
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(mappedValue ?? []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    err ? setError(err) : setAllCategories(res);
  };

  const handleChange = (categoryId) => {
    const newCategoryIds = selectedCategoryIds.includes(categoryId)
      ? selectedCategoryIds.filter((el) => el !== categoryId)
      : [...selectedCategoryIds, categoryId];
    setSelectedCategoryIds(newCategoryIds);
    onChange(newCategoryIds);
  };

  const addSubMenuLevel = (category) => (
    <SubMenu
      key={category.id}
      css={menuItem}
      disabled={category.dealsCount < 1}
      title={
        <div css={checkboxContainer}>
          <Checkbox
            css={checkbox}
            checked={selectedCategoryIds?.includes(category.id)}
            onChange={() => handleChange(category.id)}>
            <Button
              type="link"
              small
              disabled={category.dealsCount < 1}
              css={actionButtonStyle}
              onClick={() => handleChange(category.id)}>
              {getText(category.name)}
            </Button>
          </Checkbox>
          <Button
            type="link"
            small
            disabled={category.dealsCount < 1}
            onClick={() => {
              setOpenKeys(
                openKeys.indexOf(category.id.toString()) > -1
                  ? openKeys.filter((k) => k !== category.id.toString())
                  : [openKeys[0], ...[category.id.toString()]],
              );
            }}
            css={arrowStyle}>
            <Icon
              iconName="las la-angle-right"
              className={openKeys.includes(category.id.toString()) && isMobile ? 'isExpanded' : ''}
            />
          </Button>
        </div>
      }>
      {category.subCategories &&
        category.subCategories.length > 0 &&
        category.subCategories.map((subCategory) => (
          <Menu.Item css={menuItem} key={subCategory.id} disabled={subCategory.dealsCount < 1}>
            <Checkbox
              css={checkbox}
              checked={selectedCategoryIds?.includes(subCategory.id)}
              onChange={() => handleChange(subCategory.id)}>
              <Button
                type="link"
                small
                disabled={subCategory.dealsCount < 1}
                onClick={() => handleChange(subCategory.id)}
                css={actionButtonStyle}>
                {getText(subCategory.name)}
              </Button>
            </Checkbox>
          </Menu.Item>
        ))}
    </SubMenu>
  );

  return allCategories.length ? (
    <Menu
      css={categoriesFilterContent}
      mode={isMobile ? 'inline' : 'vertical'}
      theme="light"
      openKeys={isMobile ? openKeys : undefined}
      expandIcon={<></>}
      triggerSubMenuAction="hover">
      {allCategories?.map((category) =>
        category.subCategories && category.subCategories.length > 0 ? (
          <SubMenu
            key={category.id}
            css={menuItem}
            disabled={category.dealsCount < 1}
            title={
              <div className="flex space-between">
                <Checkbox
                  css={checkbox}
                  checked={selectedCategoryIds?.includes(category.id)}
                  onChange={() => handleChange(category.id)}>
                  <Button
                    type="link"
                    small
                    disabled={category.dealsCount < 1}
                    onClick={() => handleChange(category.id)}
                    css={actionButtonStyle}>
                    {getText(category.name)}
                  </Button>
                </Checkbox>
                <Button
                  type="link"
                  small
                  disabled={category.dealsCount < 1}
                  onClick={() => {
                    setOpenKeys(category.id.toString() === openKeys[0] ? [] : [category.id.toString()]);
                  }}
                  css={actionButtonStyle}>
                  <Icon
                    iconName="las la-angle-right"
                    className={openKeys.includes(category.id.toString()) && isMobile ? 'isExpanded' : ''}
                  />
                </Button>
              </div>
            }>
            {category.subCategories &&
              category.subCategories.length > 0 &&
              category.subCategories.map((subCategory) =>
                subCategory.subCategories && subCategory.subCategories.length > 0 ? (
                  addSubMenuLevel(subCategory)
                ) : (
                  <Menu.Item css={menuItem} key={subCategory.id} disabled={subCategory.dealsCount < 1}>
                    <Menu.Item key={subCategory.id} disabled={subCategory.dealsCount < 1}>
                      <Checkbox
                        css={checkbox}
                        checked={selectedCategoryIds?.includes(subCategory.id)}
                        onChange={() => handleChange(subCategory.id)}>
                        <Button
                          type="link"
                          small
                          disabled={subCategory.dealsCount < 1}
                          onClick={() => handleChange(subCategory.id)}
                          css={actionButtonStyle}>
                          {getText(subCategory.name)}
                        </Button>
                      </Checkbox>
                    </Menu.Item>
                  </Menu.Item>
                ),
              )}
          </SubMenu>
        ) : (
          <Menu.Item key={category.id}>
            {getText(category.name)} {category.dealsCount}
          </Menu.Item>
        ),
      )}
    </Menu>
  ) : (
    <Spin size="large" />
  );
};

CategoryFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
};
