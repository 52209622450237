import { theme } from 'styles';

export const search = {
  border: 'none !important',
};

export const searchResultWrapper = {
  borderRadius: '10px !important',
};

export const noMessagesBox = {
  marginTop: '5em',
  textAlign: 'center',
  '& .date': { fontSize: '0.75rem' },
  '& img': { marginTop: '4.375em' },
  '& .start': { fontSize: '1.125rem', fontWeight: 700, marginBottom: 0 },
  '& .ask-anything': { fontSize: '0.875rem', marginBottom: '3.75em' },
};

export const uploadBtn = {
  width: '100%',
  height: '3.625rem',
  justifyContent: 'start',
  paddingLeft: '0.625em',
  borderRadius: 3,
  padding: '0.625em',
  color: theme.gray200,
  backgroundColor: theme.gray600,
  '& .la-paperclip': { fontSize: '1.25rem' },
  '& div': { alignItems: 'start' },
  '& .click-here-lbl': { fontSize: '0.625rem', color: theme.gray100 },
  '& .attach-text-lbl': { fontSize: '0.625rem', color: theme.gray200 },
};

export const participantSelector = {
  marginTop: '1.25em',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.25em 1.25em 0 1.25em',
  '& p': { margin: '0 0 0 0.625em', fontSize: '1rem' },
  '& .wrapper': { alignItems: 'center', marginBottom: '1.0625em' },
};

export const imagesPreview = {
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-actions a': { display: 'none' },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [theme.Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [theme.Mobile]: { width: '6.25rem', height: '5rem' },
  },
};

export const bigLinkBtnStyle = {
  fontSize: '1rem',
  fontWeight: 700,

  '& > span': {
    marginRight: '0.75em',
    fontSize: '1.375rem',
  },
};
