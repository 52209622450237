import { useTranslations } from '@veraio/strank';
import { isNil, pick } from '@veraio/core';
import { DEFAULT_CRYPTO_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { useOrders } from 'stores';
import { PAYMENT_METHODS_CURRENCIES } from 'enums';
import { LoadingOrderDetailsDeals } from './LoadingOrderDetailsDeals';
import { orderDetailsDealsContainer, orderDetailsDealLink } from './styles';
import { PriceLabel } from 'components/shared';

export const OrderDetailsDeals = () => {
  const { getText } = useTranslations();
  const { orderItems, currencyCode, payment } = useOrders((ordersState) =>
    pick(ordersState?.selectedOrder, ['orderItems', 'currencyCode', 'payment']),
  );

  return (
    <>
      <h3 className="order-details-section-title">{getText('orderedDeals')}</h3>
      <ul css={orderDetailsDealsContainer} className="order-details-section">
        {isNil(orderItems) ? (
          <LoadingOrderDetailsDeals />
        ) : (
          orderItems?.map((orderItem) => (
            <li key={orderItem?.name} className="order-details-deal-container">
              <div className="order-details-deal-info">
                <Button type="link" linkTo={`/deal/${orderItem?.id}`} css={orderDetailsDealLink}>
                  <img src={orderItem?.media} className="order-details-deal-image" alt={orderItem?.name} />
                  {orderItem?.title}
                </Button>
              </div>
              <div className="order-details-deal-quantity">
                <h5 className="gray">{getText('quantity')}</h5>
                <p>{orderItem?.quantity}</p>
              </div>
              <div className="order-details-deal-price">
                <h5 className="gray">{getText('price')}</h5>
                <p>
                  <PriceLabel
                    fiat={orderItem?.priceFiat}
                    crypto={orderItem?.priceCrypto}
                    fiatOriginCurrency={currencyCode}
                    fiatDesiredCurrency={currencyCode}
                    cryptoDesiredCurrency={
                      (PAYMENT_METHODS_CURRENCIES[payment?.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY)?.code
                    }
                    fiatOnly={false}
                  />
                </p>
              </div>
            </li>
          ))
        )}
      </ul>
    </>
  );
};
