import { getText, getDynamicTranslation } from '@veraio/strank';
import moment from 'moment';
import { Icon } from 'components/ui';
import { Tag } from 'components/UIExternal';
import { Button } from 'components/UIExternal';
import { businessStatusInverse, businessStatusColor } from 'enums';
import { textLinkStyle, actionStyle } from './styles';

// businessesColumns should be function because it has getText inside which throw error if it is used before configuration
export const businessesColumns = () => [
  {
    name: getText('name'),
    render: (el) => (
      <Button type="link" linkTo={`/business/${el.id}`} css={textLinkStyle}>
        {getDynamicTranslation(el.name)?.name}
      </Button>
    ),
  },
  {
    name: getText('status'),
    render: (el) => <Tag type={businessStatusColor[el.statusId]}>{getText(businessStatusInverse[el.statusId])}</Tag>,
    width: 100,
  },
  {
    name: getText('email'),
    value: 'merchantEmail',
  },
  {
    name: getText('nameNickname'),
    render: (el) => <>{`${el.modifiedBy} - ${el.merchantUsername}`}</>,
  },
  {
    name: getText('launchDate'),
    render: (el) => <span>{moment(el.submittedDate).format('MMM DD,YYYY')}</span>,
    width: 200,
  },
  {
    name: getText('modified'),
    render: (el) => <span>{moment(el.modifiedDate).format('MMM DD,YYYY,h:mm:ss')}</span>,
    ellipsis: true,
    width: 200,
  },
  {
    key: 'action',
    render: (el) => (
      <Button type="link" linkTo={`/business/${el?.id}`} small css={actionStyle}>
        <Icon iconName="lar la-eye" />
      </Button>
    ),
    width: 40,
  },
];
