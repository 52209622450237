import { css } from '@emotion/react';
import 'assets/icons/line-awesome/line-awesome.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'antd/dist/antd.css';
import { theme } from './theme';

export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tagalog&display=swap');

  :root {
    font-size: 16px;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin-right: calc(100% - 100vw);
  }

  body {
    font-family: Roboto, Noto Sans Tagalog, sans-serif;
    font-weight: 400;
    color: #222222;
    color: ${theme.textLightPrimary};
    overflow-x: hidden;
    margin: 0;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: none;
    scroll-behavior: smooth;
  }

  :after,
  :before {
    box-sizing: border-box;
  }
  p,
  figure,
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }
  a {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    color: inherit;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  a:focus,
  a:hover {
    text-decoration: none;
  }
  a:focus {
    outline: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
  img {
    border: 0;
  }
  hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }
  pre {
    overflow: auto;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
  }
  button,
  select {
    overflow: visible;
    text-transform: none;
    background-color: transparent;
    border: none;
  }
  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  button label {
    cursor: inherit;
  }
  input {
    line-height: normal;
  }
  input[type='checkbox'],
  input[type='radio'] {
    padding: 0;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  input[type='search'] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  textarea {
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
  }
  p {
    margin: 0;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    outline: none;
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.8rem;
  }
  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  img {
    vertical-align: middle;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  hr {
    border: 0;
    border-top: 1px solid #eee;
  }
  [role='button'] {
    cursor: pointer;
  }

  a.ant-btn {
    padding: 4px 15px !important;
  }

  .ant-tabs {
    overflow: visible;
  }

  .ant-tooltip-inner {
    color: ${theme.white};
    background-color: ${theme.gray200};
  }

  .content-container {
    ${theme.Desktop} {
      max-width: 1664px;
    }
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .inner-content-container {
    padding: 30px;
    width: 100%;
  }
  .flex {
    display: flex;
    flex-direction: 'row';
    align-items: 'center';
    justify-content: 'flex-start';
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .flex-center {
    align-items: center;
    justify-content: center;
  }
  .flex-end {
    justify-content: flex-end;
  }
  .space-between {
    justify-content: space-between;
  }
  .align-center {
    align-items: center;
  }
  .section-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 6px;
  }
  .section-subtitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .bold-text {
    font-weight: 500;
  }
  .bolder-text {
    font-weight: 700;
  }
  .line-height-1 {
    line-height: 1;
  }

  .np {
    padding: 0;
  }

  .nm {
    margin: 0;
  }
`;

export const mb = (mar) => ({
  marginBottom: mar.lg,
  [theme.Mobile]: { marginBottom: mar.span },
});

export const mr = (mar) => ({
  marginRight: mar.lg,
  [theme.Mobile]: { marginRight: mar.span },
});

export const ml = (mar) => ({
  marginLeft: mar.lg,
  [theme.Mobile]: { marginLeft: mar.span },
});

export const mt = (mar) => ({
  marginTop: mar.lg,
  [theme.Mobile]: { marginTop: mar.span },
});
