import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Collapse } from 'antd';
import { DatePicker, TextInput } from 'components/ui';
import { useUrlParams } from 'components/UIExternal';
import { PageHeader } from 'components/shared';
import { businessAddressStatusSelect, businessAddressStatusColor, businessAddressStatusInverse } from 'enums';
import { pageFilterCollapse } from 'styles';

export const AddressesFilterBar = (props) => {
  const { paginationRef, filterKeys, defaultQueryParams } = props;
  const { queryParams } = useUrlParams();
  const queryStatus = queryParams[filterKeys.status] && queryParams[filterKeys.status].map(Number);

  return (
    <>
      <PageHeader
        title={getText('addresses')}
        statusProps={{
          defaultValue: queryStatus ?? defaultQueryParams[filterKeys.status],
          onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.status]: value }),
          options: businessAddressStatusSelect(),
          tagProps: { type: businessAddressStatusColor, value: businessAddressStatusInverse },
        }}
      />
      <Collapse css={pageFilterCollapse}>
        <Collapse.Panel header={getText('filters')}>
          <section className="filters-container">
            <div>
              <label>{getText('keyword')}</label>
              <TextInput
                showDelete
                placeholder={getText('searchByKeyword')}
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.search]: val })}
                defaultValue={queryParams[filterKeys.search]}
              />
            </div>
            <div>
              <label>{getText('businessNames')}</label>
              <TextInput
                showDelete
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.business]: val })}
                placeholder={getText('searchByBusinessName')}
                defaultValue={queryParams[filterKeys.business]}
              />
            </div>

            <div className="flex-auto">
              <h5>{getText('submittedDate')}</h5>
              <div className="filters-date-pickers">
                <div>
                  <p>{getText('from')}</p>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.creationDateFrom]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.creationDateFrom]}
                  />
                </div>

                <div>
                  <p>{getText('to')}</p>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.creationDateTo]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.creationDateTo]}
                  />
                </div>
              </div>
            </div>

            <div className="flex-auto">
              <h5>{getText('lastModified')}</h5>
              <div className="filters-date-pickers">
                <div>
                  <p>{getText('from')}</p>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.modifiedDateFrom]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.modifiedDateFrom]}
                  />
                </div>

                <div>
                  <p>{getText('to')}</p>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.modifiedDateTo]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.modifiedDateTo]}
                  />
                </div>
              </div>
            </div>
          </section>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

AddressesFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
  defaultQueryParams: PropTypes.object,
};
