import queryString from 'query-string';
import { isNil, isNumber } from 'lodash-es';
import { calculateDealPrice } from '@oneecosystem/dealshaker-core';

export const getAllDealsParams = (options) => {
  const {
    statusIds,
    sort = { sortField: 'startDate', sortOption: 'asc' },
    categoryIds,
    searchText,
    phone,
    username,
    email,
    countryIds,
    creationDateFrom,
    creationDateTo,
    publishDateFrom,
    publishDateTo,
    modifiedDateFrom,
    modifiedDateTo,
    currencyId,
    languageId,
    priceValue,
    priceSign,
    pageSize,
    pageNumber,
  } = options;

  const mapSort = (newSort) => ({
    sortField: newSort.sortField === 'modifiedDate' ? 'updatedDate' : newSort.sortField,
    sortOption: newSort.sortOption,
  });

  return queryString.stringify(
    {
      ...(pageNumber && { pageNumber }),
      ...(pageSize && { pageSize }),
      ...(sort && mapSort(sort)),
      ...(!isNil(statusIds) && { statusIds }),
      ...(!isNil(categoryIds) && { categoryIds }),
      ...(searchText && searchText?.length && { searchText }),
      ...(phone && String(phone)?.length && { phone }),
      ...(username && username?.length && { username }),
      ...(email && email?.length && { email }),
      ...(!isNil(countryIds) && { countryIds }),
      ...(!isNil(creationDateFrom) && { creationDateFrom }),
      ...(!isNil(creationDateTo) && { creationDateTo }),
      ...(!isNil(publishDateFrom) && { publishDateFrom }),
      ...(!isNil(publishDateTo) && { publishDateTo }),
      ...(!isNil(modifiedDateFrom) && { modifiedDateFrom }),
      ...(!isNil(modifiedDateTo) && { modifiedDateTo }),
      ...(!isNumber(currencyId) && { currencyId }),
      ...(!isNumber(languageId) && { languageId }),
      ...(isNumber(priceValue) && { priceValue }),
      ...(!isNil(priceSign) && isNumber(priceValue) && { priceSign }),
    },
    { arrayFormat: 'index' },
  );
};

export const getDealCommentsParams = (options) => {
  const {
    // sort = { sortField: 'startDate', sortOption: 'asc' },
    pageSize,
    pageNumber,
  } = options;

  // const mapSort = (newSort) => ({
  //   sortField: newSort.sortField === 'modifiedDate' ? 'updatedDate' : newSort.sortField,
  //   sortOption: newSort.sortOption,
  // });

  return queryString.stringify(
    {
      ...(pageNumber && { pageNumber }),
      ...(pageSize && { pageSize }),
      // ...(sort && mapSort(sort)),
    },
    { arrayFormat: 'index' },
  );
};

export const getAllDealsBody = (res) => res && { ...res, items: res.items.map((el) => calculateDealPrice(el)) };

export const getDealByIdBody = (res) => res && calculateDealPrice(res);
