import PropTypes from 'prop-types';
import { mul, div, sub } from 'exact-math';
import { pick } from 'lodash-es';
import { Col, Form, InputNumber, Slider } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, cryptoSign, findFiatCurrency } from '@oneecosystem/dealshaker-core';
import { FormEditButtons, PriceLabel } from 'components/shared';
import { formStyles, formDateStyle, pricePicker, sliderText } from './styles';
import { Dropdown } from 'components/UIExternal';
import { dealRiskLevelSelect } from 'enums';

const minOneCoinPercent = 10;

const PricingDealsEdit = ({ deal, onCancel, onEdit }) => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();
  useCurrencies((currenciesState) => currenciesState.fiatCurrencies);
  const totalPrice = Form.useWatch('price', form) ?? 0;
  const percentRatio = Form.useWatch('percentRatio', form) ?? minOneCoinPercent;
  const businessCurrency = findFiatCurrency(deal?.currencyCode);
  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  // TODO: REMOVE DISCOUNT FIELD
  // const calculateDiscountedPrice = (price, discountPercent) => (price * (1 - discountPercent / 100))?.toFixed(2);
  const calculatePrice = () => {
    const price = totalPrice || 0;
    const percent = percentRatio ?? minOneCoinPercent;

    return (
      <PriceLabel
        fiat={mul(price, div(sub(100, percent), 100))}
        fiatOriginCurrency={deal?.currencyCode}
        fiatDesiredCurrency={deal?.currencyCode}
        fiatCrypto={mul(price, div(percent, 100))}
        fiatOnly={false}
      />
    );
  };

  const handleSaveForm = async (values) => {
    await onEdit(values, ['price', 'discount', 'percentRatio', 'couponQuantity', 'paymentClearingRisk']);
    onCancel();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={pick(deal, 'price', 'discount', 'percentRatio', 'couponQuantity', 'paymentClearingRisk')}
      onFinish={handleSaveForm}
      css={formStyles}>
      <Form.Item
        label={getText('fullPrice')}
        name="price"
        style={{ width: '40%' }}
        rules={[mandatoryRule]}
        css={formDateStyle}>
        <InputNumber
          type="number"
          placeholder="Edit full price"
          style={{ width: '100%' }}
          min={0.1}
          addonBefore={businessCurrency?.symbol}
        />
      </Form.Item>
      <Form.Item
        label={getText('discount')}
        name="discount"
        style={{ width: '40%' }}
        rules={[mandatoryRule]}
        css={formDateStyle}>
        <InputNumber
          type="number"
          placeholder="Edit discount"
          min="0"
          max="100"
          style={{ width: '100%' }}
          addonBefore="%"
        />
      </Form.Item>
      {/* here is the slider */}
      <Col span={24} totalWidth={14}>
        <div css={pricePicker}>
          <label>{getText('percentPaidWithOne', { price: cryptoSign() })}</label>
          <h5>{calculatePrice()}</h5>
          <Form.Item name="percentRatio">
            <Slider
              tooltipPlacement="bottom"
              tooltipVisible
              min={minOneCoinPercent}
              marks={{ [minOneCoinPercent]: `${minOneCoinPercent}%`, 100: '100%' }}
            />
          </Form.Item>
          <p css={sliderText}>
            {getText('adjustYourCouponPriceRatio', {
              start: cryptoSign(),
              end: cryptoSign(),
            })}
          </p>
        </div>
      </Col>
      <Form.Item
        label={getText('quantityOfCoupons')}
        name="couponQuantity"
        style={{ width: '40%' }}
        css={formDateStyle}
        rules={[mandatoryRule]}
        // rules={[
        //   {
        //     required: true,
        //     message: getText('pleaseSpecifyCouponQuantity'),
        //   },
        //   ({ getFieldValue }) => ({
        //     validator(_, value) {
        //       if (!value || getFieldValue('couponsQuantity') >= 1) return Promise.resolve();

        //       return Promise.reject(getText('couponShouldBeMoreZero'));
        //     },
        //   }),
        // ]}
      >
        <InputNumber
          type="number"
          placeholder="Edit quantity of deals"
          style={{ width: '100%' }}
          min={1}
          addonBefore={<PieChartOutlined />}
        />
      </Form.Item>
      <Form.Item
        name="paymentClearingRisk"
        data-id="paymentClearingRisk"
        label={getText('dealRiskLevel')}
        style={{ width: '40%' }}
        css={formDateStyle}>
        <Dropdown
          noClear
          small
          placeholder={getText('dealRiskLevel')}
          options={dealRiskLevelSelect()}
          mapValue={(el) => el?.value}
        />
      </Form.Item>
      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

PricingDealsEdit.propTypes = {
  deal: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default PricingDealsEdit;
