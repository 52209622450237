import { theme } from 'styles';

export const pageContainer = {
  padding: '0 2em',
};

export const linkBtnStyle = {
  padding: 0,
  color: theme.primary_blue,
};

export const titleStyle = {
  fontSize: '1.5625rem',
  margin: '0.625em 0',
};

export const statisticsContainer = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  gridGap: '5%',
  marginBottom: '3.125em',
};

export const block = {
  border: `1px solid ${theme.gray500}`,
  borderRadius: 5,
  padding: '0.625em',
};

export const dataNumbers = {
  fontSize: '2.125rem',
  fontWeight: 500,
};

export const totalStyles = {
  marginRight: '0.625em',
};
