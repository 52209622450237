export const categoryTreeItem = {
	display: 'flex',
	alignItems: 'center',
	width: '100%'
};

export const title = {
	width: '100%'
};

export const actions = {
	marginLeft: 'auto',
	display: 'flex',
	whiteSpace: 'nowrap',
	alignItems: 'center',
	gap: '0.5rem'
};