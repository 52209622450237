import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Spin } from 'antd';
import { toast } from 'react-hot-toast';
import { TabPane, Tabs, Icon, DeclineModal, CommentModal } from 'components/ui';
import { Button, Tag } from 'components/UIExternal';
import { businessStatus, businessStatusColor, businessStatusInverse } from 'enums';
import { useParamsInt } from 'utils';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { omit, isString } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { BusinessInformationTab, BusinessStatusHistoryTab } from './Tabs';
import { CommentsList } from 'components/shared';
import { getBusinessById, updateBusinessStatus, addCommentToBusiness, updateBusinessDetails, setError } from 'services';
import { navigationWrapper } from './styles';

import { tabs, spinner } from './styles';

const BusinessDetail = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const history = useHistory();
  const businessId = useParamsInt('businessId');
  const [business, setBusiness] = useState();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const isEdited = business?.currentBusinessInformation?.statusId === businessStatus.edited;
  const businessData = isEdited ? business?.newBusinessInformation : business?.currentBusinessInformation;

  useEffect(() => {
    fetchBusinessDetails();
  }, [businessId]);

  const fetchBusinessDetails = async () => {
    const [res, err] = await getBusinessById(businessId);
    err ? setError(err) : setBusiness(res);
  };

  const handleEditBusinessStatus = async (status, reason) => {
    const [, err] = await updateBusinessStatus({ id: businessId, status, reason });
    err ? setError(err) : fetchBusinessDetails();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToBusiness = async (comment) => {
    const [, err] = await addCommentToBusiness({ id: businessId, comment });
    err ? setError(err) : fetchBusinessDetails();
    setIsCommentModalVisible(false);
  };

  const handleEditBusiness = async (values, keysToOmit) => {
    const requestData = {
      ...omit(businessData, keysToOmit),
      ...values,
    };

    const body = {
      ...omit(
        requestData,
        'acceptBankTransfers',
        'acceptCryptoPayment',
        'area',
        'country',
        'bankName',
        'bitpayWalletAddress',
        'coinbaseWalletAddress',
        'iban',
        'media',
        'preferredCurrencyId',
        'name',
        'statusId',
        'typeId',
      ),

      countryId: isString(requestData?.country)
        ? countries.find((country) => country.name === requestData.country)?.id
        : requestData?.country?.id,
      merchantTypeId: requestData?.typeId,
    };

    const [, err] = await updateBusinessDetails(business?.id, body);
    if (err) return setError(err);
    toast.success(getText('successfullyEditedBusiness'));
    await fetchBusinessDetails();
  };

  const mainInformation = {
    handleEditBusinessStatus,
    handleEditBusiness,
  };

  return business ? (
    <>
      <div className="inner-content-container">
        <div css={navigationWrapper}>
          <div className="specs-container">
            <Button type="link" onClick={() => history.goBack()} small>
              <Icon iconName="las la-arrow-left" />
            </Button>
            <label className="flex flex-center business-name">{getDynamicTranslation(businessData?.name)?.name}</label>
            <Tag type={businessStatusColor[businessData?.statusId]}>
              {getText(businessStatusInverse[businessData?.statusId])}
            </Tag>
          </div>
          <div className="actions-container">
            {businessData?.statusId !== businessStatus.approved && (
              <Button type="secondary" small onClick={() => handleEditBusinessStatus(businessStatus.approved)}>
                {businessData?.statusId === businessStatus.edited
                  ? getText('approveChanges')
                  : getText('verifyBusiness')}
              </Button>
            )}
            <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
              {getText('comment')}
            </Button>
            {businessData?.statusId !== businessStatus.declined && (
              <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                {businessData?.statusId === businessStatus.edited
                  ? getText('declineChanges')
                  : getText('declineBusiness')}
              </Button>
            )}
          </div>
        </div>

        <CommentsList comments={business?.comments} />

        <Tabs css={tabs}>
          <TabPane key={1} tab={getText('businessInformation')}>
            <BusinessInformationTab
              {...mainInformation}
              business={businessData}
              businessWithAddress={business}
              comments={business?.comments}
              oldData={business?.currentBusinessInformation}
              newData={business?.newBusinessInformation}
            />
          </TabPane>
          <TabPane key={4} tab={getText('statusHistory')}>
            <BusinessStatusHistoryTab business={businessData} />
          </TabPane>
        </Tabs>
      </div>
      <DeclineModal
        title={getText('business')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditBusinessStatus(businessStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToBusiness}
      />
    </>
  ) : (
    <Spin size="large" css={spinner} />
  );
};

export default BusinessDetail;
