import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button, Breadcrumbs } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { notificationType } from 'enums';
import useError from 'services/errorHandling/useError';
import { getNotificationById } from 'services/api/notificationsService';
import { notificationIcn, titleIcnContainer, dateStyles, statusDot } from '../../styles';
import { detailNotificationPages } from './config';
import {
  notificationDetailContainer,
  breadcrumbsStyles,
  backButtonStyle,
  titleStyle,
  statusContainer,
  statusLabel,
  detailContainer,
  contentContainer,
  statusDotContainer,
  notificationIcon,
} from './styles';

export const NotificationDetails = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    fetchNotificationById();
  }, []);

  const fetchNotificationById = async () => {
    const [res, err] = await getNotificationById(notificationId);
    err ? setError(err) : setNotification(res);
  };

  return (
    <div css={notificationDetailContainer}>
      {/* detailNotificationPages should be function because it has getText inside which throw error if it is used before configuration */}
      <Breadcrumbs css={breadcrumbsStyles} items={detailNotificationPages()} />

      <div css={detailContainer} className="header">
        <Button type="link" onClick={() => history.goBack()} small css={backButtonStyle}>
          <Icon iconName="las la-arrow-left" />
        </Button>
        <h1 css={titleStyle}>{getText(notification?.title)}</h1>
        <div css={statusContainer(notification?.isSent)}>
          <label css={statusLabel}>{notification?.isSent ? getText('send') : getText('notSend')}</label>
        </div>
      </div>
      <Row css={contentContainer} gutter={40}>
        <Col lg={4} css={titleIcnContainer}>
          <div css={notificationIcn}>
            <img css={notificationIcon} src={notificationType[notification?.type]?.icon} alt={notification?.type} />
          </div>
          <div>
            <b>
              {getText(
                `${notification?.type}Title`,
                notification?.additionalData ?? { createdBy: notification?.notificationManual.createdBy },
              )}
            </b>
            <div>
              {moment(notification?.publishDate ?? notification?.createdDate).format('DD/MM/YYYY')}
              {moment(notification?.publishDate ?? notification?.createdDate).format('HH:mm')}
            </div>
          </div>
        </Col>
        <Col lg={5}>
          {getText(
            `${notification?.type}Message`,
            notification?.additionalData ?? {
              title: notification?.notificationManual.title,
              message: notification?.notificationManual.message,
            },
          )}
        </Col>
        <Col lg={3}>
          <h4>{notification?.country}</h4>
          <p css={dateStyles}>{notification?.merchantsOnly ? getText('merchants') : getText('allUsers')}</p>
        </Col>
        <Col lg={4}>
          <h3>{notification?.creatorName}</h3>
          <p css={dateStyles}>{moment(notification?.createdDate).format('DD/MM/YYYY')}</p>
        </Col>
        <Col lg={2} css={statusDotContainer}>
          <div css={statusDot(notification?.isSent)} />
          <p>{notification?.isSent ? getText('send') : getText('notSend')}</p>
        </Col>
      </Row>
    </div>
  );
};
