import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { isArray, isPlainObject } from 'lodash-es';
import toast from 'react-hot-toast';
import { Form, Input } from 'antd';
import { Button } from 'components/UIExternal';
import LogoImg from 'assets/images/Logo_color.png';
import { loginPageContainer } from './styles';

const Login = () => {
  const { getText } = useTranslations();
  const [form] = Form.useForm();

  const handleLogin = async (data) => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));
    //     Error from sts identity                                Default behavior
    err && (isArray(err) ? err.forEach((el) => toast.error(el)) : toast.error(err));
  };

  return (
    <div css={loginPageContainer}>
      <img className="logo" src={LogoImg} alt="502 img" />
      <section className="login-form">
        <Form form={form} onFinish={handleLogin}>
          <h2 className="title">{getText('loginIntoYourAdminAccount')}</h2>
          <Form.Item
            name="email"
            label={getText('email')}
            className="email-input"
            rules={[{ required: true, message: getText('fieldIsRequired') }]}>
            <Input placeholder={getText('email')} />
          </Form.Item>
          <Form.Item
            name="password"
            label={getText('password')}
            rules={[{ required: true, message: getText('fieldIsRequired') }]}>
            <Input placeholder={getText('password')} type="password" />
          </Form.Item>
        </Form>
      </section>
      <Button small type="primary" onClick={() => form.submit()}>
        {getText('login')}
      </Button>
    </div>
  );
};

export default Login;
