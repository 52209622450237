import { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import { chartTitle, chartContainer } from './styles';

const BarChart = (props) => {
  const { data, config, title, chartHeight } = props;
  const canvasRef = useRef();

  useEffect(() => {
    const chart = new Chart(canvasRef.current, !isNil(config) && config(data));
    return () => chart.destroy();
  }, [data]);

  return (
    <div css={chartContainer}>
      {title && <h4 css={chartTitle}>{title}</h4>}
      <canvas ref={canvasRef} height={chartHeight} />
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.array,
  config: PropTypes.func,
  title: PropTypes.string,
  chartHeight: PropTypes.string,
};

export default BarChart;
