import { theme } from 'styles';

export const deliveryMethodStyle = {
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: '0.5em',
  },
};

export const tagListStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '-0.25em',
};

export const packageStyles = {
  marginRight: '1.5em',

  '&: last-of-type': {
    marginRight: 0,
  },
};

export const noCategoryContainer = (showError) => ({
  border: showError ? `1px solid ${theme.red}` : `2px solid ${theme.gray500}`,
  borderRadius: 3,
});

export const categoryButtonsWrapper = {
  marginBottom: '1.5em',
  marginTop: '0.25em',
  display: 'flex',
  gap: '0.5em',
  width: '100%',
  flexWrap: 'wrap',

  '& > div': {
    marginBottom: '0.3125em',
  },
  '& .categorySelected': {
    backgroundColor: theme.default_hover,
    color: theme.red,
  },
  '& .ant-btn': {
    marginRight: '1em',
    marginTop: '0.25em',
    borderRadius: '4px',
    color: theme.black,
    textShadow: 'none',
  },
  '& .ant-btn-primary': {
    backgroundColor: theme.skyblue,
  },
  '& button:focus': {
    background: theme.skyblue,
    color: theme.black,
  },
};

export const requiredStyles = {
  textWrap: 'no-wrap',
  color: theme.red,
  marginRight: '0.25em',
};

export const wrapperStyles = {
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
};

export const radioGroup = {
  '& .ant-radio-button-wrapper': {
    marginRight: '0.5em',
    textAlign: 'center',
  },
  '& .ant-radio-button-wrapper-checked': {
    background: `${theme.skyblue} !important`,
    color: 'black !important',
  },
};

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -1em',
  alignItems: 'center',
  gap: '0.2em',
};

export const rowWeight = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -1em',
  alignItems: 'center',
};

export const colWeight = {
  flex: '0 0 auto',
  width: 'auto%',
  maxWidth: '100%',
  padding: '0 1em',
};

export const box = {
  alignItems: 'center',
  borderRadius: 3,
  '& .ant-form-item-control-input-content': {
    display: 'flex',
  },
  '& .ant-form-item-label .ant-form-item-no-colon': {
    fontWeight: 500,
    fontSize: '0.8125rem !important',
  },
};

export const formDateStyle = {
  marginBottom: '1em',
  '& > div': {
    padding: '0 !important',
  },
};

export const formStyles = {
  width: '100%',
  '& > div': {
    marginBottom: '1em',
  },
  '& .ant-form-item': {
    marginBottom: '0.5em',
  },
};

export const weightDropdown = {
  '& .ant-select': {
    width: 'auto',
  },
};

export const popoverContainer = {
  '& .ant-popover-inner-content': {
    padding: '0.5em',
    background: theme.gray100,
    borderRadius: 3,
    maxWidth: 700,
    color: theme.white,
  },
  '& .ant-popover-arrow-content': {
    background: theme.gray100,
  },
  '& p': {
    marginBottom: 0,
    fontSize: '0.75rem',
  },
};

export const tagsHintText = {
  color: theme.gray300,
  fontSize: '0.75rem',
  marginTop: '0.3125em',
  display: 'inline-block',
};

export const tagStyles = {
  background: theme.gray600,
  borderRadius: 4,
  padding: '0.25em 0.75em',
  marginRight: '0.25em',
  marginTop: '0.3125em',
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginLeft: '0.3125em',
    cursor: 'pointer',
  },
};

export const categoriesEdit = {
  '& .ant-form-item-row': {
    flexDirection: 'column !important',
  },
};

export const tagsStyles = {
  '& .ant-form-item-row': {
    gap: '0.5em',
  },
};
