import PropTypes from 'prop-types';
import { isString } from '@veraio/core';
import { tagContainer, TAG_TYPE } from './styles';

export { TAG_TYPE };

export const Tag = (props) => {
  const { children, className } = props;

  return (
    <span css={tagContainer(props)} {...(isString(className) && { className })}>
      {children}
    </span>
  );
};

Tag.propTypes = {
  type: PropTypes.oneOf(Object.values(TAG_TYPE)),
  children: PropTypes.any,
  className: PropTypes.string,
};
