import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import {
  progressLineWrapper,
  progressLine,
  progressLineCheckPoints,
  progressLinePoint,
  progressStepImages,
  progressStepStepsWrapper,
  progressStep,
} from './styles';

export const ProgressBar = (props) => {
  const { steps } = props;
  const { getText } = useTranslations();
  const progressPercentage = parseInt((steps?.findLastIndex((el) => el.isCompleted) / (steps.length - 1)) * 100, 10);

  return (
    <>
      <div css={progressStepImages(progressPercentage)}>
        {steps?.map((step, ind) => (
          <img key={`${step?.label}-${ind}`} css={step?.className} src={step?.image} alt={step?.label} />
        ))}
      </div>
      {/* wrapper */}
      <div css={progressLineWrapper}>
        {/* line */}
        <div css={progressLine(progressPercentage)} />
        {/* dots */}
        <div css={progressLineCheckPoints}>
          {steps?.map((step, ind) => (
            <span key={`${step?.label}-${ind}`} css={progressLinePoint(step?.isCompleted)} />
          ))}
        </div>
      </div>
      <div css={progressStepStepsWrapper}>
        {steps?.map((step, ind) => (
          <span key={`${step?.label}-${ind}`} css={progressStep}>
            {getText(step?.label)}
          </span>
        ))}
      </div>
    </>
  );
};

ProgressBar.propTypes = {
  images: PropTypes.array,
  stepList: PropTypes.array,
  stepLabelList: PropTypes.string,
  order: PropTypes.object,
  steps: PropTypes.array,
};
