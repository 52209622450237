import { getText, getDynamicTranslation } from '@veraio/strank';
import moment from 'moment';
import { Button } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { Tag } from 'components/UIExternal';
import { businessAddressStatusInverse, businessAddressStatusColor } from 'enums';
import { textLinkStyle, actionStyle } from './styles';

// addressesColumns should be function because it has getText inside which throw error if it is used before configuration
export const addressesColumns = () => [
  {
    name: getText('name'),
    render: (el) => (
      <Button type="link" linkTo={`/address/${el.id}`} css={textLinkStyle}>
        {getDynamicTranslation(el.name)?.name}
      </Button>
    ),
  },
  {
    name: getText('status'),
    render: (el) => (
      <Tag type={businessAddressStatusColor[el.statusId]}>{getText(businessAddressStatusInverse[el.statusId])}</Tag>
    ),
    width: 100,
  },
  {
    name: getText('businessNames'),
    render: (el) => (
      <Button type="link" linkTo={`/address/${el.id}`} css={textLinkStyle}>
        {el.businessNames.length ? getDynamicTranslation(el.businessNames)?.name : '-'}
      </Button>
    ),
  },
  {
    name: getText('nameNickname'),
    render: (el) => <>{`${el.merchantName} - ${el.username}`}</>,
  },
  {
    name: getText('submittedOn'),
    render: (el) => <span>{moment(el.submittedDate).format('MMM DD,YYYY,h:mm:ss')}</span>,
    width: 200,
  },
  {
    name: getText('lastModifiedOn'),
    render: (el) => (
      <span>{`${moment(el.modifiedDate).format('MMM DD,YYYY')} ${getText('by')} ${el?.modifiedBy}`}</span>
    ),
    ellipsis: true,
    width: 200,
  },
  {
    key: 'action',
    width: 40,
    render: (el) => (
      <Button type="link" linkTo={`/address/${el?.id}`} small css={actionStyle}>
        <Icon iconName="lar la-eye" />
      </Button>
    ),
  },
];
